import { useCallback, useEffect, useMemo, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { CellClassParams, EditableCallbackParams, GridApi, IRowNode } from 'ag-grid-community'
import {
    Box,
    Button,
    ButtonDropdown,
    ContentLayout,
    Header,
    Select,
    SpaceBetween,
    TextContent,
    Toggle,
} from '@amzn/awsui-components-react'
import useStore from '../../Store'
import { useAppContext } from '../../../context'
import { HttpMethod } from '../../Constant'
import { getDefaultDate } from '../../common/Util'
import EmptyState from '../reusable/EmptyState'
import ModalTemplate from '../reusable/ModalTemplate'
import {
    BulkDirectoryPayload,
    DEFAULT_PAGE_SIZE,
    defaultExcelExportParams,
    DIRECTORY_STATUS_TYPES,
    DirectoryPayload,
    EDITABLE_COLUMN_IDS,
    excelStyles,
    EXCLUDED_EXCEL_COLUMN_IDS,
    getBEDirectoryName,
    getDirectoryColumnDefs,
    isUpdated,
    NOTIFICATION_ITEM_ID,
} from './DirectoryUtil'

interface DirectoryListProps {
    isLoading: boolean
    setIsSaved: (isSaved: boolean) => void
    sendRequest: (
        method: HttpMethod,
        url: string,
        payload: DirectoryPayload | BulkDirectoryPayload | undefined,
        onSuccess: (res: any) => void,
        errMsg: string,
    ) => void
    handleToggleStatus: () => void
    myTeams: Map<string, any> | undefined
    allTeams: Map<string, any> | undefined
    isAllTeams: boolean
    setIsAllTeams: (isAllTeams: boolean) => void
    parentPrograms: Map<string, any> | undefined
    teamPrograms: any[]
    revisionOptions: any[]
    selectedRevision: any
    setSelectedRevision: (revision: any) => void
    handleClickCreate: () => void
    handleClickSendNotification: (event: CustomEvent, gridApi: GridApi) => void
}

const DirectoryList = ({
    isLoading,
    setIsSaved,
    sendRequest,
    handleToggleStatus,
    myTeams,
    allTeams,
    isAllTeams,
    setIsAllTeams,
    parentPrograms,
    teamPrograms,
    revisionOptions,
    selectedRevision,
    setSelectedRevision,
    handleClickCreate,
    handleClickSendNotification,
}: DirectoryListProps) => {
    const apiContext = useAppContext()
    const userAlias = apiContext.userAlias
    const canAdmin = useStore((state) => state.canAdmin)
    const selectedBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [isAllUpdated, setIsAllUpdated] = useState(true)
    const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false)

    const [gridApi, setGridApi] = useState<GridApi>()
    const columnDefs = useMemo(() => {
        const isRevisionEditable =
            selectedRevision?.value.revision_status === DIRECTORY_STATUS_TYPES.OPEN
        const isRowEditable = (params: EditableCallbackParams | CellClassParams): boolean => {
            // managers only allowed to edit myTeams
            return Boolean(canAdmin || myTeams?.has(params.data.team_id))
        }
        const isCellEditable = (params: EditableCallbackParams | CellClassParams): boolean => {
            return EDITABLE_COLUMN_IDS.includes(params.column.getColId())
        }

        return getDirectoryColumnDefs(canAdmin, isRevisionEditable, isRowEditable, isCellEditable)
    }, [canAdmin, selectedRevision, myTeams])

    const onGridReady = useCallback((params) => {
        setGridApi(params.api)
    }, [])

    const onCellValueChanged = useCallback(
        ({ node }) => {
            setIsSaved(false)
            setIsAllUpdated(false)
            node.data.updated_at = null
            node.data.updated_by = null
            gridApi?.applyTransaction({ update: [node.data] })
        },
        [gridApi],
    )

    const onPaginationChanged = useCallback(() => {
        if (!gridApi) return

        const currentPage = gridApi.paginationGetCurrentPage()
        const pageSize = gridApi.paginationGetPageSize()
        const startRowIdx = currentPage * pageSize
        const endRowIdx = Math.min(startRowIdx + pageSize, gridApi.getDisplayedRowCount())

        for (let i = startRowIdx; i < endRowIdx; i++) {
            const node = gridApi.getDisplayedRowAtIndex(i)
            if (!node) continue
            node.data.visited = true
        }
    }, [gridApi])

    const handleExport = useCallback(() => {
        if (!gridApi || !selectedRevision || !selectedRevision.value || !selectedBusinessEntity)
            return

        gridApi.exportDataAsExcel({
            fileName: `${getBEDirectoryName(selectedBusinessEntity.name)}-${selectedRevision.value.revision_title}-${getDefaultDate()}.xlsx`,
            columnKeys: gridApi
                .getColumns()
                ?.filter(
                    (column) =>
                        column.isVisible() &&
                        !EXCLUDED_EXCEL_COLUMN_IDS.includes(column.getColId()),
                ),
        })
    }, [gridApi, selectedRevision, selectedBusinessEntity])

    const handleClickConfirm = () => {
        if (!gridApi) return

        // add a modal to remind managers
        // if they haven't viewed all pages for myTeams and no updates have been made
        let allRowsVisited = true
        let anyRowsUpdated = false

        gridApi.forEachNode((node) => {
            if (!canAdmin && !myTeams?.has(node.data.team_id)) return

            allRowsVisited = allRowsVisited && Boolean(node.data.visited)
            anyRowsUpdated = anyRowsUpdated || isUpdated(node.data)
        })

        if (!allRowsVisited && !anyRowsUpdated) {
            setConfirmModalVisible(true)
            return
        }

        handleConfirm()
    }

    const handleConfirm = () => {
        if (!gridApi) return

        const updatedPayloads: DirectoryPayload[] = []
        const updatedRowNodes: IRowNode[] = []

        gridApi.forEachNode((node) => {
            if (!canAdmin && !myTeams?.has(node.data.team_id)) return

            let payload: DirectoryPayload = {
                directory_sort: node.data.directory_sort,
                wiki_link: node.data.wiki_link,
                description: node.data.description,
                stl_alias: node.data.stl_alias,
                tpm_alias: node.data.tpm_alias,
                pmt_alias: node.data.pmt_alias,
            }

            updatedPayloads.push(payload)
            updatedRowNodes.push(node)
        })

        if (!updatedPayloads.length) return // manager has no permission to update any teams

        const handleBulkUpdateSuccess = () => {
            setIsSaved(true)
            setIsAllUpdated(true)
            updatedRowNodes.forEach((node) => {
                node.data.updated_by = userAlias
                node.data.updated_at = Date.now().toString()
                gridApi?.applyTransaction({ update: [node.data] })
            })
        }
        sendRequest(
            HttpMethod.PUT,
            `/directory/${selectedRevision.value.revision_id}/bulk-update`,
            { team_programs: updatedPayloads, requester: userAlias ?? '' },
            handleBulkUpdateSuccess,
            `Failed to confirm updates for ${selectedRevision.value.revision_title}.`,
        )
    }

    useEffect(() => {
        if (isLoading) {
            gridApi?.showLoadingOverlay()
        } else if (!gridApi?.getDisplayedRowCount()) {
            gridApi?.showNoRowsOverlay()
        } else {
            gridApi?.hideOverlay()
        }
    }, [isLoading])

    useEffect(() => {
        if (
            !gridApi ||
            isLoading ||
            myTeams === undefined ||
            allTeams === undefined ||
            parentPrograms == undefined
        )
            return

        let allRowsUpdated = true
        const rows =
            canAdmin || isAllTeams
                ? teamPrograms
                : teamPrograms.filter((row) => myTeams.has(row.team_id))
        rows.forEach((row) => {
            row.org_name = allTeams.get(row.team_id)?.org_name
            row.team_name = allTeams.get(row.team_id)?.team_name
            row.program_name = parentPrograms.get(row.parent_program_id)?.program_name

            if (canAdmin || myTeams.has(row.team_id)) {
                allRowsUpdated = allRowsUpdated && isUpdated(row)
            }
        })
        setIsAllUpdated(allRowsUpdated)
        gridApi.setGridOption('rowData', rows)
    }, [teamPrograms, isAllTeams, myTeams, allTeams, parentPrograms])

    return (
        <ContentLayout
            header={
                <Header
                    actions={
                        <SpaceBetween size='l' direction='horizontal' alignItems='center'>
                            {!canAdmin && (
                                <SpaceBetween size='xs' direction='horizontal'>
                                    <Box>My Teams</Box>
                                    <Toggle
                                        checked={isAllTeams}
                                        onChange={({ detail }) => {
                                            setIsAllTeams(detail.checked)
                                        }}
                                        disabled={isLoading}
                                    ></Toggle>
                                    <Box>All Teams</Box>
                                </SpaceBetween>
                            )}
                            {canAdmin && (
                                <ButtonDropdown
                                    disabled={
                                        isLoading ||
                                        !teamPrograms.length ||
                                        selectedRevision?.value.revision_status !==
                                            DIRECTORY_STATUS_TYPES.OPEN
                                    }
                                    items={[
                                        {
                                            text: 'Incomplete Teams',
                                            id: NOTIFICATION_ITEM_ID.INCOMPLETE_TEAMS,
                                            disabled: teamPrograms.every((tp) => isUpdated(tp)),
                                        },
                                        { text: 'All Teams', id: NOTIFICATION_ITEM_ID.ALL_TEAMS },
                                    ]}
                                    onItemClick={(event) => {
                                        if (!gridApi) return
                                        handleClickSendNotification(event, gridApi)
                                    }}
                                >
                                    Send Notification
                                </ButtonDropdown>
                            )}
                            <Button
                                disabled={isLoading || !teamPrograms.length}
                                onClick={handleExport}
                            >
                                Export
                            </Button>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween size='m' direction='horizontal' alignItems='center'>
                        <Select
                            selectedOption={selectedRevision}
                            onChange={({ detail }) => {
                                if (!detail.selectedOption.value) {
                                    handleClickCreate()
                                    return
                                }
                                setSelectedRevision(detail.selectedOption)
                            }}
                            options={
                                canAdmin
                                    ? [
                                          {
                                              label: 'New Revision',
                                              value: null,
                                              disabled:
                                                  revisionOptions.length &&
                                                  revisionOptions[0].value.revision_status ===
                                                      DIRECTORY_STATUS_TYPES.OPEN,
                                              disabledReason:
                                                  'Please close the open revision before creating a new one.',
                                          },
                                          ...revisionOptions,
                                      ]
                                    : revisionOptions
                            }
                            disabled={isLoading}
                            placeholder='Select a revision'
                        />
                        {canAdmin && (
                            <Button
                                variant='primary'
                                disabled={
                                    isLoading ||
                                    !selectedRevision ||
                                    selectedRevision != revisionOptions?.[0]
                                }
                                onClick={handleToggleStatus}
                            >
                                {selectedRevision?.value?.revision_status ===
                                DIRECTORY_STATUS_TYPES.CLOSED
                                    ? 'Open Revision'
                                    : 'Close Revision'}
                            </Button>
                        )}
                    </SpaceBetween>
                </Header>
            }
        >
            <div className='ag-theme-quartz' style={{ height: '60vh' }}>
                <AgGridReact
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    columnDefs={columnDefs}
                    tooltipShowDelay={500}
                    enterNavigatesVertically={true}
                    enterNavigatesVerticallyAfterEdit={true}
                    suppressRowHoverHighlight={true}
                    singleClickEdit={true}
                    stopEditingWhenCellsLoseFocus={true}
                    pagination={true}
                    paginationPageSize={DEFAULT_PAGE_SIZE}
                    onPaginationChanged={onPaginationChanged}
                    defaultExcelExportParams={defaultExcelExportParams}
                    excelStyles={excelStyles}
                    noRowsOverlayComponent={() =>
                        !selectedRevision || !selectedRevision.value ? (
                            <EmptyState
                                title='No revisions'
                                subtitle="You don't have any revision to display."
                                action={
                                    canAdmin && (
                                        <div style={{ pointerEvents: 'all' }}>
                                            <Button onClick={handleClickCreate}>
                                                Create Revision
                                            </Button>
                                        </div>
                                    )
                                }
                            />
                        ) : (
                            <EmptyState
                                title='No directory items'
                                subtitle="You don't have any directory items to display."
                                action=''
                            />
                        )
                    }
                />
                <Box float='right' margin={{ top: 'l' }}>
                    <Button
                        variant='primary'
                        disabled={
                            isLoading ||
                            !teamPrograms.length ||
                            selectedRevision?.value?.revision_status !==
                                DIRECTORY_STATUS_TYPES.OPEN ||
                            isAllUpdated
                        }
                        onClick={handleClickConfirm}
                    >
                        Confirm
                    </Button>
                </Box>
            </div>
            <ModalTemplate
                modalVisible={confirmModalVisible}
                onModalVisibleChange={setConfirmModalVisible}
                title={'Warning'}
                body={
                    <TextContent>
                        You have not reviewed all the pages for this revision. Do you want to
                        continue?
                    </TextContent>
                }
                actionName='Continue'
                action={() => {
                    handleConfirm()
                    setConfirmModalVisible(false)
                }}
                cancelName='Cancel'
            />
        </ContentLayout>
    )
}

export default DirectoryList
