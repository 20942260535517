import React, { useEffect, useState } from 'react'
import { Spinner } from '@amzn/awsui-components-react'
import { useAppContext } from '../../../context'
import { SpaceBetween, Tabs } from '@amzn/awsui-components-react/polaris'
import {
    generatePlanName,
    generatePlanUrl,
    generateRevisionName,
    getDateFormat,
    getDateFromEgretRevisionId,
    getDateNYearsAgo,
    isAllocationActive,
} from '../../common/Util'
import { decompressResponse } from './Utils'
import useStore from '../../Store'
import { ColDef, GridApi } from 'ag-grid-community'
import _ from 'lodash'
import { AgGridReact } from 'ag-grid-react'
import { ITEM_TYPES } from '../../Constant'
import {
    DEFAULT_COLUMN_OPTIONS,
    defaultGridOptions,
    generateSpendHeadcountTableColDefs,
    groupTableColDefs,
    HEADCOUNT_VALUE_KEY,
    MAX_HISTORIC_YEARS,
    programTableColDefs,
    SPEND_VALUE_KEY,
    SUMMARY_GRID_HEIGHT,
} from './AllocationSummaryConstants'
import { isWeakMap } from 'node:util/types'

interface SummaryTableProps {
    setGridApi: (api: GridApi) => void
    rowData: any[]
    colDefs: ColDef[]
}
const SummaryTable = (props: SummaryTableProps) => {
    const { rowData, setGridApi, colDefs } = props

    return (
        <div className='ag-theme-quartz' style={{ height: SUMMARY_GRID_HEIGHT }}>
            <AgGridReact
                onGridReady={(params) => setGridApi(params.api)}
                columnDefs={colDefs}
                rowData={rowData}
                gridOptions={defaultGridOptions()}
                defaultColDef={DEFAULT_COLUMN_OPTIONS}
            />
        </div>
    )
}

const AllocationSummary = (props) => {
    const { effectiveDate, isActive, itemType, itemId, originalIsActive, originalEffectiveDate } =
        props
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const earliestEffectiveDate = getDateFormat(getDateNYearsAgo(MAX_HISTORIC_YEARS))
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const egretBaseUrl = appContext.egretUrl
    const [itemAllocations, setItemAllocations] = useState([])
    const [itemHeadcountEstimates, setItemHeadcountEstimates] = useState([])
    const [itemSpendEstimates, setItemSpendEstimates] = useState([])
    const [itemHeadcountFilteredData, setItemHeadcountFilteredData] = useState<any[]>([])
    const [itemSpendFilteredData, setItemSpendFilteredData] = useState<any[]>([])
    const [isAllocationLoading, setIsAllocationLoading] = useState(true)
    const localProgramMap = useStore((state) => state.localProgramsByYear)
    const setLocalProgramMap = useStore((state) => state.setLocalProgramsByYear)
    const projectMap = useStore((state) => state.projectsByYear)
    const setProjectMap = useStore((state) => state.setProjectsByYear)
    const teamMap = useStore((state) => state.teamMap)
    const setTeamMap = useStore((state) => state.setTeamMap)
    const orgGroupMap = useStore((state) => state.orgGroupMap)
    const setOrgGroupMap = useStore((state) => state.setOrgGroupMap)
    const selectedBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [programAllocationSummaryData, setProgramAllocationSummaryData] = useState({})
    const [groupAllocationSummaryData, setGroupAllocationSummaryData] = useState({})
    const [isTeamsLoading, setIsTeamsLoading] = useState<boolean>(true)
    const [isProgramsLoading, setIsProgramsLoading] = useState<boolean>(true)
    const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(true)
    const [isGroupsLoading, setIsGroupsLoading] = useState<boolean>(true)
    const [activeItemAllocations, setActiveItemAllocations] = useState([])
    const [programsGrid, setProgramsGrid] = useState<GridApi>()
    const [groupsGrid, setGroupsGrid] = useState<GridApi>()
    const [headcountGrid, setHeadcountGrid] = useState<GridApi>()
    const [spendGrid, setSpendGrid] = useState<GridApi>()
    const isOrgGroup = itemType === ITEM_TYPES.ORG || itemType === ITEM_TYPES.GROUP
    const [isEgretLoading, setIsEgretLoading] = useState<boolean>(isOrgGroup)
    const [egretPlans, setEgretPlans] = useState<Map<string, any>>(new Map())
    const [isEgretPlansLoading, setIsEgretPlansLoading] = useState<boolean>(isOrgGroup)

    const fetchLocalProgramsByBusinessEntityAndYear = (year, expectedYears) => {
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/year/${year}/programs`)
            .then((res) => {
                const programIdMap = res.data.map((program) => [program.local_program_id, program])
                setLocalProgramMap({ year: year, values: new Map(programIdMap) })
                setIsProgramsLoading(expectedYears.length < Object.keys(localProgramMap).length + 1)
            })
            .catch((err) => {
                console.error(err)
                setIsProgramsLoading(false)
            })
    }
    const fetchProjectsByBusinessEntityAndYear = (year, expectedYears) => {
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/year/${year}/projects`)
            .then((res) => {
                const yearProjectMap = res.data.map((project) => [project.project_id, project])
                setProjectMap({ year: year, values: new Map(yearProjectMap) })
                setIsProjectsLoading(expectedYears.length < Object.keys(projectMap).length + 1)
            })
            .catch((err) => {
                console.error(err)
                setIsProjectsLoading(false)
            })
    }

    const fetchTeamsForBusinessEntity = () => {
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/teams`)
            .then((res) => {
                const teamEntries = res.data.map((team) => [formatGroupTeamKey(team), team])
                setTeamMap(new Map(teamEntries))
                setIsTeamsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsTeamsLoading(false)
            })
    }

    const fetchEgretPlans = () => {
        setIsEgretPlansLoading(true)
        apiClient
            .get(`/egret-plans/business-entity/${selectedBusinessEntity.id}?year=`)
            .then((res) => {
                const planList = res.data.map((plan) => [plan.plan_id, plan])
                setEgretPlans(new Map(planList))
                setIsEgretPlansLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsEgretPlansLoading(false)
                setIsEgretLoading(false)
            })
    }

    const fetchGroupsForOrg = () => {
        if (itemType !== ITEM_TYPES.ORG) {
            setIsGroupsLoading(false)
            return
        }
        setIsGroupsLoading(true)
        apiClient
            .get(`orgs/${itemId}/groups`)
            .then((res) => {
                const groupMapItems = res.data.map((group) => [
                    `${group.group_id}#${group.org_id}`,
                    group,
                ])
                setOrgGroupMap({ [itemId]: new Map(groupMapItems) })
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                setIsGroupsLoading(false)
            })
    }

    const isEgretEstimateActive = (estimate) => {
        if (!isOrgGroup) {
            return true
        }
        let isGroupActive, groupEffectiveDate
        if (itemType === ITEM_TYPES.GROUP) {
            isGroupActive = originalIsActive
            groupEffectiveDate = originalEffectiveDate
        } else {
            const groupMap = orgGroupMap[estimate['org_id']] ?? new Map()
            const groupMeta = groupMap.get(`${estimate['group_id']}#${estimate['org_id']}`)
            if (!groupMeta) {
                return false
            }
            isGroupActive = groupMeta.is_active
            groupEffectiveDate = groupMeta.active_status_effective_date
        }
        return isGroupActive || new Date(groupEffectiveDate) > new Date(estimate.effective_date)
    }

    const processEgretEstimates = (estimatesList, estimateValueKey): any[] => {
        const planRevisionTotals = {}
        const estimateTotalKey = `${estimateValueKey}_total`
        estimatesList.forEach((estimate) => {
            if (
                new Date(effectiveDate) > new Date(estimate.effective_date) ||
                !isEgretEstimateActive(estimate)
            ) {
                // ignore the estimate if it's created earlier than the effective date
                // or, if the estimate is already inactive
                return
            }
            const planKey = `${estimate.plan_id}#${estimate.revision_id}`
            if (!planRevisionTotals[planKey]) {
                planRevisionTotals[planKey] = {
                    plan_name: estimate.plan_name,
                    plan_id: estimate.plan_id,
                    revision_id: estimate.revision_id,
                    plan_url: estimate.plan_url,
                    revision_name: estimate.revision_name,
                    [estimateTotalKey]: 0,
                }
            }
            const estimateValue = Number(estimate[estimateValueKey])
            planRevisionTotals[planKey][estimateTotalKey] += estimateValue
        })
        return Object.values(planRevisionTotals)
    }

    const fetchEgretEstimatesForItem = () => {
        setIsEgretLoading(true)
        apiClient
            .get(
                `/egret-estimates/item-type/${itemType}/item-id/${itemId}?date=${earliestEffectiveDate}`,
            )
            .then((res) => {
                let allResults = decompressResponse(res.data)
                allResults = !_.isEmpty(allResults) ? allResults : { spend: [], headcount: [] }
                const mapEstimates = (estimatesResult) => {
                    return estimatesResult.map((estimate) => ({
                        ...estimate,
                        plan_name: generatePlanName(egretPlans.get(estimate.plan_id)),
                        plan_url: generatePlanUrl(egretBaseUrl, estimate),
                        revision_name: generateRevisionName(
                            egretPlans.get(estimate.plan_id),
                            estimate.revision_id,
                        ),
                        effective_date: getDateFromEgretRevisionId(estimate.revision_id),
                    }))
                }
                setItemHeadcountEstimates(mapEstimates(allResults['headcount']))
                setItemSpendEstimates(mapEstimates(allResults['spend']))
                setIsEgretLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsEgretLoading(false)
            })
    }

    useEffect(() => {
        fetchEgretPlans()
    }, [selectBusinessEntity])

    useEffect(() => {
        if (!itemId) {
            return
        }
        setIsTeamsLoading(true)
        setIsProgramsLoading(true)
        setIsProjectsLoading(true)
        fetchTeamsForBusinessEntity()
        fetchFalconAllocationsForItem()
    }, [itemId])

    useEffect(() => {
        if (!itemId) {
            return
        }
        if (itemType === ITEM_TYPES.ORG) {
            setIsGroupsLoading(true)
            fetchGroupsForOrg()
        } else {
            setIsGroupsLoading(false)
        }
    }, [itemId])

    useEffect(() => {
        if (isEgretPlansLoading || isGroupsLoading) {
            return
        }
        if (isOrgGroup) {
            fetchEgretEstimatesForItem()
        }
    }, [isGroupsLoading, isEgretPlansLoading])

    useEffect(() => {
        if (!isActive) {
            generateSummaryForDate()
        }
    }, [activeItemAllocations, effectiveDate, isActive, groupsGrid, programsGrid])

    useEffect(() => {
        if (!isActive) {
            setItemSpendFilteredData(processEgretEstimates(itemSpendEstimates, SPEND_VALUE_KEY))
            setItemHeadcountFilteredData(
                processEgretEstimates(itemHeadcountEstimates, HEADCOUNT_VALUE_KEY),
            )
        }
    }, [itemSpendEstimates, effectiveDate, isActive, spendGrid, headcountGrid])

    const formatGroupTeamKey = (item) => {
        return `${item['team_id']}#${item['group_id']}#${item['org_id']}`
    }

    const generateSummaryForDate = () => {
        const programProjectSummary = {}
        const groupTeamSummary = {}
        activeItemAllocations.forEach((allocation) => {
            if (allocation['allocation_week'] < effectiveDate) {
                return
            }
            const allocationValue = Number(allocation['allocation_value'])
            const programMapForYear = localProgramMap[allocation['allocation_year']] ?? new Map()
            const projectMapForYear = projectMap[allocation['allocation_year']] ?? new Map()
            const groupTeamKey = formatGroupTeamKey(allocation)

            const foundTeam = teamMap.get(groupTeamKey) ?? {}
            const programProjectKey = `${allocation['local_program_id']}#${allocation['project_id']}`
            const foundProgram = programMapForYear.get(allocation['local_program_id']) ?? {}
            const foundProject = projectMapForYear.get(allocation['project_id']) ?? {}

            if (!programProjectSummary[programProjectKey]) {
                programProjectSummary[programProjectKey] = {
                    program_name: foundProgram?.program_name ?? 'Program',
                    project_name: foundProject?.project_name ?? 'Project',
                    program_year: allocation['allocation_year'],
                    program_id: allocation['program_id'],
                    project_id: allocation['project_id'],
                    local_program_id: allocation['local_program_id'],
                    allocation_total: 0,
                    program: allocation['local_program_id'],
                    local_program_id_and_year: `${allocation['local_program_id']}#${allocation['allocation_year']}`,
                }
            }
            if (!groupTeamSummary[groupTeamKey]) {
                groupTeamSummary[groupTeamKey] = {
                    team_name: foundTeam?.team_name ?? `Team`,
                    group_name: foundTeam?.group_name ?? 'Group',
                    allocation_total: 0,
                    group_id: allocation['group_id'],
                    team_id: allocation['team_id'],
                }
            }
            programProjectSummary[programProjectKey]['allocation_total'] += allocationValue
            groupTeamSummary[groupTeamKey]['allocation_total'] += allocationValue
        })
        setProgramAllocationSummaryData(programProjectSummary)
        setGroupAllocationSummaryData(groupTeamSummary)
    }

    useEffect(() => {
        if (isTeamsLoading || isProgramsLoading || isProjectsLoading) {
            return
        }
        setActiveItemAllocations(
            itemAllocations.filter((allocation) => {
                if (!Number(allocation['allocation_value'])) {
                    return false
                }
                const programMapForYear =
                    localProgramMap[allocation['allocation_year']] ?? new Map()
                const projectMapForYear = projectMap[allocation['allocation_year']] ?? new Map()
                const groupTeamKey = formatGroupTeamKey(allocation)

                const foundTeam = teamMap.get(groupTeamKey) ?? {}
                const foundProgram = programMapForYear.get(allocation['local_program_id']) ?? {}
                const foundProject = projectMapForYear.get(allocation['project_id']) ?? {}
                if (_.isEmpty(foundTeam) || _.isEmpty(foundProject)) {
                    // ignore allocations belonging to archived items
                    return false
                }
                return isAllocationActive(allocation, foundTeam, foundProgram, foundProject)
            }),
        )
        setIsAllocationLoading(false)
    }, [isTeamsLoading, isProgramsLoading, isProjectsLoading, itemAllocations])

    const fetchFalconAllocationsForItem = () => {
        setIsAllocationLoading(true)
        apiClient
            .get(
                `/falcon-allocations/item-type/${itemType}/item-id/${itemId}?date=${earliestEffectiveDate}&business_entity_id=${selectBusinessEntity.id}`,
            )
            .then((res) => {
                const allAllocations = decompressResponse(res.data)

                const allocationYears: Set<string> = new Set(
                    allAllocations.map((alloc) => alloc.allocation_year),
                )
                let fetchingPrograms = false
                allocationYears.forEach((year) => {
                    if (!localProgramMap[year]) {
                        fetchingPrograms = true
                        setIsProgramsLoading(true)
                        setIsProjectsLoading(true)
                        fetchLocalProgramsByBusinessEntityAndYear(year, allocationYears)
                        fetchProjectsByBusinessEntityAndYear(year, allocationYears)
                    }
                })
                if (!fetchingPrograms) {
                    setIsProgramsLoading(false)
                    setIsProjectsLoading(false)
                }
                setItemAllocations(allAllocations)
            })
            .catch((err) => {
                console.error(err)
                setIsAllocationLoading(false)
                setItemAllocations([])
                setIsTeamsLoading(false)
                setIsProgramsLoading(false)
                setIsProjectsLoading(false)
            })
    }

    const isFalconDataLoading = () => {
        return isAllocationLoading || isTeamsLoading || isProjectsLoading || isProgramsLoading
    }

    const isEgretDataLoading = () => {
        return isEgretPlansLoading || isEgretLoading
    }

    const defaultSpinner = (estimateType) => {
        return (
            <SpaceBetween size={'xs'} direction={'horizontal'}>
                <Spinner />
                {`Fetching ${estimateType} for ${itemType}...`}
            </SpaceBetween>
        )
    }

    return (
        <SpaceBetween size={'s'} direction={'vertical'}>
            {
                <Tabs
                    tabs={[
                        {
                            label: `Falcon - Groups`,
                            id: 'first',
                            content: isFalconDataLoading() ? (
                                defaultSpinner('allocations')
                            ) : (
                                <SummaryTable
                                    setGridApi={setGroupsGrid}
                                    rowData={Object.values(groupAllocationSummaryData)}
                                    colDefs={groupTableColDefs}
                                />
                            ),
                        },
                        {
                            label: `Falcon - Programs`,
                            id: 'second',
                            content: isFalconDataLoading() ? (
                                defaultSpinner('allocations')
                            ) : (
                                <SummaryTable
                                    setGridApi={setProgramsGrid}
                                    rowData={Object.values(programAllocationSummaryData)}
                                    colDefs={programTableColDefs}
                                />
                            ),
                        },
                        ...(!isOrgGroup
                            ? []
                            : [
                                  {
                                      label: `Egret - Headcount`,
                                      id: 'third',
                                      content: isEgretDataLoading() ? (
                                          defaultSpinner('Egret headcount estimates')
                                      ) : (
                                          <SummaryTable
                                              setGridApi={setHeadcountGrid}
                                              rowData={itemHeadcountFilteredData}
                                              colDefs={generateSpendHeadcountTableColDefs(false)}
                                          />
                                      ),
                                  },
                                  {
                                      label: `Egret - Spend`,
                                      id: 'fourth',
                                      content: isEgretDataLoading() ? (
                                          defaultSpinner('Egret spend estimates')
                                      ) : (
                                          <SummaryTable
                                              setGridApi={setSpendGrid}
                                              rowData={itemSpendFilteredData}
                                              colDefs={generateSpendHeadcountTableColDefs(true)}
                                          />
                                      ),
                                  },
                              ]),
                    ]}
                />
            }
        </SpaceBetween>
    )
}

export default AllocationSummary
