import '../grid/TableStyle.scss'
import './Directory.scss'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useBlocker } from 'react-router-dom'
import { ColDef, GridApi } from 'ag-grid-community'
import {
    Box,
    Container,
    ContentLayout,
    Flashbar,
    SpaceBetween,
    TextContent,
} from '@amzn/awsui-components-react'
import useStore from '../../Store'
import { useAppContext } from '../../../context'
import { AlertTypes, HttpMethod } from '../../Constant'
import HeaderTemplate from '../reusable/HeaderTemplate'
import ModalTemplate from '../reusable/ModalTemplate'
import { convertToLocalTime, decompressResponse } from '../reusable/Utils'
import CreateRevision from './CreateRevision'
import SendNotification from './SendNotification'
import DirectoryList from './DirectoryList'
import {
    BulkDirectoryPayload,
    DIRECTORY_PREFIX,
    DIRECTORY_STATUS_TYPES,
    DirectoryPayload,
    formatRevisionOptions,
    getBEDirectoryName,
    getNotificationColumnDefs,
    getNotificationTemplates,
    isUpdated,
    NOTIFICATION_ITEM_ID,
    RevisionOption,
    toggleStatus,
    useLoadingState,
} from './DirectoryUtil'

const Directory = () => {
    const apiContext = useAppContext()
    const apiClient = apiContext.apiClient
    const userAlias = apiContext.userAlias
    const selectedBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [revisionOptions, setRevisionOptions] = useState<RevisionOption[]>([])
    const [selectedRevision, setSelectedRevision] = useState<RevisionOption | undefined>(undefined)

    const [isAllTeams, setIsAllTeams] = useState<boolean>(false)
    const [myTeams, setMyTeams] = useState<Map<string, any> | undefined>(undefined)
    const [allTeams, setAllTeams] = useState<Map<string, any> | undefined>(undefined)
    const [parentPrograms, setParentPrograms] = useState<Map<string, any> | undefined>(undefined)
    const [teamPrograms, setTeamPrograms] = useState<any[]>([])

    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [notificationModalVisible, setNotificationModalVisible] = useState<boolean>(false)
    const [notificationTeams, setNotificationTeams] = useState<any[]>([])
    const notificationColumnDefs = useMemo<ColDef[]>(getNotificationColumnDefs, [])
    const notificationTemplates = useMemo<string[][]>(
        () => getNotificationTemplates(selectedRevision?.value.revision_title),
        [selectedRevision],
    )

    const [alertType, setAlertType] = useState<AlertTypes>(AlertTypes.INFO)
    const [alertContent, setAlertContent] = useState<string>('')

    // give a warning if the user tries to refresh or leave the page without saving changes
    const [isSaved, setIsSaved] = useState<boolean>(true)
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            !isSaved && currentLocation.pathname !== nextLocation.pathname,
    )
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (!isSaved) {
                event.preventDefault()
                event.returnValue = ''
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [isSaved])

    // fetches/posts/puts data from the specified URL with loading state management
    // and executes the success callback with the res
    const { isLoading, startLoading, stopLoading } = useLoadingState()
    const sendRequest = useCallback(
        (
            method: HttpMethod,
            url: string,
            payload: DirectoryPayload | BulkDirectoryPayload | undefined = undefined,
            onSuccess: (res: any) => void,
            errMsg: string,
        ) => {
            startLoading()

            const request = () => {
                switch (method) {
                    case HttpMethod.GET:
                        return apiClient.get(url)
                    case HttpMethod.POST:
                        return apiClient.post(url, JSON.stringify(payload))
                    case HttpMethod.PUT:
                        return apiClient.put(url, JSON.stringify(payload))
                }
            }

            request()
                .then(onSuccess)
                .catch((err) => {
                    console.error(err)
                    setAlertType(AlertTypes.ERROR)
                    setAlertContent(errMsg)
                })
                .finally(() => {
                    stopLoading()
                })
        },
        [startLoading, stopLoading],
    )

    useEffect(() => {
        fetchParentPrograms()
    }, [])

    const fetchParentPrograms = useCallback(() => {
        const processParentProgramRes = (res: any) => {
            const parentPrograms = decompressResponse(res.data)
            const parentProgramList = parentPrograms.map((prog) => [prog.parent_program_id, prog])
            setParentPrograms(new Map(parentProgramList))
        }
        sendRequest(
            HttpMethod.GET,
            `/programs?metadata=false`,
            undefined,
            processParentProgramRes,
            'Failed to fetch programs',
        )
    }, [])

    useEffect(() => {
        fetchTeams()
        loadRevisions()
    }, [selectedBusinessEntity])

    const fetchTeams = useCallback(() => {
        if (!selectedBusinessEntity || !selectedBusinessEntity.id) return

        const myTeamsURL = `/user/${userAlias}/business-entity/${selectedBusinessEntity.id}/myteam`
        const processMyTeamsRes = (res: any) => {
            const myTeamList = res.data.map((team) => [team.team_id, team])
            setMyTeams(new Map(myTeamList))
        }
        sendRequest(
            HttpMethod.GET,
            myTeamsURL,
            undefined,
            processMyTeamsRes,
            'Failed to fetch my teams',
        )

        const allTeamsURL = `/business-entity/${selectedBusinessEntity.id}/teams`
        const processAllTeamsRes = (res: any) => {
            const allTeamList = res.data.map((team) => [team.team_id, team])
            setAllTeams(new Map(allTeamList))
        }
        sendRequest(
            HttpMethod.GET,
            allTeamsURL,
            undefined,
            processAllTeamsRes,
            'Failed to fetch all teams',
        )
    }, [selectedBusinessEntity])

    const loadRevisions = useCallback(async () => {
        if (!selectedBusinessEntity || !selectedBusinessEntity.id) return

        const processRevisionsRes = (res) => {
            const revisions: any[] = res.data
            revisions.sort((a: any, b: any) => Number(b.revision_id) - Number(a.revision_id))

            const options = revisions.map((rev) => formatRevisionOptions(rev))
            setRevisionOptions(options)
            setSelectedRevision(options?.[0])
        }

        sendRequest(
            HttpMethod.GET,
            `business-entity/${selectedBusinessEntity.id}/directories`,
            undefined,
            processRevisionsRes,
            `Failed to load directory revisions for ${selectedBusinessEntity.name}`,
        )
    }, [selectedBusinessEntity])

    useEffect(() => {
        if (!selectedRevision || !selectedRevision.value) {
            setTeamPrograms([])
            return
        }

        const processDirectoryRes = (res: any) => {
            const team_programs = res.data.filter((row) =>
                row.directory_sort.startsWith(DIRECTORY_PREFIX.TEAM_PROGRAM),
            )
            setTeamPrograms(team_programs)
        }
        sendRequest(
            HttpMethod.GET,
            `/directory/${selectedRevision.value.revision_id}`,
            undefined,
            processDirectoryRes,
            `Failed to load directory revision ${selectedRevision.value.revision_title}`,
        )
    }, [selectedRevision])

    const handleClickCreate = useCallback(() => {
        setCreateModalVisible(true)
    }, [])

    const handleCreate = useCallback(
        (title: string) => {
            if (!selectedBusinessEntity || !selectedBusinessEntity.id) return

            const current_time = Date.now()
            const six_month_time = new Date(current_time)
            six_month_time.setMonth(six_month_time.getMonth() - 6)

            const payload: DirectoryPayload = {
                requester: userAlias ?? '',
                business_entity_id: selectedBusinessEntity.id,
                revision_title: title,
                date_from: convertToLocalTime(six_month_time),
            }

            const processCreate = () => {
                loadRevisions()
                setAlertType(AlertTypes.SUCCESS)
                setAlertContent(`Successfully created directory revision ${title}`)
            }

            sendRequest(
                HttpMethod.PUT,
                `/directory/${current_time.toString()}`,
                payload,
                processCreate,
                `Failed to create directory revision ${title}`,
            )
        },
        [selectedBusinessEntity],
    )

    const handleClickSendNotification = useCallback(
        (event: CustomEvent, gridApi: GridApi) => {
            if (!allTeams) return

            const teamMap: Map<string, any> = new Map()
            gridApi.forEachNode((node) => {
                const updated = isUpdated(node.data)
                if (event.detail.id === NOTIFICATION_ITEM_ID.INCOMPLETE_TEAMS && updated) return
                teamMap.set(node.data.team_id, {
                    ...allTeams.get(node.data.team_id),
                    updated: updated,
                })
            })

            const teams = Array.from(teamMap.values())
            teams.sort(
                (a, b) =>
                    (a?.org_name ?? '').localeCompare(b?.org_name ?? '') ||
                    (a?.primary_alias ?? '').localeCompare(b?.primary_alias ?? ''),
            )
            setNotificationTeams(teams)
            setNotificationModalVisible(true)
        },
        [allTeams],
    )

    const handleToggleStatus = useCallback(() => {
        if (!selectedRevision || !selectedRevision.value) return

        const new_status = toggleStatus(selectedRevision.value.revision_status)
        const payload: DirectoryPayload = {
            requester: userAlias ?? '',
            directory_sort: selectedRevision.value.directory_sort,
            revision_status: new_status,
        }

        const is_reopened = new_status === DIRECTORY_STATUS_TYPES.OPEN
        const processClose = () => {
            loadRevisions()
            setAlertType(AlertTypes.SUCCESS)
            setAlertContent(
                `Successfully ${is_reopened ? 'reopened' : 'closed'} directory revision ${selectedRevision.value.revision_title}`,
            )
        }
        sendRequest(
            HttpMethod.PUT,
            `/directory/${selectedRevision.value.revision_id}`,
            payload,
            processClose,
            `Failed to ${is_reopened ? 'reopen' : 'close'} directory revision ${selectedRevision.value.revision_title}`,
        )
    }, [selectedRevision])

    return (
        <Box margin={'s'}>
            <ContentLayout
                header={
                    <SpaceBetween size='m'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                {
                                    text: getBEDirectoryName(selectedBusinessEntity?.name),
                                    href: '',
                                },
                            ]}
                        />
                        {alertContent !== '' && (
                            <Flashbar
                                items={[
                                    {
                                        onDismiss: () => {
                                            setAlertContent('')
                                        },
                                        dismissible: true,
                                        content: alertContent,
                                        type: alertType,
                                    },
                                ]}
                            />
                        )}
                    </SpaceBetween>
                }
            >
                <Container
                    fitHeight
                    header={
                        <Box color='text-status-inactive'>
                            <span style={{ fontWeight: 'bold' }}>Directions: </span>{' '}
                            <span>
                                Take a moment to review your team's directory information below.
                            </span>
                            <ol style={{ paddingLeft: 20, marginTop: 0 }}>
                                <li>
                                    Is the information correct? Is anything missing? Anything marked
                                    not confirmed has not been updated since the last revision.
                                    Please confirm the information is up-to-date.
                                </li>
                                <li>
                                    "Description of Support" should include how your team supports
                                    the individual programs you allocate against in 15 words or less
                                    for each item.
                                </li>
                                <li>
                                    If any POC, TPM, or PMT information is available, please add the
                                    team member's alias in the designated columns.
                                </li>
                            </ol>
                        </Box>
                    }
                >
                    <DirectoryList
                        isLoading={isLoading}
                        setIsSaved={setIsSaved}
                        sendRequest={sendRequest}
                        handleToggleStatus={handleToggleStatus}
                        myTeams={myTeams}
                        allTeams={allTeams}
                        isAllTeams={isAllTeams}
                        setIsAllTeams={setIsAllTeams}
                        parentPrograms={parentPrograms}
                        teamPrograms={teamPrograms}
                        revisionOptions={revisionOptions}
                        selectedRevision={selectedRevision}
                        setSelectedRevision={setSelectedRevision}
                        handleClickCreate={handleClickCreate}
                        handleClickSendNotification={handleClickSendNotification}
                    />
                    <CreateRevision
                        visible={createModalVisible}
                        setVisible={setCreateModalVisible}
                        handleCreate={handleCreate}
                    />
                    <SendNotification
                        visible={notificationModalVisible}
                        setVisible={setNotificationModalVisible}
                        setAlertType={setAlertType}
                        setAlertContent={setAlertContent}
                        teams={notificationTeams}
                        templates={notificationTemplates}
                        columnDefs={notificationColumnDefs}
                    />
                    <ModalTemplate
                        modalVisible={blocker.state === 'blocked'}
                        title={'Leave Page'}
                        body={
                            <TextContent>
                                You have unconfirmed changes for this revision. Do you want to
                                continue?
                            </TextContent>
                        }
                        actionName='Continue'
                        action={() => blocker.proceed?.()}
                        cancelName='Cancel'
                        onDismiss={() => blocker.reset?.()}
                    />
                </Container>
            </ContentLayout>
        </Box>
    )
}

export default Directory
