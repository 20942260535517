import { ROLES } from '../role'

export const FALCON_START_YEAR = 2022
export const DESCRIPTION_LENGTH_LIMIT = 50
export const NOTIFICATION_RESOURCE_LIMIT = 5
export const ALLOCATION_NOTE_WORD_LIMIT = 150
export const ALLOCATION_VALUE_WIDTH_LIMIT = 120
export const HEADCOUNT_VALUE_WIDTH_LIMIT = 90
export const DATE_PICKER_WIDTH_LIMIT = 150
export const SELECT_WIDTH_LIMIT = 250
export const NOT_APPLICABLE = 'N/A'

const ABACUS_TEAM_ID_LENGTH = 20
export const validPermissionGroup = new RegExp(
    `^amzn1.abacus.team.([a-z0-9]{${ABACUS_TEAM_ID_LENGTH}})$`,
)
export const YEAR_MONTH_REGEX = new RegExp(`[1-9][0-9][0-9]{2}-([0][1-9]|[1][0-2])`)

export const PHONE_TOOL_PREFIX = 'https://phonetool.amazon.com/users/'
export const PERMISSION_GROUP_PREFIX = 'https://permissions.amazon.com/a/team/'
export const SELECT_BUSINESS_ENTITY = 'Select Business Entity'
export const GLOBAL_BUSINESS_ENTITY = 'GLOBAL'
export const PROGRAM_ID_SEPARATOR = '__'
export const NO_GROUP = 'No group'
export const ALL_GROUP = 'all-groups'
export const TEAM_NAME_VALIDATION = 'Team name is required.'
export const TEAM_NAME_DUPLICATE = 'That team name is taken. Try another.'
export const GROUP_NAME_VALIDATION = 'Group name is required.'
export const GROUP_NAME_DUPLICATE = 'That group name is taken. Try another.'
export const EMPTY_ACCESS_CONTROL_VALIDATION = 'Access control is required.'
export const ACCESS_CONTROL_VALIDATION =
    'Invalid access control. Enter in the form amzn1.abacus.team.m435lggpabbcz4w47c7a'
export const PROJECT_NAME_VALIDATION = 'Project name is required.'
export const PROJECT_NAME_DUPLICATE = 'That project name is taken. Try another.'
export const PROGRAM_SELECT_VALIDATION = 'Selected program is invalid (missing ID).'
export const PROGRAM_NUMERIC_ATTRIBUTE_VALIDATION = 'Please enter a valid number or leave blank.'
export const ATTRIBUTE_DROPDOWN_VALUE_NOT_SET = 'ATTRIBUTE_DROPDOWN_VALUE_NOT_SET'
export const ATTRIBUTE_DROPDOWN_VALUE_NOT_SET_DISPLAY = 'Not Set'

export enum HttpMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
}

export enum Selections {
    ORG = 'Select org',
    GROUP = 'Select group',
    TEAM = 'Select team',
    ALL_GROUP = 'All groups',
}

export enum ModalModes {
    EDIT = 'EDIT',
    CREATE = 'CREATE',
    NOT_SET = 'NOT SET',
    DELETE = 'DELETE',
    VIEW = 'VIEW',
    IMPORT = 'IMPORT',
}

export enum AlertTypes {
    INFO = 'info',
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
}

export const EMPTY_ALERT_STATE = {
    type: AlertTypes.INFO,
    header: '',
    content: '',
}

export const enum DAY_OF_WEEK {
    SUNDAY = 0,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
}

export const enum PIE_CHART_STATUS {
    LOADING = 'loading',
    FINISHED = 'finished',
    ERROR = 'error',
}
export const enum COMPONENT_TYPES {
    INPUT_STRING = 'Input string',
    INPUT_INT = 'Input integer',
    INPUT_FLOAT = 'Input float',
    TOGGLE = 'Toggle',
    TEXTAREA = 'Textarea',
    SELECT = 'Select',
    LINK = 'Link',
    DATE = 'Date',
    TOKEN_GROUP = 'TokenGroup',
    POPOVER = 'Popover',
    CUSTOM = 'Custom',
}

export const enum POPOVER_CONTENT_TYPES {
    TABLE = 'Table',
}

export const enum PROGRAM_ATTRIBUTE_TYPES {
    CORE = 'core',
    LOCAL = 'local',
    GLOBAL = 'global',
}

export const BUSINESS_ENTITY_ABBREVIATIONS = {
    AR: 'AR Product Engineering',
    GRD: 'Global Robotics Delivery',
}

export const EMPTY_SELECTION = {
    label: 'Select Option',
    value: '',
}

export const EMPTY_YEAR_SELECTION = {
    label: 'No Year Found',
    value: 'no_year_found',
}

export const enum ENTITLEMENT_CURRENCY {
    MILLION = '$MM',
}

export const enum STATUS_INDICATOR_TYPES {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
    INFO = 'info',
    STOPPED = 'stopped',
    PENDING = 'pending',
    IN_PROGRESS = 'in-progress',
    LOADING = 'loading',
}

export const enum BADGE_COLOR {
    GREY = 'grey',
    BLUE = 'blue',
    RED = 'red',
    GREEN = 'green',
}

export const enum BADGE_NAME {
    NEW = 'new',
    UPDATED = 'updated',
}

export const enum ALLOCATION_STATUS {
    NOT_ALLOCATED = 'Not Allocated',
    COMPLETED = 'Completed',
    OVER_ALLOCATED = 'Over Allocated',
    UNDER_ALLOCATED = 'Under Allocated',
}

export const enum EMPLOYEE_TYPE {
    BLUE_BADGE = 'blue_badge',
    YELLOW_BADGE = 'yellow_badge',
    OTHER = 'other',
}

export const rolePriority = [ROLES.ADMIN, ROLES.STL, ROLES.MANAGER]

// define employee classes
export const EMPLOYEE_TYPE_GENERIC = ['employee', 'other']
// how the employee types are shown on the UI
export const EMPLOYEE_TYPE_DISPLAY_NAMES = ['Employee', 'Co-Op/Intern']
// popover info for each employee type
export const EMPLOYEE_TYPE_DEFINITIONS = [
    'Total Amazon employee resources and headcount for the team.',
    'Total Co-Op/Intern resources and headcount for the team.',
]
// define employee alias pattern; source: https://code.amazon.com/packages/FCAssociateEmploymentProfileService/blobs/6fb4cf56585f66bc0dad863db571502d6feabb4d/--/src/amazon/fclm/aeps/util/EmployeeUtil.java#L20
export const EMPLOYEE_ALIAS_PATTERN = /^[0-9a-zA-Z|\\-]{1,32}$/

// instantiate Object from define employee types, with all allocations set to 0
export const getDefaultAllocation = () =>
    Object.fromEntries(EMPLOYEE_TYPE_GENERIC.map((employeeType) => [employeeType, '0']))

export const getEmptyHrData = () =>
    Object.fromEntries(EMPLOYEE_TYPE_GENERIC.map((employeeType) => [employeeType, []]))

export const LIVE_HR_TEAM_DATA_KEY = [
    'entity_id',
    'entity_name',
    'entity',
    'hr_permission_group',
    'hr_permission_group_name',
    ...EMPLOYEE_TYPE_GENERIC,
    'total_headcount',
]

export const HISTORICAL_HR_TEAM_DATA_KEY = ['date'].concat(LIVE_HR_TEAM_DATA_KEY)

export const HR_MEMBER_DATA_KEY = [
    'team_id',
    'team_name',
    'hr_permission_group',
    'hr_permission_group_name',
    'status',
    'resource_name',
    'alias',
    'manager_alias',
    'business_title',
    'employee_type',
]

export const TIME_STRING = 'T00:00:00'
export const JS_FUNDAMENTALLY_DATE = '1970-01-01'
export const EMPTY_ALLOCATION_EFFECTIVE_DATE = ''
export const MOVE_ALL_ALLOCATIONS_DEFAULT = true

export const enum HOLIDAY {
    HALLOWEEN = 'halloween',
    CHRISTMAS = 'christmas',
}

export const FALCON = 'falcon'

export enum ITEM_TYPES {
    ORG = 'org',
    GROUP = 'group',
    TEAM = 'team',
    PROGRAM = 'program',
    PROJECT = 'project',
}

export const DEFAULT_PLAN_NAME = 'Plan Not Found'
export const DEFAULT_REVISION_NAME = 'Revision Not Found'
