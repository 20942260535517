import React, { useContext, useEffect, useState } from 'react'
import {
    Box,
    Button,
    FormField,
    Input,
    Modal,
    SpaceBetween,
    Textarea,
} from '@amzn/awsui-components-react/polaris'
import { useAppContext } from '../../../context'
import {
    generateId,
    getDefaultDate,
    isDuplicateByPropertyValue,
    isValidChanged,
} from '../../common/Util'
import {
    ACCESS_CONTROL_VALIDATION,
    AlertTypes,
    ITEM_TYPES,
    ModalModes,
    validPermissionGroup,
} from '../../Constant'
import useStore from '../../Store'
import { Icon, Link, Toggle, TokenGroupProps } from '@amzn/awsui-components-react'
import { OrgContext } from './constants/OrgContext'
import ActiveEffectiveDateSelector from '../reusable/ActiveEffectiveDateSelector'
import PrimaryAliasRegisteredUsersInput from '../reusable/PrimaryAliasRegisteredUsersInput'
import _ from 'lodash'

const ORG_NAME_VALIDATION_MESSAGE = 'Organization name must be provided.'
const ORG_NAME_DUPLICATE_MESSAGE = 'That organization name is taken. Try another.'

const CreateOrg = ({
    modalMode,
    modalVisible,
    closeModalHandler,
    refreshList,
    setAlertContent,
    setAlertType,
    onIsOrgLoadingChange,
}) => {
    const appContext = useAppContext()
    const orgContext = useContext(OrgContext)
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias
    const orgNamePlaceHolder = 'e.g. Core Software'

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [orgName, setOrgName] = useState('')
    const [orgNameError, setOrgNameError] = useState('')
    const [orgDescription, setOrgDescription] = useState('')
    const [isSaveDisabled, setIsSaveDisabled] = useState(false)
    const [hrDataPermissionGroup, setHrDataPermissionGroup] = useState('')
    const [hrDataPermissionGroupName, setHrDataPermissionGroupName] = useState('')
    const [hrPermissionGroupError, setHrPermissionGroupError] = useState('')
    const defaultDate = getDefaultDate()
    const [originalIsActive, setOriginalIsActive] = useState<boolean>(true)
    const [isActive, setIsActive] = useState<boolean>(true)
    const [orgCreationDate, setOrgCreationDate] = useState<string>(defaultDate)
    const [activeStatusEffectiveDate, setActiveStatusEffectiveDate] = useState<string>(defaultDate)
    const [originalActivityEffectiveDate, setOriginalActivityEffectiveDate] =
        useState<string>(defaultDate)
    const [primaryContact, setPrimaryContact] = useState<TokenGroupProps.Item[]>([])
    const [registeredUsers, setRegisteredUsers] = useState<TokenGroupProps.Item[]>([])
    const [primaryContactError, setPrimaryContactError] = useState<string>('')
    const [registeredUserError, setRegisteredUserError] = useState<string>('')

    const checkValidActiveEffectiveDate = (newDate) => {
        if (_.isEmpty(orgContext.selectedOrgs)) {
            setIsSaveDisabled(true)
            return
        }
        if (orgContext.selectedOrgs[0].is_active) {
            return true
        }
        setIsSaveDisabled(
            !isValidChanged(newDate, orgContext.selectedOrgs[0], 'active_status_effective_date'),
        )
    }

    useEffect(() => {
        if (modalMode === ModalModes.EDIT) {
            if (orgContext.selectedOrgs === undefined || orgContext.selectedOrgs.length === 0) {
                return
            }
            setIsSaveDisabled(true)
            fillAllInputWithSelectedItem(orgContext.selectedOrgs[0])
        } else {
            clearAllInput()
        }
    }, [modalMode, orgContext.selectedOrgs])

    const fillAllInputWithSelectedItem = (org) => {
        setOrgName(org['org_name'])
        setOrgDescription(org['description'])
        setHrDataPermissionGroup(org['hr_permission_group'])
        setHrDataPermissionGroupName(org['hr_permission_group_name'])
        setIsActive(org['is_active'] ?? true)
        const createAtDate = org['created_at'].split('T')[0]
        const activeEffectiveDate = org['is_active']
            ? defaultDate
            : org['active_status_effective_date']
        setOrgCreationDate(createAtDate)
        setOriginalActivityEffectiveDate(activeEffectiveDate)
        setActiveStatusEffectiveDate(activeEffectiveDate)
        setOriginalIsActive(org['is_active'])
        const primaryAlias = org['primary_alias']
        setPrimaryContact(
            primaryAlias
                ? [
                      {
                          label: primaryAlias,
                          dismissLabel: 'Remove ' + primaryAlias,
                      },
                  ]
                : [],
        )
        const allRegisteredUsers = org.registered_users ?? []
        setRegisteredUsers(
            allRegisteredUsers.map((registeredUser) => ({
                label: registeredUser,
                dismissLabel: `Remove ${registeredUser}`,
            })),
        )
    }

    const clearAllInput = () => {
        setOrgName('')
        setOrgDescription('')
        setHrDataPermissionGroup('')
        setHrDataPermissionGroupName('')
        setPrimaryContact([])
        setPrimaryContactError('')
        setRegisteredUsers([])
        setRegisteredUserError('')
    }

    const isPayloadValidated = (payload) => {
        if (orgName.trim().length === 0) {
            setOrgNameError(ORG_NAME_VALIDATION_MESSAGE)
            return false
        }
        return true
    }

    const handleCloseModal = (reason) => {
        if (reason === 'overlay') {
            // prevent close modal from clicking outside of modal
            return
        }
        closeModalHandler()
        if (modalMode === ModalModes.EDIT) {
            setIsSaveDisabled(true)
            fillAllInputWithSelectedItem(orgContext.selectedOrgs[0])
        } else {
            clearAllInput()
        }
        setOrgNameError('')
    }

    const handleSubmit = () => {
        const payload = {
            org_id:
                modalMode === ModalModes.CREATE ? generateId() : orgContext.selectedOrgs[0].org_id,
            org_name: orgName,
            business_entity_id: selectBusinessEntity.id,
            business_entity_name: selectBusinessEntity.name,
            description: orgDescription,
            requester: userAlias,
            hr_permission_group: hrDataPermissionGroup,
            hr_permission_group_name: hrDataPermissionGroupName,
            is_active: isActive,
            active_status_effective_date: activeStatusEffectiveDate,
            primary_alias: primaryContact.length ? primaryContact[0].label : '',
            registered_users: registeredUsers.map((user) => user.label),
        }

        if (!isPayloadValidated(payload)) {
            return
        }

        onIsOrgLoadingChange(true)
        createUpdateOrg(payload)
            .then((res) => {
                setAlertType(AlertTypes.SUCCESS)
                if (modalMode === ModalModes.EDIT) {
                    setAlertContent(
                        `Successfully updated organization ${orgContext.selectedOrgs[0].org_name}.`,
                    )
                } else {
                    setAlertContent(`Successfully created organization ${orgName}.`)
                }
                clearAllInput()
                orgContext.onSelectionChange([])
                refreshList()
            })
            .catch((error) => {
                setAlertType(AlertTypes.ERROR)
                if (modalMode === ModalModes.EDIT) {
                    setAlertContent(
                        `Failed to update organization ${orgContext.selectedOrgs[0].org_name}: ${error.response.data}.`,
                    )
                } else {
                    setAlertContent(
                        `Failed to create organization ${orgName}: ${error.response.data}.`,
                    )
                }
                onIsOrgLoadingChange(false)
                console.error(error)
            })
        closeModalHandler()
    }

    const createUpdateOrg = (payload) => {
        return modalMode === ModalModes.EDIT
            ? apiClient.put(`/org/${orgContext.selectedOrgs[0].org_id}`, JSON.stringify(payload))
            : apiClient.post(
                  `/business-entity/${selectBusinessEntity.id}/orgs`,
                  JSON.stringify(payload),
              )
    }

    return (
        <Modal
            onDismiss={({ detail }) => handleCloseModal(detail.reason)}
            header={modalMode === ModalModes.EDIT ? 'Edit Organization' : 'Add an Organization'}
            visible={modalVisible}
            size={modalMode === ModalModes.EDIT ? 'large' : 'medium'}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleSubmit}
                            disabled={isSaveDisabled || orgNameError !== ''}
                        >
                            {modalMode === ModalModes.EDIT ? 'Update' : 'Add'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='xs'>
                <FormField
                    label='Organization'
                    description='Name of the organization'
                    errorText={orgNameError}
                    stretch
                >
                    <Input
                        value={orgName}
                        placeholder={orgNamePlaceHolder}
                        onChange={({ detail }) => {
                            const value = detail.value
                            const isDuplicate = isDuplicateByPropertyValue(
                                'org_name',
                                orgContext.selectedOrgs.length > 0
                                    ? orgContext.selectedOrgs[0].org_name
                                    : 'undefined',
                                orgContext.orgs,
                                'org_name',
                                value.trim(),
                            )
                            let errMessage = ''
                            if (value.trim().length === 0) {
                                errMessage = ORG_NAME_VALIDATION_MESSAGE
                            } else if (isDuplicate) {
                                errMessage = ORG_NAME_DUPLICATE_MESSAGE
                            }
                            setOrgNameError(errMessage)
                            setOrgName(value)

                            if (modalMode === ModalModes.CREATE) {
                                setIsSaveDisabled(false)
                                return
                            }
                            if (
                                !isDuplicate &&
                                isValidChanged(value, orgContext.selectedOrgs[0], 'org_name')
                            ) {
                                setIsSaveDisabled(false)
                            } else {
                                setIsSaveDisabled(true)
                            }
                        }}
                    ></Input>
                </FormField>
                <FormField
                    stretch
                    label='Business Entity'
                    description='Business entity that the organization belongs to'
                >
                    <Input readOnly={true} value={selectBusinessEntity.name}></Input>
                </FormField>
                <FormField
                    stretch
                    label='Headcount Source'
                    description='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks org headcount.'
                    errorText={hrPermissionGroupError}
                >
                    <SpaceBetween direction='vertical' size='xs'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Link href='https://permissions.amazon.com/a/user' target={'_blank'}>
                                Find your permission group <Icon name={'external'} />{' '}
                            </Link>
                            <Link
                                href='https://permissions.amazon.com/a/team/new'
                                target={'_blank'}
                            >
                                Create a new permission group <Icon name={'external'} />{' '}
                            </Link>
                        </SpaceBetween>
                        <Input
                            value={hrDataPermissionGroup}
                            onChange={({ detail }) => {
                                const value = detail.value.trim()
                                let errorMessage = ''

                                if (value.length > 0 && !validPermissionGroup.test(value)) {
                                    errorMessage = ACCESS_CONTROL_VALIDATION
                                }
                                setHrPermissionGroupError(errorMessage)
                                setHrDataPermissionGroup(detail.value)

                                if (modalMode === ModalModes.CREATE) {
                                    setIsSaveDisabled(false)
                                    return
                                }
                                setIsSaveDisabled(
                                    !isValidChanged(
                                        value,
                                        orgContext.selectedOrgs[0],
                                        'hr_permission_group',
                                    ),
                                )
                            }}
                        ></Input>
                    </SpaceBetween>
                </FormField>
                <FormField
                    stretch
                    label='Headcount Resource Name'
                    description='Amazon TEAM Name for the headcount data source. For example, Falcon-core-SW-HC'
                >
                    <Input
                        value={hrDataPermissionGroupName}
                        onChange={({ detail }) => {
                            setHrDataPermissionGroupName(detail.value)

                            if (modalMode === ModalModes.CREATE) {
                                setIsSaveDisabled(false)
                                return
                            }
                            setIsSaveDisabled(
                                !isValidChanged(
                                    detail.value,
                                    orgContext.selectedOrgs[0],
                                    'hr_permission_group_name',
                                ),
                            )
                        }}
                    ></Input>
                </FormField>
                <PrimaryAliasRegisteredUsersInput
                    itemType={ITEM_TYPES.ORG}
                    onSaveDisabledChange={setIsSaveDisabled}
                    onRegisteredUsersChange={setRegisteredUsers}
                    onRegisteredUserInputErrorChange={setRegisteredUserError}
                    registeredUsers={registeredUsers}
                    registerUserInputError={registeredUserError}
                    primaryContact={primaryContact}
                    primaryContactInputError={primaryContactError}
                    onPrimaryContactChange={setPrimaryContact}
                    onPrimaryContactInputErrorChange={setPrimaryContactError}
                    selectedItem={orgContext.selectedOrgs[0]}
                />
                <FormField
                    stretch
                    label='Description'
                    description='Description of the organization'
                >
                    <Textarea
                        value={orgDescription}
                        onChange={({ detail }) => {
                            setOrgDescription(detail.value)
                            if (modalMode === ModalModes.CREATE) {
                                setIsSaveDisabled(false)
                                return
                            }
                            if (
                                isValidChanged(
                                    detail.value,
                                    orgContext.selectedOrgs[0],
                                    'description',
                                )
                            ) {
                                setIsSaveDisabled(false)
                            } else {
                                setIsSaveDisabled(true)
                            }
                        }}
                    ></Textarea>
                </FormField>
                <FormField
                    label='Active'
                    description='Mark true if it is an active organization.'
                    stretch
                >
                    <Toggle
                        onChange={({ detail }) => {
                            setIsActive(detail.checked)
                            if (modalMode === ModalModes.EDIT) {
                                setIsSaveDisabled(
                                    !isValidChanged(
                                        detail.checked,
                                        orgContext.selectedOrgs[0],
                                        'is_active',
                                    ),
                                )
                            }
                        }}
                        checked={isActive}
                    >
                        Is org active?
                    </Toggle>
                </FormField>
                {modalMode === ModalModes.EDIT && (
                    <ActiveEffectiveDateSelector
                        activeEffectiveDate={activeStatusEffectiveDate}
                        setActiveEffectiveDate={setActiveStatusEffectiveDate}
                        originalActiveEffectiveDate={originalActivityEffectiveDate}
                        isActive={isActive}
                        originalIsActive={originalIsActive}
                        checkValidChange={checkValidActiveEffectiveDate}
                        itemType={ITEM_TYPES.ORG}
                        itemId={
                            orgContext.selectedOrgs && orgContext.selectedOrgs.length
                                ? orgContext.selectedOrgs[0].org_id
                                : ''
                        }
                        itemName={
                            orgContext.selectedOrgs && orgContext.selectedOrgs.length
                                ? orgContext.selectedOrgs[0].org_name
                                : 'Org'
                        }
                        itemCreationDate={orgCreationDate}
                    />
                )}
            </SpaceBetween>
        </Modal>
    )
}

export default CreateOrg
