import React, { useEffect, useState } from 'react'
import OrgList from './OrgList'
import CreateOrg from './CreateOrg'
import { useAppContext } from '../../../context'
import { Alert, Box, ContentLayout, Flashbar, SpaceBetween } from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { AlertTypes, ModalModes } from '../../Constant'
import { CREATE_TEAM_REQUEST } from '../../common/LinkUtil'
import { LIMITED_ACCESS_MESSAGE } from '../reusable/TextUtil'
import DeleteModal from '../reusable/DeleteModal'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import useStore from '../../Store'
import { defaultState, OrgContext, OrgContextType } from './constants/OrgContext'
import { getAllGroupsUnderOrg, getOrgTeamMapping } from './OrgUtil'

const SetupOrg = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const canAdmin = useStore((state) => state.canAdmin)
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [orgs, setOrgs] = useState([])
    const [groups, setGroups] = useState({})
    const [teams, setTeams] = useState({})
    const [isGroupsLoading, setIsGroupsLoading] = useState(false)
    const [isTeamsLoading, setIsTeamsLoading] = useState(false)
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [modalMode, setModalMode] = useState<ModalModes>(ModalModes.NOT_SET)
    const [isOrgLoading, setIsOrgLoading] = useState(true)
    const [selectedOrgs, setSelectedOrgs] = useState<any>([])
    const [alertContent, setAlertContent] = useState<string>('')
    const [alertType, setAlertType] = useState<AlertTypes>(AlertTypes.INFO)
    const [orgContext, setOrgContext] = useState<OrgContextType>(defaultState)
    const [showActiveOrgs, setShowActiveOrgs] = useState<boolean>(true)

    const handleCreate = () => {
        setModalMode(ModalModes.CREATE)
        setCreateModalVisible(true)
    }

    const handleEdit = () => {
        setModalMode(ModalModes.EDIT)
        setCreateModalVisible(true)
    }

    const handleDelete = () => {
        setDeleteModalVisible(true)
    }

    const handleCreateModalClose = () => {
        setCreateModalVisible(false)
    }

    const getAllOrgsByBusinessEntity = () => {
        setIsOrgLoading(true)
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/orgs`)
            .then((response) => {
                const allOrgs = response.data.filter((org) => canAdmin || org.is_active)
                setOrgs(allOrgs)
                setIsOrgLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsOrgLoading(false)
            })
    }

    const deleteOrg = () => {
        setDeleteModalVisible(false)
        setIsOrgLoading(true)
        apiClient
            .delete(`/org/${selectedOrgs[0]['org_id']}`)
            .then(() => {
                getAllOrgsByBusinessEntity()
                setAlertType(AlertTypes.SUCCESS)
                setAlertContent(`Successfully deleted organization ${selectedOrgs[0].org_name}.`)
                setSelectedOrgs([])
            })
            .catch((error) => {
                console.error(error)
                setAlertType(AlertTypes.ERROR)
                setAlertContent(
                    `Failed to delete because organization ${error.response.data} Delete the groups and teams before deleting the organization.`,
                )
                setSelectedOrgs([])
                setIsOrgLoading(false)
            })
    }

    useEffect(() => {
        BusinessEntityRefresh(selectBusinessEntity.id, getAllOrgsByBusinessEntity)
    }, [selectBusinessEntity])

    useEffect(() => {
        setOrgContext({ ...orgContext, selectedOrgs: selectedOrgs })
    }, [selectedOrgs])

    useEffect(() => {
        setIsTeamsLoading(true)
        setIsGroupsLoading(true)
        getAllGroupsUnderOrg(orgs, apiClient, setGroups, setIsGroupsLoading)
        getOrgTeamMapping(orgs, apiClient, setTeams, setIsTeamsLoading)
        setOrgContext({
            ...orgContext,
            orgs: orgs,
            onSelectionChange: setSelectedOrgs,
            selectedOrgs: [],
        })
    }, [orgs])

    useEffect(() => {
        setOrgContext({
            ...orgContext,
            selectedOrgs: orgContext.selectedOrgs.filter((org) => !showActiveOrgs || org.is_active),
        })
    }, [showActiveOrgs])

    useEffect(() => {
        if (!isGroupsLoading) {
            setOrgContext({ ...orgContext, groups: groups })
        }
    }, [isGroupsLoading])

    useEffect(() => {
        if (!isTeamsLoading) {
            setOrgContext({ ...orgContext, teams: teams })
        }
    }, [isTeamsLoading])

    return (
        <OrgContext.Provider value={orgContext}>
            <ContentLayout
                header={
                    <Box margin={{ left: 's', right: 's' }}>
                        <SpaceBetween size='xs' direction='vertical'>
                            <HeaderTemplate
                                items={[
                                    { text: 'Home', href: '/' },
                                    { text: 'Organizations', href: '' },
                                ]}
                            />
                            {!canAdmin && (
                                <Alert header='Limited Access'>
                                    <LIMITED_ACCESS_MESSAGE
                                        item='organization'
                                        link={CREATE_TEAM_REQUEST}
                                    />
                                </Alert>
                            )}
                            {alertContent !== '' && (
                                <Flashbar
                                    items={[
                                        {
                                            onDismiss: () => {
                                                setAlertContent('')
                                            },
                                            dismissible: true,
                                            content: alertContent,
                                            type: alertType,
                                        },
                                    ]}
                                />
                            )}
                        </SpaceBetween>
                    </Box>
                }
            >
                <DeleteModal
                    title='Organization'
                    visible={deleteModalVisible}
                    onDismiss={() => setDeleteModalVisible(false)}
                    onDelete={deleteOrg}
                />
                <Box margin={{ left: 's', right: 's' }}>
                    <OrgList
                        canAdmin={canAdmin}
                        onCreate={handleCreate}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        isLoading={isOrgLoading}
                        showActiveOrgs={showActiveOrgs}
                        setShowActiveOrgs={setShowActiveOrgs}
                    />
                </Box>
                <CreateOrg
                    modalMode={modalMode}
                    modalVisible={createModalVisible}
                    closeModalHandler={handleCreateModalClose}
                    setAlertContent={setAlertContent}
                    setAlertType={setAlertType}
                    onIsOrgLoadingChange={setIsOrgLoading}
                    refreshList={getAllOrgsByBusinessEntity}
                />
            </ContentLayout>
        </OrgContext.Provider>
    )
}

export default SetupOrg
