export const GRAND_TOTAL_COLOR_CODE = '#AEAEAE'
export const NORMAL_CELL_COLOR_CODE = '#FFFFFF'

export const enum DATA_TYPE {
    TEXT = 'text',
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    DATE = 'date',
    DATE_STRING = 'dateString',
    OBJECT = 'object',
}

export const enum FILTER_TYPE {
    TEXT = 'agTextColumnFilter',
    NUMBER = 'agNumberColumnFilter',
    SET = 'agSetColumnFilter',
}
