import React, { useState } from 'react'
import useStore from '../../Store'
import AllocationHeader from './AllocationHeader'
import AllocationTable from './AllocationTable'
import AllocationAction from './AllocationAction'
import AllocationNote from './AllocationNote'
import {
    Box,
    Container,
    ContentLayout,
    Flashbar,
    Grid,
    SpaceBetween,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { ALERT_TEMPLATE } from '../reusable/TextUtil'
import { PIE_CHART_STATUS } from '../../Constant'
import AllocationSummary from './AllocationSummary'

import './AllocationPage.scss'

const AllocationPage = () => {
    const allocationPageAlert = useStore((state) => state.allocationPageAlert)
    const setAllocationPageAlert = useStore((state) => state.setAllocationPageAlert)
    const allocationAddProjectAlert = useStore((state) => state.allocationAddProjectAlert)
    const setAllocationAddProjectAlert = useStore((state) => state.setAllocationAddProjectAlert)
    const selectedTeam = useStore((state) => state.selectedTeam)

    const [isReadOnly, setIsReadOnly] = useState(false)
    const [isAllocationLoading, setIsAllocationLoading] = useState(false)
    const [alert, setAlert] = useState({
        type: '',
        header: '',
        content: '',
    })
    const [hrAlert, setHrAlert] = useState({
        type: '',
        header: '',
        content: '',
    })
    const [chartStatus, setChartStatus] = useState<PIE_CHART_STATUS>(PIE_CHART_STATUS.FINISHED)

    const allocationDisabled = () => {
        return isAllocationLoading || isReadOnly
    }

    return (
        <ContentLayout
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween size='s' direction='vertical'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                {
                                    text: 'Allocation Browser',
                                    href: `/history${selectedTeam.value ? `/team/${selectedTeam.value}` : ''}`,
                                },
                                { text: 'Allocation', href: '' },
                            ]}
                        />
                        {alert.content && !isAllocationLoading ? (
                            <ALERT_TEMPLATE
                                type={alert.type}
                                header={alert.header}
                                text={alert.content}
                            />
                        ) : (
                            <></>
                        )}
                        {hrAlert.content && !isAllocationLoading ? (
                            <ALERT_TEMPLATE
                                type={hrAlert.type}
                                header={hrAlert.header}
                                text={hrAlert.content}
                            />
                        ) : (
                            <></>
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <Box margin={{ left: 's', right: 's' }}>
                <SpaceBetween direction='vertical' size='m'>
                    {allocationPageAlert.content === '' || isAllocationLoading ? (
                        <></>
                    ) : (
                        <Flashbar
                            items={[
                                {
                                    onDismiss: () => {
                                        setAllocationPageAlert({ content: '' })
                                    },
                                    dismissible: true,
                                    dismissLabel: 'Dismiss message',
                                    content: allocationPageAlert.content,
                                    type: allocationPageAlert.type,
                                },
                            ]}
                        />
                    )}
                    {allocationAddProjectAlert.content === '' || isAllocationLoading ? (
                        <></>
                    ) : (
                        <Flashbar
                            items={[
                                {
                                    onDismiss: () => {
                                        setAllocationAddProjectAlert({ content: '' })
                                    },
                                    dismissible: true,
                                    dismissLabel: 'Dismiss message',
                                    content: allocationAddProjectAlert.content,
                                    type: allocationAddProjectAlert.type,
                                },
                            ]}
                        />
                    )}
                    <Container>
                        <SpaceBetween direction='vertical' size='m'>
                            <Grid
                                gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}
                                data-container='align-items'
                            >
                                <SpaceBetween direction='vertical' size='m'>
                                    <AllocationHeader
                                        onIsReadOnlyChange={setIsReadOnly}
                                        onChartStatusChange={setChartStatus}
                                        isAllocationLoading={isAllocationLoading}
                                        onIsAllocationLoadingChange={setIsAllocationLoading}
                                        onAlertChange={setAlert}
                                        onHrAlertChange={setHrAlert}
                                        allocationDisabled={() => allocationDisabled()}
                                    />
                                    <AllocationTable
                                        isAllocationLoading={isAllocationLoading}
                                        allocationDisabled={() => allocationDisabled()}
                                    />
                                </SpaceBetween>
                                <SpaceBetween direction='vertical' size='m'>
                                    <AllocationNote
                                        isLoading={isAllocationLoading}
                                        isDisabled={() => allocationDisabled()}
                                    />
                                    <AllocationSummary
                                        chartStatus={chartStatus}
                                        onChartStatusChange={setChartStatus}
                                    />
                                </SpaceBetween>
                            </Grid>
                            <AllocationAction
                                allocationDisabled={() => allocationDisabled()}
                                isLoading={isAllocationLoading}
                            />
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
            </Box>
        </ContentLayout>
    )
}

export default AllocationPage
