import {
    Box,
    Button,
    ContentLayout,
    Flashbar,
    SpaceBetween,
    StatusIndicator,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../../reusable/HeaderTemplate'
import {
    DESCRIPTION_LENGTH_LIMIT,
    AlertTypes,
    SELECT_BUSINESS_ENTITY,
    STATUS_INDICATOR_TYPES,
    TIME_STRING,
} from '../../../Constant'
import { useEffect, useState } from 'react'
import StatusOrgTable from './StatusOrgTable'
import StatusTeamTable from './StatusTeamTable'
import OrgStatusIndicator from './OrgStatusIndicator'
import { getDateFormat } from '../../../common/Util'
import {
    calculateStatus,
    formatDashboardTeams,
    getAllocationStatus,
    getLastFriday,
    getNotificationCountStatus,
    isDateEnabled,
} from '../../reusable/AllocationUtils'
import { useAppContext } from '../../../../context'
import LinkButton from '../../reusable/LinkButton'
import StatusInfoPopover from '../../reusable/StatusInfoPopover'
import TextPopover from '../../reusable/TextPopover'
import useStore from '../../../Store'
import StatusNotificationModal from './StatusNotificationModal'
import {
    formatSlackChannelOptions,
    formatSlackMessageTemplates,
    convertSelectionValueToLabel,
    getAllocationUpdateTemplateMessage,
    getAliasMentionSlackFormat,
    NO_SLACK_CHANNELS_OPTION,
    NO_MESSAGE_TEMPLATES_OPTION,
    getAllIncompleteTeams,
} from './NotificationUtils'
import { scrollToTopLeft } from '../../reusable/AllocationUtils'
import BusinessEntityRefresh from '../../reusable/BusinessEntityRefresh'

const StatusPage = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias

    const lastFriday = getDateFormat(getLastFriday(new Date()))

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [selectEndDate, setSelectEndDate] = useState(lastFriday)
    const [orgs, setOrgs] = useState<any[]>([])
    const [selectedOrgs, setSelectedOrgs] = useState<any>([])
    const [isOrgLoading, setIsOrgLoading] = useState(true)
    const [teams, setTeams] = useState([])
    const [selectedTeams, setSelectedTeams] = useState<any>([])
    const [notificationTeams, setNotificationTeams] = useState<any>([])
    const [isTeamLoading, setIsTeamLoading] = useState(false)
    const [alertContent, setAlertContent] = useState<string>('')
    const [alertType, setAlertType] = useState<AlertTypes>(AlertTypes.INFO)
    const [sendNotificationModalVisible, setSendNotificationModalVisible] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState<string>('')
    const [slackSelectedOption, setSlackSelectedOption] = useState(NO_SLACK_CHANNELS_OPTION)
    const [slackOptions, setSlackOptions] = useState<any[]>([])
    const [templateSelectedOption, setTemplateSelectedOption] = useState(
        NO_MESSAGE_TEMPLATES_OPTION,
    )
    const [templateOptions, setTemplateOptions] = useState<any[]>([])
    const [displayAliases, setDisplayAliases] = useState('')
    const [isNotificationLoading, setIsNotificationLoading] = useState(false)
    const [isCloseButtonLoading, setIsCloseButtonLoading] = useState(false)
    const [isDateValid, setIsDateValid] = useState(true)
    const [dateErrorText, setDateErrorText] = useState('')

    const isTeamButtonsLoading = () => {
        return isOrgLoading || isTeamLoading || isNotificationLoading || isCloseButtonLoading
    }

    const handleRefresh = () => {
        setAlertContent('')
        getAllOrgsByBusinessEntityDate()
        getTeamStatusUnderOrgsByDate(selectEndDate)
        setSelectedTeams([])
    }

    const handleNotificationModalClose = () => {
        setSendNotificationModalVisible(false)
        setNotificationMessage('')
        setSlackSelectedOption(slackOptions[0] || NO_SLACK_CHANNELS_OPTION)
        setTemplateSelectedOption(templateOptions[0] || NO_MESSAGE_TEMPLATES_OPTION)
    }

    const handleClickSendNotification = (detailId: string) => {
        if (detailId === 'selected_teams') {
            // sorted by org for alias mention
            setNotificationTeams(
                selectedTeams.sort(function compareFn(a, b) {
                    return a['org_id'].localeCompare(b['org_id'])
                }),
            )
            setSendNotificationModalVisible(true)
            return
        }

        setIsNotificationLoading(true)
        const org_ids = orgs.map((org) => org['org_id']).join(',')
        apiClient
            .get(`/admin/dashboard/week/${selectEndDate}/teams?org_ids=${org_ids}`)
            .then((response) => {
                const res = response.data.flat()
                formatDashboardTeams(res)
                detailId === 'all_teams'
                    ? setNotificationTeams(res)
                    : setNotificationTeams(getAllIncompleteTeams(res))
                setIsNotificationLoading(false)
                setSendNotificationModalVisible(true)
            })
            .catch((error) => {
                console.error(error)
                setIsNotificationLoading(false)
            })
    }

    const generateNotificationPayload = () => {
        return {
            user_alias: userAlias,
            slack_channel_id: slackSelectedOption.value,
            week_closed: selectEndDate,
            teams: notificationTeams,
            message:
                getAliasMentionSlackFormat(notificationTeams, false) +
                getAllocationUpdateTemplateMessage(
                    selectEndDate,
                    notificationMessage,
                    templateSelectedOption.value,
                ),
        }
    }

    const sendNotification = () => {
        const payload = generateNotificationPayload()
        setIsNotificationLoading(true)
        apiClient
            .post(`/chirp/slack`, JSON.stringify(payload))
            .then(() => {
                setAlertType(AlertTypes.SUCCESS)
                setAlertContent(
                    `Successfully notified Slack channel #${slackSelectedOption.label}.`,
                )
                setSelectedOrgs([])
                setTeams([])
                setSelectedTeams([])
                setIsNotificationLoading(false)
                handleNotificationModalClose()
                scrollToTopLeft()
            })
            .catch((error) => {
                console.error(error)
                setAlertType(AlertTypes.ERROR)
                setAlertContent(
                    `Failed to notify Slack channel #${slackSelectedOption.label}: ${error.response.data}.`,
                )
                setSendNotificationModalVisible(false)
                setIsNotificationLoading(false)
                scrollToTopLeft()
            })
    }

    const getTeamStatusUnderOrgsByDate = (date: string) => {
        setSelectedTeams([])
        const orgIds = selectedOrgs.map((org) => org['org_id']).join(',')
        if (orgIds) {
            setIsTeamLoading(true)
            apiClient
                .get(`/admin/dashboard/week/${date}/teams?org_ids=${orgIds}`)
                .then((response) => {
                    const res = response.data.flat()
                    formatDashboardTeams(res)
                    setTeams(res)
                    setIsTeamLoading(false)
                })
                .catch((error) => {
                    console.error(error)
                    setIsTeamLoading(false)
                })
        } else {
            setTeams([])
        }
    }

    const handleClickCloseReopen = (detailId: string) => {
        if (detailId === 'close_all_orgs') {
            closeAllOrgs()
        } else if (detailId === 'reopen_all_orgs') {
            reOpenAllOrgs()
        } else if (detailId === 'close_selected_orgs') {
            closeSelectedOrgs()
        } else {
            reopenSelectedOrgs()
        }
    }
    const closeAllOrgs = () => {
        closeReopenAllOrgs(true)
    }

    const reOpenAllOrgs = () => {
        closeReopenAllOrgs(false)
    }

    const closeSelectedOrgs = () => {
        closeReopenSelectedOrgs(true)
    }

    const reopenSelectedOrgs = () => {
        closeReopenSelectedOrgs(false)
    }

    const generateCloseReopenPayload = (isClosing: boolean) => {
        return {
            note: '',
            requester: userAlias,
            status: isClosing ? 'CLOSED' : 'OPEN',
        }
    }

    const closeReopenSelectedOrgs = (isClosingOrgs: boolean) => {
        const payload = generateCloseReopenPayload(isClosingOrgs)
        const orgIds = selectedOrgs.map((org) => org['org_id']).join(',')
        const orgNames = selectedOrgs.map((org) => org['org_name']).join(', ')

        setIsCloseButtonLoading(true)
        apiClient
            .post(
                `/admin/close-status/business-entity/${selectBusinessEntity.id}/week/${selectEndDate}/orgs?org_ids=${orgIds}`,
                JSON.stringify(payload),
            )
            .then((response) => {
                getAllOrgsByBusinessEntityDate()
                setAlertType(AlertTypes.SUCCESS)
                setAlertContent(
                    `Successfully ${
                        isClosingOrgs ? 'closed' : 'reopened'
                    } week ${selectEndDate} for organization ${orgNames}.`,
                )
                setIsCloseButtonLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setAlertType(AlertTypes.ERROR)
                setAlertContent(
                    `Failed to ${
                        isClosingOrgs ? 'closed' : 'reopened'
                    } week ${selectEndDate} for organization ${orgNames}: ${error.response.data}`,
                )
                setIsCloseButtonLoading(false)
            })
    }

    const closeReopenAllOrgs = (isClosing: boolean) => {
        const payload = generateCloseReopenPayload(isClosing)

        setIsCloseButtonLoading(true)
        apiClient
            .post(
                `/admin/close-status/business-entity/${selectBusinessEntity.id}/week/${selectEndDate}`,
                JSON.stringify(payload),
            )
            .then((response) => {
                getAllOrgsByBusinessEntityDate()
                setAlertType(AlertTypes.SUCCESS)
                setAlertContent(
                    `Successfully ${isClosing ? 'closed' : 'reopened'} week ${selectEndDate}.`,
                )
                setIsCloseButtonLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setAlertType(AlertTypes.ERROR)
                setAlertContent(
                    `Failed to ${isClosing ? 'closed' : 'reopened'} week ${selectEndDate}: ${
                        error.response.data
                    }`,
                )
                setIsCloseButtonLoading(false)
            })
    }

    const getAllOrgsByBusinessEntityDate = () => {
        setIsOrgLoading(true)
        setIsCloseButtonLoading(true)
        apiClient
            .get(
                `/admin/dashboard/business-entity/${selectBusinessEntity.id}/week/${selectEndDate}`,
            )
            .then((response) => {
                const allOrgs = response.data.filter(
                    (org) => org?.is_active && org?.team_entry_status?.total_teams,
                )
                setOrgs(allOrgs)
                setSelectedOrgs(
                    allOrgs.filter((org) =>
                        selectedOrgs.some((selectedOrg) => org.org_id === selectedOrg.org_id),
                    ),
                )
                setIsOrgLoading(false)
                setIsCloseButtonLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsOrgLoading(false)
                setIsCloseButtonLoading(false)
            })
    }

    const orgListColumnDef = [
        {
            id: 'org_id',
            header: 'Org ID',
            cell: (e: any) => e.org_id,
        },
        {
            id: 'org_name',
            header: 'Organization',
            cell: (e: any) => e.org_name,
            sortingField: 'org_name',
        },
        {
            id: 'description',
            header: 'Description',
            cell: (e: any) => (
                <TextPopover text={e.description} lengthLimit={DESCRIPTION_LENGTH_LIMIT} />
            ),
            maxWidth: 250,
        },
        {
            id: 'entry_status',
            header: 'Entry Status',
            cell: (e) =>
                e.team_entry_status.total_teams === 0
                    ? 'No team'
                    : `${e.team_entry_status.allocation_completed_teams} / ${e.team_entry_status.total_teams}`,
        },
        {
            id: 'status_indicator',
            header: 'Status Indicator',
            cell: (e) =>
                e.team_entry_status.total_teams === 0 ? (
                    <Flashbar
                        items={[
                            {
                                content: 'Not Available',
                                type: AlertTypes.INFO,
                            },
                        ]}
                    />
                ) : (
                    <OrgStatusIndicator value={calculateStatus(e) * 100} />
                ),
            sortingComparator: (a, b) => {
                const totalA = a.team_entry_status.total_teams
                const totalB = b.team_entry_status.total_teams
                if (totalA === 0 && totalB === 0) {
                    return 0
                }
                if (totalA === 0) {
                    return -1
                }
                if (totalB === 0) {
                    return 1
                }

                const statusA = calculateStatus(a)
                const statusB = calculateStatus(b)
                if (statusA > statusB) {
                    return 1
                }
                if (statusA < statusB) {
                    return -1
                }
                return 0
            },
        },
        {
            id: 'week_closed',
            header: 'Week Closed',
            cell: (e: any) => (
                <StatusIndicator
                    type={
                        e.week_closed
                            ? STATUS_INDICATOR_TYPES.SUCCESS
                            : STATUS_INDICATOR_TYPES.WARNING
                    }
                >
                    {e.week_closed ? 'Yes' : 'No'}
                </StatusIndicator>
            ),
            sortingField: 'week_closed',
        },
    ]

    const teamListColumnDef = [
        {
            id: 'team_id',
            header: 'Team ID',
            cell: (e: any) => e.team_id,
        },
        {
            id: 'team_name',
            header: 'Team',
            cell: (e: any) => e.team_name,
            sortingField: 'team_name',
        },
        {
            id: 'org_name',
            header: 'Organization',
            cell: (e: any) => e.org_name,
            sortingField: 'org_name',
        },
        {
            id: 'group_name',
            header: 'Group',
            cell: (e: any) => e.group_name,
            sortingField: 'group_name',
        },
        {
            id: 'allocation_page',
            header: (
                <StatusInfoPopover
                    title='Allocation Page'
                    popoverContent='Redirect to the allocation page.'
                />
            ),
            cell: (e: any) => (
                <LinkButton
                    path={`/allocation/${e.team_id}/${selectEndDate}`}
                    variant='link'
                    iconName='user-profile'
                    target={'_blank'}
                />
            ),
        },
        {
            id: 'allocation_browser_page',
            header: (
                <StatusInfoPopover
                    title='Browser Page'
                    popoverContent='Redirect to the allocation browser page.'
                />
            ),
            cell: (e: any) => (
                <LinkButton
                    path={`/history/team/${e.team_id}`}
                    variant='link'
                    iconName='calendar'
                    target={'_blank'}
                />
            ),
        },
        {
            id: 'allocations_headcount',
            header: 'Allocations / Headcount',
            cell: (e) => `${e.total_allocation} / ${e.total_headcount}`,
        },
        {
            id: 'status',
            header: 'Status',
            cell: (e) => getAllocationStatus(e.status),
            sortingField: 'status',
        },
        {
            id: 'notification_count',
            header: 'Notification Count',
            cell: (e) => getNotificationCountStatus(e.notification_count),
            sortingField: 'notification_count',
        },
        {
            id: 'primary_alias',
            header: 'Primary Contact',
            cell: (e) => e.primary_alias || '-',
            sortingField: 'primary_alias',
        },
        {
            id: 'registered_users',
            header: 'Registered Users',
            cell: (e) => e.registered_users || '-',
        },
    ]

    const orgVisibleColumns = [
        'org_name',
        'description',
        'entry_status',
        'status_indicator',
        'week_closed',
    ]

    const teamVisibleColumns = [
        'team_name',
        'org_name',
        'group_name',
        'allocation_page',
        'allocation_browser_page',
        'allocations_headcount',
        'status',
        'notification_count',
        'primary_alias',
        'registered_users',
    ]

    useEffect(() => {
        if (selectBusinessEntity.name === SELECT_BUSINESS_ENTITY) {
            return
        }

        const slackChannels = selectBusinessEntity.slack_channels
        const slackMessageTemplates = selectBusinessEntity.slack_message_templates
        if (slackChannels && slackChannels.length > 0) {
            setSlackSelectedOption({
                label: slackChannels[0].slack_channel_name,
                value: slackChannels[0].slack_channel_id,
            })
            setSlackOptions(formatSlackChannelOptions(slackChannels))
        } else {
            setSlackSelectedOption(NO_SLACK_CHANNELS_OPTION)
            setSlackOptions([])
        }
        if (slackMessageTemplates && slackMessageTemplates.length > 0) {
            setTemplateSelectedOption({
                label: convertSelectionValueToLabel(slackMessageTemplates[0][0]),
                value: slackMessageTemplates[0][1],
            })
            setTemplateOptions(formatSlackMessageTemplates(slackMessageTemplates))
        } else {
            setTemplateSelectedOption(NO_MESSAGE_TEMPLATES_OPTION)
            setTemplateOptions([])
        }
    }, [selectBusinessEntity])

    useEffect(() => {
        if (sendNotificationModalVisible) {
            setDisplayAliases(getAliasMentionSlackFormat(notificationTeams, true))
        }
    }, [sendNotificationModalVisible])

    useEffect(() => {
        if (!isDateEnabled(new Date(selectEndDate + TIME_STRING))) {
            setIsDateValid(false)
            setDateErrorText('Invalid date. Use YYYY/MM/DD format. Friday only.')
        } else {
            setIsDateValid(true)
            setDateErrorText('')
            BusinessEntityRefresh(selectBusinessEntity.id, getAllOrgsByBusinessEntityDate)
        }
        setTeams([])
        setSelectedTeams([])
    }, [selectBusinessEntity, selectEndDate])

    return (
        <ContentLayout
            header={
                <Box margin={{ top: 's', left: 's', right: 's' }}>
                    <SpaceBetween size='m'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                { text: 'Status Dashboard', href: '' },
                            ]}
                            actions={
                                <Button iconName='refresh' onClick={() => handleRefresh()}></Button>
                            }
                        />
                        {alertContent === '' ? (
                            <></>
                        ) : (
                            <Flashbar
                                items={[
                                    {
                                        onDismiss: () => {
                                            setAlertContent('')
                                        },
                                        dismissible: true,
                                        content: alertContent,
                                        type: alertType,
                                    },
                                ]}
                            />
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <StatusNotificationModal
                modalVisible={sendNotificationModalVisible}
                onModalVisibleChange={handleNotificationModalClose}
                modalTextValue={notificationMessage}
                onModalTextValueChange={setNotificationMessage}
                selectEndDate={selectEndDate}
                slackSelectedOption={slackSelectedOption}
                slackOptions={slackOptions}
                onSlackSelectedOptionChange={setSlackSelectedOption}
                templateSelectedOption={templateSelectedOption}
                onTemplateSelectedOptionChange={setTemplateSelectedOption}
                templateOptions={templateOptions}
                onSendNotification={sendNotification}
                teams={notificationTeams}
                displayAliases={displayAliases}
                isSendingNotification={isNotificationLoading}
            />
            <Box margin={{ left: 's', right: 's', bottom: 's' }}>
                <SpaceBetween size='l' direction='vertical'>
                    <StatusOrgTable
                        selectEndDate={selectEndDate}
                        onSelectEndDateChange={setSelectEndDate}
                        selectedOrgs={selectedOrgs}
                        onSelectedOrgsChange={setSelectedOrgs}
                        orgs={orgs}
                        columns={orgListColumnDef}
                        visibleColumns={orgVisibleColumns}
                        isLoading={isOrgLoading}
                        isTeamLoading={isTeamLoading}
                        onClickCloseReopen={handleClickCloseReopen}
                        isCloseButtonLoading={isCloseButtonLoading}
                        getTeamStatus={getTeamStatusUnderOrgsByDate}
                        isDateValid={isDateValid}
                        dateErrorText={dateErrorText}
                        onAlertContentChange={setAlertContent}
                    />
                    <StatusTeamTable
                        teams={teams}
                        selectedTeams={selectedTeams}
                        onSelectedTeamsChange={setSelectedTeams}
                        isLoading={isTeamLoading}
                        isOrgLoading={isOrgLoading}
                        onClickSendNotification={handleClickSendNotification}
                        columns={teamListColumnDef}
                        visibleColumns={teamVisibleColumns}
                        isTeamButtonsLoading={() => isTeamButtonsLoading()}
                    />
                </SpaceBetween>
            </Box>
        </ContentLayout>
    )
}

export default StatusPage
