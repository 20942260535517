import { v4 as uuidv4 } from 'uuid'
import dateFormat from 'dateformat'
import {
    BADGE_COLOR,
    BADGE_NAME,
    DEFAULT_PLAN_NAME,
    DEFAULT_REVISION_NAME,
    FALCON,
    HOLIDAY,
} from '../Constant'
import getUrls from 'get-urls'
import { Link } from '@amzn/awsui-components-react'
import React from 'react'
import _ from 'lodash'

export const getDateFormat = (date: Date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
}

export const getDateByDayDifference = (dateString: string, dayDifference: number) => {
    const date = new Date(dateString)
    date.setUTCDate(date.getUTCDate() + dayDifference)
    return getDateFormat(date)
}

export const generateId = () => {
    return uuidv4()
}

export const convertToLocalTime = (utcDateTime) => {
    return utcDateTime ? dateFormat(new Date(utcDateTime), 'yyyy-mm-dd HH:MM:ss') : ''
}

export const isDuplicateByPropertyValue = (
    skipIdName: string,
    skipId: string,
    list,
    property: string,
    value,
) => {
    // skip check duplicate by skipIdName and skipId if that item is current editing
    return (
        list.findIndex((item) => {
            if (item[skipIdName] === skipId) {
                return false
            }
            return item[property] === value
        }) !== -1
    )
}

export const convertAlertContentWithLink = (content: string, link_content: string) => {
    return (
        <>
            {content.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '')}{' '}
            <Link
                external
                color='inverted'
                externalIconAriaLabel='Opens in a new tab'
                href={Array.from(getUrls(content)).join(',')}
            >
                {link_content}
            </Link>
        </>
    )
}

export const isValidURL = (url: string) => {
    try {
        const parsedURL = new URL(url)
        return parsedURL.protocol === 'http:' || parsedURL.protocol === 'https:'
    } catch {
        return false
    }
}

export const isValidNumericString = (inputStr) => {
    const digitAndDecimals = new RegExp('\\d*\\.?\\d+')
    return !inputStr.replace(digitAndDecimals, '').length
}

export const isValidChanged = (value, selected, prop) => {
    if (!selected) {
        return value !== undefined
    }

    return selected[prop] !== value
}

export const isValidChangedProgramProject = (value, selected, prop) => {
    if (!selected) {
        return false
    }
    if (prop === 'is_op_goal') {
        return selected[prop].toLowerCase() !== value.toString()
    }
    if (prop === 'is_active') {
        if (!(prop in selected)) {
            return 'true' !== value.toString()
        }
        return selected[prop].toLowerCase() !== value.toString()
    }

    return selected[prop] !== value
}

export const addBadgesToList = (
    attributeName: string,
    list,
    newList,
    color: BADGE_COLOR,
    badgeName: BADGE_NAME,
) => {
    const result = [...list]
    const tempNewList = [...newList]
    result.forEach(function (item: any) {
        const index = tempNewList.findIndex(
            (newItem) => newItem[attributeName] === item[attributeName],
        )
        if (index !== -1) {
            item['badge'] = badgeName
            tempNewList.slice(index, 1)
        } else {
            item['badge'] = ''
        }
    })
    return result
}

export const isProgramProjectActiveForYear = (item) => {
    return !('is_active_for_year' in item) || item.is_active_for_year === 'True'
}

export const isProgramProjectActive = (item) => {
    return !('is_active' in item) || item.is_active === 'True'
}

export const formatYearSelection = (years) => {
    return years
        .map((year) => ({
            label: year,
            value: year,
        }))
        .sort((a, b) => b.label - a.label)
}

export const filterOrgsGroupsTeams = (items: any[], isGroup: boolean, isOrg?: boolean) => {
    if (_.isEmpty(items)) {
        return []
    }
    return items.filter(
        (item) =>
            item?.is_active &&
            !item?.archived &&
            (!isGroup || (item?.is_falcon && item?.number_of_teams)) &&
            (!isOrg || item?.number_of_groups?.falcon),
    )
}

export const getFalconGroups = (allGroups: any[]) => {
    return (allGroups || []).filter((gp) => gp?.is_falcon)
}

export const filterActiveGroupsTeams = (allItems: any[]) => {
    return (allItems || []).filter((item) => item.is_active)
}

export const styleHeaderWithNoWrap = (headerText) => {
    return <p style={{ whiteSpace: 'nowrap' }}>{headerText}</p>
}

export const styleHeaderConditionally = (attrDef) => {
    const content = attrDef?.headerDisplay ? attrDef.headerDisplay : attrDef.headerName
    return attrDef?.tableVisible || attrDef?.summaryVisible
        ? styleHeaderWithNoWrap(content)
        : content
}

export const sortObjectsByField = (objectList: any[], fieldName: string) => {
    return objectList.sort((obj1, obj2) => (obj1[fieldName] < obj2[fieldName] ? -1 : 1))
}

export const parseYearFromDateString = (dateString: string | null) => {
    return dateString && dateString.length >= 4 ? dateString.substring(0, 4) : null
}

export const getHolidaySession = () => {
    const today = new Date()
    const currentYear = today.getFullYear()
    const octoberFirstString = new Date(`${currentYear}-10-01`)
    const octoberLastString = new Date(`${currentYear}-10-31`)
    const nextJanuaryFifthLastString = new Date(`${currentYear + 1}-01-05`)

    switch (true) {
        case today >= octoberFirstString && today <= octoberLastString:
            return HOLIDAY.HALLOWEEN
        case today > octoberLastString && today <= nextJanuaryFifthLastString:
            return HOLIDAY.CHRISTMAS
        default:
            return FALCON
    }
}

export const getStageLabel = () => {
    try {
        const hostname = new URL(window.location.href).hostname
        if (hostname.includes('localhost') || /^127\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname)) {
            return '[Local]'
        } else if (hostname.startsWith('beta.')) {
            return '[Beta]'
        } else if (hostname.startsWith('gamma.')) {
            return '[Gamma]'
        }
    } catch (err) {
        console.error('Failed to parse URL', err)
    }
    return ''
}

export const isAllocationActive = (allocation, teamItem, programItem, projectItem) => {
    const teamActiveStatus = teamItem?.is_active ?? true
    const defaultDate = getDateFormat(new Date())
    const isItemActive = (item, activeStatus) => {
        const itemEffectiveDate = item?.active_status_effective_date ?? defaultDate
        return (
            activeStatus ||
            (!activeStatus && new Date(itemEffectiveDate) > new Date(allocation['allocation_week']))
        )
    }
    const programActiveStatus = isProgramProjectActive(programItem)
    const projectActiveStatus = isProgramProjectActive(projectItem)
    return (
        isItemActive(teamItem, teamActiveStatus) &&
        isItemActive(programItem, programActiveStatus) &&
        isItemActive(projectItem, projectActiveStatus)
    )
}

export const getDefaultDate = () => {
    return getDateFormat(new Date())
}

export const generatePlanName = (planMetadata) => {
    if (_.isEmpty(planMetadata)) {
        return DEFAULT_PLAN_NAME
    }
    return `${planMetadata.plan_type} ${planMetadata.year}`
}

export const getDateFromEgretRevisionId = (revisionId) => {
    return convertToLocalTime(parseInt(revisionId)).split(' ')[0]
}
export const generateRevisionName = (planMetadata, revisionId) => {
    const revisions = planMetadata?.revisions || []
    if (_.isEmpty(planMetadata) || _.isEmpty(revisions)) {
        return DEFAULT_REVISION_NAME
    }
    const selectedRevision = revisions.find((rev) => rev.revision_id === revisionId)
    const revisionIdAsDate = getDateFromEgretRevisionId(revisionId)
    const revNumber = selectedRevision?.revision_number
    const revName = revNumber ? `Revision ${revNumber}` : DEFAULT_REVISION_NAME
    return `${revName} (${revisionIdAsDate})`
}
export const generatePlanUrl = (egretBaseUrl, item) => {
    if (!item?.plan_id || !item?.revision_id) {
        return egretBaseUrl
    }
    return `${egretBaseUrl}/plan/${item.plan_id}/revision/${item.revision_id}`
}

export const convertToMoneyFormat = (numericInp: any) => {
    if (!numericInp) {
        return ''
    }
    const num = Number(String(numericInp))
    if (isNaN(num)) {
        return numericInp
    }
    return num.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    })
}

export const getDateNYearsAgo = (numYears) => {
    const today = new Date()
    const nYearsAgoYear = today.getFullYear() - numYears
    return new Date(nYearsAgoYear, today.getMonth(), today.getDate())
}
