import { ColDef } from 'ag-grid-community'
import { ITextFilterParams } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { FILTER_TYPE, DATA_TYPE } from './Constants'

const generateFilterParams = (colDef, params) => {
    switch (colDef.cellDataType) {
        case DATA_TYPE.BOOLEAN:
            return params.value ? 'Yes' : 'No'
        default:
            return params.value
    }
}

export const addGeneralFilterParams = (colDef: ColDef) => {
    colDef.menuTabs = ['generalMenuTab', 'columnsMenuTab']
    colDef.suppressColumnsToolPanel = colDef.hide
    colDef.floatingFilter = true
    colDef.filter = colDef.filter ?? FILTER_TYPE.SET
    colDef.filterParams = {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        valueFormatter: (params) => {
            if (colDef.filter === FILTER_TYPE.SET) {
                return generateFilterParams(colDef, params)
            }
        },
    } as ITextFilterParams
}
