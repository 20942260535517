import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    FormField,
    Modal,
    Input,
    SpaceBetween,
    Textarea,
    Alert,
    ColumnLayout,
    Header,
    Icon,
    Link,
    Table,
    Toggle,
} from '@amzn/awsui-components-react'
import { getDefaultDate, isDuplicateByPropertyValue, isValidChanged } from '../../common/Util'
import {
    AlertTypes,
    GROUP_NAME_VALIDATION,
    GROUP_NAME_DUPLICATE,
    validPermissionGroup,
    ACCESS_CONTROL_VALIDATION,
    DESCRIPTION_LENGTH_LIMIT,
    ITEM_TYPES,
} from '../../Constant'
import { useAppContext } from '../../../context'
import useStore from '../../Store'
import TextPopover from '../reusable/TextPopover'
import ActiveEffectiveDateSelector from '../reusable/ActiveEffectiveDateSelector'
import { TokenGroup, TokenGroupProps } from '@amzn/awsui-components-react/polaris'
import PrimaryAliasRegisteredUsersInput from '../reusable/PrimaryAliasRegisteredUsersInput'
import FalconEgretUsageToggle from '../reusable/FalconEgretUsageToggle'

const EditGroup = ({
    orgId,
    orgName,
    groups,
    selectedGroups,
    onSelectedGroupsChange,
    groupEditModalVisible,
    onGroupEditModalDismiss,
    onOrgHeaderAlertChange,
    onGroupLoadingChange,
    refreshGroupsList,
    activeTeams,
    refreshTeamsList,
}) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [groupName, setGroupName] = useState('')
    const [groupNameError, setGroupNameError] = useState('')
    const [description, setDescription] = useState('')
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false)
    const defaultDate = getDefaultDate()
    const [groupCreationDate, setGroupCreationDate] = useState<string>(defaultDate)
    const [activeStatusEffectiveDate, setActiveStatusEffectiveDate] = useState(defaultDate)
    const [originalActivityEffectiveDate, setOriginalActivityEffectiveDate] = useState(defaultDate)
    const [registeredUsers, setRegisteredUsers] = useState<TokenGroupProps.Item[]>([])
    const [primaryContact, setPrimaryContact] = useState<TokenGroupProps.Item[]>([])
    const [hrDataPermissionGroup, setHrDataPermissionGroup] = useState('')
    const [isEgret, setIsEgret] = useState(false)
    const [isFalcon, setIsFalcon] = useState(true)
    const [isActive, setIsActive] = useState(true)
    const groupTeams = selectedGroups.length
        ? activeTeams.filter((team) => team.group_id === selectedGroups[0].group_id)
        : []
    const [originalIsActive, setOriginalIsActive] = useState(true)
    const [hrDataPermissionGroupName, setHrDataPermissionGroupName] = useState<string>('')
    const [hrPermissionGroupError, setHrPermissionGroupError] = useState<string>('')
    const [primaryContactError, setPrimaryContactError] = useState<string>('')
    const [registeredUserError, setRegisteredUserError] = useState<string>('')

    const checkValidActiveEffectiveDate = (newDate) => {
        if (selectedGroups[0]?.is_active) {
            return true
        }
        setIsSaveDisabled(
            !isValidChanged(newDate, selectedGroups[0], 'active_status_effective_date'),
        )
    }

    useEffect(() => {
        if (selectedGroups === undefined || selectedGroups.length === 0) {
            return
        }
        setIsSaveDisabled(true)
        fillAllInputWithSelectedItem(selectedGroups[0])
    }, [groups, selectedGroups])

    const fillAllInputWithSelectedItem = (group) => {
        setGroupName(group['group_name'])
        setDescription(group['description'])
        setHrDataPermissionGroup(group['hr_permission_group'])
        setHrDataPermissionGroupName(group['hr_permission_group_name'])
        setIsFalcon(group['is_falcon'])
        setIsEgret(group['is_egret'])
        setIsActive(group['is_active'])
        const createAtDate = group['created_at'].split('T')[0]
        const activeEffectiveDate = group['is_active']
            ? defaultDate
            : group['active_status_effective_date']
        setGroupCreationDate(createAtDate)
        setOriginalActivityEffectiveDate(activeEffectiveDate)
        setActiveStatusEffectiveDate(activeEffectiveDate)
        setOriginalIsActive(group['is_active'])
        const primaryAlias = group['primary_alias']
        setPrimaryContact(
            primaryAlias
                ? [
                      {
                          label: primaryAlias,
                          dismissLabel: 'Remove ' + primaryAlias,
                      },
                  ]
                : [],
        )
        const allRegisteredUsers = selectedGroups[0].registered_users ?? []
        setRegisteredUsers(
            allRegisteredUsers.map((registeredUser) => ({
                label: registeredUser,
                dismissLabel: `Remove ${registeredUser}`,
            })),
        )
    }

    const clearAllInput = () => {
        setGroupName('')
        setDescription('')
        setHrDataPermissionGroup('')
        setHrDataPermissionGroupName('')
        setRegisteredUsers([])
        setPrimaryContact([])
        setRegisteredUserError('')
        setPrimaryContactError('')
        setIsFalcon(true)
        setIsActive(true)
        setIsEgret(false)
    }

    const handleOnDismiss = () => {
        onGroupEditModalDismiss()
        setIsSaveDisabled(true)
        fillAllInputWithSelectedItem(selectedGroups[0])
        setGroupNameError('')
    }

    const isPayloadValidated = (payload) => {
        if (payload.group_name.trim().length === 0) {
            setGroupNameError(GROUP_NAME_VALIDATION)
            return false
        }

        if (
            isDuplicateByPropertyValue(
                'group_name',
                payload.group_name,
                groups,
                'group_name',
                payload.group_name.trim(),
            )
        ) {
            setGroupNameError(GROUP_NAME_DUPLICATE)
            return false
        }

        return true
    }

    const generateGroupPayload = () => {
        return {
            business_entity_id: selectBusinessEntity.id,
            business_entity_name: selectBusinessEntity.name,
            org_id: orgId,
            org_name: orgName,
            group_id: selectedGroups[0].group_id,
            group_name: groupName.trim(),
            description: description,
            requester: userAlias,
            hr_permission_group: hrDataPermissionGroup,
            hr_permission_group_name: hrDataPermissionGroupName,
            is_egret: isEgret,
            is_falcon: isFalcon,
            is_active: isActive,
            active_status_effective_date: activeStatusEffectiveDate,
            primary_alias: primaryContact.length > 0 ? primaryContact[0].label : '',
            registered_users: registeredUsers.map((item) => item.label),
        }
    }
    const refreshList = () => {
        refreshGroupsList()
        refreshTeamsList()
    }

    const handleClickUpdate = () => {
        const payload = generateGroupPayload()

        if (!isPayloadValidated(payload)) {
            return
        }

        onGroupLoadingChange(true)
        updateGroup(payload)
            .then((res) => {
                refreshList()
                onOrgHeaderAlertChange([
                    {
                        type: AlertTypes.SUCCESS,
                        content: `Successfully updated group ${selectedGroups[0].group_name}.`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => onOrgHeaderAlertChange([]),
                    },
                ])
                clearAllInput()
                onSelectedGroupsChange([])
                onGroupLoadingChange(false)
            })
            .catch((error) => {
                onOrgHeaderAlertChange([
                    {
                        type: AlertTypes.ERROR,
                        content: `Failed to update group ${selectedGroups[0].group_name}: ${error.response.data}`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => onOrgHeaderAlertChange([]),
                    },
                ])
                console.error(error)
                onGroupLoadingChange(false)
                onSelectedGroupsChange([])
            })
        onGroupEditModalDismiss()
    }

    const updateGroup = (payload) => {
        return apiClient.put(
            `/orgs/${orgId}/groups/${selectedGroups[0].group_name}`,
            JSON.stringify(payload),
        )
    }

    return (
        <Modal
            onDismiss={handleOnDismiss}
            header={'Edit Group'}
            size={'large'}
            visible={groupEditModalVisible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleOnDismiss}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleClickUpdate}
                            disabled={isSaveDisabled || groupNameError !== ''}
                        >
                            Update
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='xs'>
                <FormField
                    label='Group'
                    description='Name of the group'
                    errorText={groupNameError}
                    stretch
                >
                    <Input
                        value={groupName}
                        onChange={({ detail }) => {
                            const value = detail.value
                            let errorMessage = ''
                            if (value.trim().length === 0) {
                                errorMessage = GROUP_NAME_VALIDATION
                            } else if (
                                isDuplicateByPropertyValue(
                                    'group_name',
                                    selectedGroups[0].group_name,
                                    groups,
                                    'group_name',
                                    value.trim(),
                                )
                            ) {
                                errorMessage = GROUP_NAME_DUPLICATE
                            }
                            setGroupNameError(errorMessage)
                            setGroupName(value)

                            if (isValidChanged(value, selectedGroups[0], 'group_name')) {
                                setIsSaveDisabled(false)
                            } else {
                                setIsSaveDisabled(true)
                            }
                        }}
                    ></Input>
                </FormField>
                <ColumnLayout columns={2}>
                    <FormField
                        label='Business Entity'
                        description='Business entity that the group belongs to'
                    >
                        <Input readOnly={true} value={selectBusinessEntity.name}></Input>
                    </FormField>
                    <FormField
                        label='Organization'
                        description='Organization that the group belongs to'
                    >
                        <Input readOnly={true} value={orgName}></Input>
                    </FormField>
                </ColumnLayout>
                <FormField
                    stretch
                    label='Headcount Source'
                    description='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks team headcount'
                    errorText={hrPermissionGroupError}
                >
                    <SpaceBetween direction='vertical' size='xs'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Link href='https://permissions.amazon.com/a/user' target={'_blank'}>
                                Find your permission group <Icon name={'external'} />{' '}
                            </Link>
                            <Link
                                href='https://permissions.amazon.com/a/team/new'
                                target={'_blank'}
                            >
                                Create a new permission group <Icon name={'external'} />{' '}
                            </Link>
                        </SpaceBetween>
                        <Input
                            value={hrDataPermissionGroup}
                            onChange={({ detail }) => {
                                const value = detail.value.trim()
                                let errorMessage = ''

                                if (value.length > 0 && !validPermissionGroup.test(value)) {
                                    errorMessage = ACCESS_CONTROL_VALIDATION
                                }
                                setHrPermissionGroupError(errorMessage)
                                setHrDataPermissionGroup(detail.value)
                                setIsSaveDisabled(
                                    !isValidChanged(
                                        value,
                                        selectedGroups[0],
                                        'hr_permission_group',
                                    ),
                                )
                            }}
                        ></Input>
                    </SpaceBetween>
                </FormField>

                <FormField
                    stretch
                    label='Headcount Resource Name'
                    description='Amazon TEAM Name for the headcount data source. For example, Falcon-core-SW-HC'
                >
                    <Input
                        value={hrDataPermissionGroupName}
                        onChange={({ detail }) => {
                            setHrDataPermissionGroupName(detail.value)
                            setIsSaveDisabled(
                                !isValidChanged(
                                    detail.value,
                                    selectedGroups[0],
                                    'hr_permission_group_name',
                                ),
                            )
                        }}
                    ></Input>
                </FormField>
                <PrimaryAliasRegisteredUsersInput
                    registeredUsers={registeredUsers}
                    onRegisteredUsersChange={setRegisteredUsers}
                    onSaveDisabledChange={setIsSaveDisabled}
                    primaryContact={primaryContact}
                    onPrimaryContactChange={setPrimaryContact}
                    itemType={ITEM_TYPES.GROUP}
                    selectedItem={selectedGroups?.length ? selectedGroups[0] : {}}
                    registerUserInputError={registeredUserError}
                    primaryContactInputError={primaryContactError}
                    onPrimaryContactInputErrorChange={setPrimaryContactError}
                    onRegisteredUserInputErrorChange={setRegisteredUserError}
                />

                <FormField label='Description' description='Description of the group' stretch>
                    <Textarea
                        value={description}
                        onChange={({ detail }) => {
                            setDescription(detail.value)

                            if (isValidChanged(detail.value, selectedGroups[0], 'description')) {
                                setIsSaveDisabled(false)
                            } else {
                                setIsSaveDisabled(true)
                            }
                        }}
                    ></Textarea>
                </FormField>
                <ColumnLayout columns={1} borders={'horizontal'}>
                    <FormField label='Active' description='Mark true if it is an active group.'>
                        <Toggle
                            onChange={({ detail }) => {
                                setIsActive(detail.checked)
                                setIsSaveDisabled(
                                    !isValidChanged(detail.checked, selectedGroups[0], 'is_active'),
                                )
                            }}
                            checked={isActive}
                        >
                            Is group active?
                        </Toggle>
                    </FormField>
                    <ActiveEffectiveDateSelector
                        activeEffectiveDate={activeStatusEffectiveDate}
                        setActiveEffectiveDate={setActiveStatusEffectiveDate}
                        originalActiveEffectiveDate={originalActivityEffectiveDate}
                        isActive={isActive}
                        originalIsActive={originalIsActive}
                        checkValidChange={checkValidActiveEffectiveDate}
                        itemType={ITEM_TYPES.GROUP}
                        itemId={
                            selectedGroups && selectedGroups?.length
                                ? selectedGroups[0].group_id
                                : ''
                        }
                        itemName={
                            selectedGroups && selectedGroups.length
                                ? selectedGroups[0].group_name
                                : 'Group'
                        }
                        itemCreationDate={groupCreationDate}
                    />
                    <FalconEgretUsageToggle
                        isFalcon={isFalcon}
                        isEgret={isEgret}
                        onIsFalconChange={setIsFalcon}
                        onIsEgretChange={setIsEgret}
                        onSaveDisabledChange={setIsSaveDisabled}
                        referenceItem={selectedGroups[0] ?? {}}
                    />
                </ColumnLayout>
            </SpaceBetween>
        </Modal>
    )
}

export default EditGroup
