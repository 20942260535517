import React from 'react'
import {
    FormField,
    Input,
    SpaceBetween,
    Textarea,
    TokenGroupProps,
} from '@amzn/awsui-components-react/polaris'
import { isDuplicateByPropertyValue, isValidChanged } from '../../common/Util'
import useStore from '../../Store'
import { Icon, Link, Toggle } from '@amzn/awsui-components-react'
import { ACCESS_CONTROL_VALIDATION, ITEM_TYPES, validPermissionGroup } from '../../Constant'
import PrimaryAliasRegisteredUsersInput from '../reusable/PrimaryAliasRegisteredUsersInput'
import ActiveEffectiveDateSelector from '../reusable/ActiveEffectiveDateSelector'
import FalconEgretUsageToggle from '../reusable/FalconEgretUsageToggle'

export const isGroupNameValidated = (groups, GroupName) => {
    const trimmedGroupName = GroupName.trim()
    return (
        trimmedGroupName.length !== 0 &&
        !isDuplicateByPropertyValue('teamId', 'undefined', groups, 'group_name', trimmedGroupName)
    )
}
export interface CreateGroupProps {
    orgName: string
    groups: any[]
    groupName: string
    onGroupNameChange: (inp: string) => void
    groupNameError: string
    onGroupNameErrorChange: (inp: string) => void
    description: string
    onDescriptionChange: (inp: string) => void
    hrDataPermissionGroup: string
    onHrDataPermissionGroupChange: (inp: string) => void
    hrDataPermissionGroupName: string
    onHrDataPermissionGroupNameChange: (inp: string) => void
    hrPermissionGroupError: string
    onHrPermissionGroupErrorChange: (inp: string) => void
    isActiveGroup: boolean
    onIsActiveChange: (inp: boolean) => void
    isFalconGroup: boolean
    onIsFalconChange: (inp: boolean) => void
    isEgretGroup: boolean
    onIsEgretChange: (inp: boolean) => void
    primaryAliasGroup: TokenGroupProps.Item[]
    onPrimaryAliasChange: (inp: TokenGroupProps.Item[]) => void
    primaryAliasError: string
    onPrimaryAliasErrorChange: (inp: string) => void
    registeredUsersGroup: TokenGroupProps.Item[]
    onRegisteredUsersChange: (inp: TokenGroupProps.Item[]) => void
    registeredUserError: string
    onRegisteredUserErrorChange: (inp: string) => void
    onSaveDisabledChange: (inp: boolean) => void
}
const CreateGroup = (props: CreateGroupProps) => {
    const {
        orgName,
        groups,
        groupName,
        onGroupNameChange,
        groupNameError,
        onGroupNameErrorChange,
        description,
        onDescriptionChange,
        hrDataPermissionGroup,
        onHrDataPermissionGroupChange,
        hrDataPermissionGroupName,
        onHrDataPermissionGroupNameChange,
        hrPermissionGroupError,
        onHrPermissionGroupErrorChange,
        isActiveGroup,
        onIsActiveChange,
        isFalconGroup,
        onIsFalconChange,
        isEgretGroup,
        onIsEgretChange,
        primaryAliasGroup,
        onPrimaryAliasChange,
        primaryAliasError,
        onPrimaryAliasErrorChange,
        registeredUsersGroup,
        onRegisteredUsersChange,
        registeredUserError,
        onRegisteredUserErrorChange,
        onSaveDisabledChange,
    } = props

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    return (
        <SpaceBetween direction='vertical' size='xs'>
            <FormField label='Group' description='Name of the group' errorText={groupNameError}>
                <Input
                    value={groupName}
                    onChange={({ detail }) => {
                        if (groupNameError && isGroupNameValidated(groups, detail.value)) {
                            onGroupNameErrorChange('')
                        }
                        onGroupNameChange(detail.value)
                    }}
                ></Input>
            </FormField>
            <FormField
                label='Business Entity'
                description='Business entity that the group belongs to'
            >
                <Input readOnly={true} value={selectBusinessEntity.name}></Input>
            </FormField>
            <FormField label='Organization' description='Organization that the group belongs to'>
                <Input readOnly={true} value={orgName}></Input>
            </FormField>
            <FormField
                label='Headcount Source'
                description='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks team headcount'
                errorText={hrPermissionGroupError}
            >
                <SpaceBetween direction='vertical' size='xs'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Link href='https://permissions.amazon.com/a/user' target={'_blank'}>
                            Find your permission group <Icon name={'external'} />{' '}
                        </Link>
                        <Link href='https://permissions.amazon.com/a/team/new' target={'_blank'}>
                            Create a new permission group <Icon name={'external'} />{' '}
                        </Link>
                    </SpaceBetween>
                    <Input
                        value={hrDataPermissionGroup}
                        onChange={({ detail }) => {
                            const value = detail.value.trim()
                            let errorMessage = ''

                            if (value.length > 0 && !validPermissionGroup.test(value)) {
                                errorMessage = ACCESS_CONTROL_VALIDATION
                            }
                            onHrPermissionGroupErrorChange(errorMessage)
                            onHrDataPermissionGroupChange(detail.value)
                        }}
                    ></Input>
                </SpaceBetween>
            </FormField>
            <FormField
                label='Headcount Resource Name'
                description='Amazon TEAM Name for the headcount data source. For example, Falcon-core-SW-HC'
            >
                <Input
                    value={hrDataPermissionGroupName}
                    onChange={({ detail }) => {
                        onHrDataPermissionGroupNameChange(detail.value)
                    }}
                ></Input>
            </FormField>
            <PrimaryAliasRegisteredUsersInput
                onRegisteredUsersChange={onRegisteredUsersChange}
                itemType={ITEM_TYPES.GROUP}
                selectedItem={{}}
                registeredUsers={registeredUsersGroup}
                onPrimaryContactChange={onPrimaryAliasChange}
                primaryContact={primaryAliasGroup}
                onSaveDisabledChange={onSaveDisabledChange}
                registerUserInputError={registeredUserError}
                onRegisteredUserInputErrorChange={onRegisteredUserErrorChange}
                primaryContactInputError={primaryAliasError}
                onPrimaryContactInputErrorChange={onPrimaryAliasErrorChange}
            />
            <FormField label='Description' description='Description of the group'>
                <Textarea
                    value={description}
                    onChange={({ detail }) => onDescriptionChange(detail.value)}
                ></Textarea>
            </FormField>
            <FormField label='Active' description='Mark true if it is an active group.'>
                <Toggle
                    onChange={({ detail }) => {
                        onIsActiveChange(detail.checked)
                    }}
                    checked={isActiveGroup}
                >
                    Is group active?
                </Toggle>
            </FormField>
            <FalconEgretUsageToggle
                isFalcon={isFalconGroup}
                isEgret={isEgretGroup}
                onIsFalconChange={onIsFalconChange}
                onIsEgretChange={onIsEgretChange}
                onSaveDisabledChange={() => true}
                referenceItem={{}}
            />
        </SpaceBetween>
    )
}

export default CreateGroup
