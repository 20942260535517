import { ReactElement, useCallback, useRef, useState } from 'react'
import { CustomCellRendererProps } from 'ag-grid-react'
import { ColDef, EditableCallbackParams, ExcelExportParams } from 'ag-grid-community'
import { ExcelStyle } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { Badge, Icon, Link, StatusIndicator, TextContent } from '@amzn/awsui-components-react'
import { isValidURL } from '../../common/Util'
import {
    BUSINESS_ENTITY_ABBREVIATIONS,
    EMPLOYEE_ALIAS_PATTERN,
    PHONE_TOOL_PREFIX,
} from '../../Constant'
import { DATA_TYPE } from '../grid/Constants'
import { addGeneralFilterParams } from '../grid/Utils'

export interface DirectoryPayload {
    business_entity_id?: string
    date_from?: string
    description?: string
    directory_sort?: string
    pmt_alias?: string
    requester?: string
    revision_status?: string
    revision_title?: string
    stl_alias?: string
    tpm_alias?: string
    wiki_link?: string
}

export interface BulkDirectoryPayload {
    requester: string
    team_programs: DirectoryPayload[]
}

export interface RevisionOption {
    label: ReactElement
    value: any
}

interface extendedCustomCellRendererProps extends CustomCellRendererProps {
    isRowEditable?: (params: any) => boolean
}

export const DEFAULT_PAGE_SIZE = 50
export const EXCLUDED_EXCEL_COLUMN_IDS = ['org_name']
export const EDITABLE_COLUMN_IDS = [
    'wiki_link',
    'description',
    'stl_alias',
    'tpm_alias',
    'pmt_alias',
]

export const DIRECTORY_STATUS_TYPES = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
}

export const STATUS_TO_BADGE_MAP = {
    OPEN: 'blue',
    CLOSED: 'grey',
}

export const DIRECTORY_PREFIX = {
    REVISION: 'rev#',
    TEAM_PROGRAM: 'tp#',
}

export const NOTIFICATION_ITEM_ID = {
    INCOMPLETE_TEAMS: 'incomplete_teams',
    ALL_TEAMS: 'all_teams',
}

export const useLoadingState = () => {
    const loadingRef = useRef(0)
    const [isLoading, setIsLoading] = useState(false)

    const startLoading = useCallback(() => {
        loadingRef.current++
        setIsLoading(true)
    }, [])

    const stopLoading = useCallback(() => {
        loadingRef.current = Math.max(0, loadingRef.current - 1)
        if (loadingRef.current === 0) {
            setIsLoading(false)
        }
    }, [])

    return {
        isLoading,
        startLoading,
        stopLoading,
    }
}

export const isUpdated = ({ updated_by, updated_at }): boolean => {
    return Boolean(updated_by && updated_at)
}

export const isLink = (value, colID): boolean => {
    if (!value || !colID) return false
    return colID.toLowerCase().includes('_link')
}

export const isAlias = (value, colID): boolean => {
    if (!value || !colID) return false
    return colID.toLowerCase().includes('_alias') && EMPLOYEE_ALIAS_PATTERN.test(value)
}

export const toggleStatus = (status): string =>
    status === DIRECTORY_STATUS_TYPES.CLOSED
        ? DIRECTORY_STATUS_TYPES.OPEN
        : DIRECTORY_STATUS_TYPES.CLOSED

export const getBEDirectoryName = (business_entity_name?: string) => {
    const business_entity_abbr = Object.entries(BUSINESS_ENTITY_ABBREVIATIONS).find(
        ([_, n]) => n === business_entity_name,
    )?.[0]
    return `${business_entity_abbr || ''} Directory`.trim()
}

export const formatRevisionOptions = (rev: any): RevisionOption => {
    if (!rev) return { label: <></>, value: null }

    return {
        label: (
            <TextContent>
                <p>
                    {`${rev.revision_title} - ${new Date(Number(rev.revision_id)).toLocaleString('default', { month: 'short', year: 'numeric' })}`}{' '}
                    <Badge color={STATUS_TO_BADGE_MAP[rev.revision_status]}>
                        {rev.revision_status}
                    </Badge>
                </p>
            </TextContent>
        ),
        value: rev,
    }
}

export const defaultExcelExportParams: ExcelExportParams = {
    autoConvertFormulas: true,
    processCellCallback: (params) => {
        return isLink(params.value, params.column.getColId())
            ? `=HYPERLINK("${params.value}")`
            : params.value
    },
}

export const excelStyles: ExcelStyle[] = [
    {
        id: 'ag-cell-hyperlink',
        font: { underline: 'Single', color: '#358ccb' },
    },
    { id: 'ag-cell-boolean', dataType: 'Boolean' },
]

const statusCellRenderer = (params: extendedCustomCellRendererProps) => {
    const isLocked = params.isRowEditable && !params.isRowEditable(params)
    return (
        <StatusIndicator type={isLocked ? 'stopped' : params.value ? 'success' : 'warning'}>
            {isLocked ? 'Locked' : params.value ? 'Yes' : 'No'}
        </StatusIndicator>
    )
}

const customCellRenderer = (params: CustomCellRendererProps) => {
    const { value, colDef } = params
    if (!colDef || typeof colDef.editable !== 'function') return value

    const displayAsLink = isLink(value, colDef.field) && isValidURL(value)
    const displayAsAlias =
        isAlias(value, colDef.field) && isValidURL(`${PHONE_TOOL_PREFIX}${value}`)
    const content =
        displayAsLink || displayAsAlias ? (
            <Link href={`${displayAsAlias ? PHONE_TOOL_PREFIX : ''}${value}`} external>
                {value}
            </Link>
        ) : (
            value
        )

    return (
        <>
            <span className='ag-cell-text'>{content}</span>
            <span className='ag-cell-icon'>
                <Icon
                    name={
                        colDef.editable(params as EditableCallbackParams) ? 'edit' : 'lock-private'
                    }
                    variant='subtle'
                    size='small'
                />
            </span>
        </>
    )
}

export const getDirectoryColumnDefs = (
    canAdmin: boolean,
    isRevisionEditable: boolean,
    isRowEditable: (any) => boolean,
    isCellEditable: (any) => boolean,
): ColDef[] => {
    const columnDefs: ColDef[] = [
        { field: 'team_id', headerName: 'Team ID', hide: true },
        { field: 'parent_program_id', headerName: 'Program ID', hide: true },
        { field: 'org_name', headerName: 'Organization', flex: 0.8 },
        { field: 'team_name', headerName: 'Team', sortIndex: 0, sort: 'asc' },
        { field: 'program_name', headerName: 'Program', sortIndex: 1, sort: 'asc' },
        { field: 'wiki_link', headerName: 'Wiki Link' },
        { field: 'description', headerName: 'Description of Support' },
        { field: 'stl_alias', headerName: 'Team POC Alias' },
        { field: 'tpm_alias', headerName: 'TPM Alias' },
        { field: 'pmt_alias', headerName: 'PMT Alias' },
        {
            field: 'updated',
            headerName: canAdmin ? 'Updated' : 'Confirmed',
            flex: 0.8,
            valueGetter: (params) => isUpdated(params.data),
            cellDataType: DATA_TYPE.BOOLEAN,
            cellRenderer: statusCellRenderer,
            cellRendererParams: {
                isRowEditable: isRowEditable,
            },
        },
    ]

    const isAllEditable = (params) =>
        isRevisionEditable && isRowEditable(params) && isCellEditable(params)
    columnDefs.forEach((colDef) => {
        colDef.flex = colDef.flex ?? 1
        colDef.tooltipField = colDef.field
        colDef.editable = isAllEditable
        colDef.cellRenderer = EDITABLE_COLUMN_IDS.includes(colDef.field || '')
            ? customCellRenderer
            : colDef.cellRenderer
        colDef.cellClassRules = {
            'ag-cell-custom': () => EDITABLE_COLUMN_IDS.includes(colDef.field || ''),
            'ag-cell-editable': isAllEditable,
            'ag-cell-non-editable': (params) => !isAllEditable(params),
            'ag-cell-backcolor-grey': (params) => !isRevisionEditable || !isRowEditable(params),
            'ag-cell-boolean': () => colDef.cellDataType === DATA_TYPE.BOOLEAN,
            'ag-cell-hyperlink': (params) => isLink(params.value, params.colDef.field),
            'ag-cell-textcolor-red': (params) =>
                isLink(params.value, params.colDef.field) && !isValidURL(params.value),
        }
        addGeneralFilterParams(colDef)
    })
    return columnDefs
}

export const getNotificationTemplates = (revision_title) => {
    return [
        [
            'directory_update',
            `Please update your <https://prod.falcon.birds.amazon.dev/directory|Falcon Directory> for ${revision_title}.`,
            `Please update your Falcon Directory for ${revision_title}.`,
        ],
    ]
}

export const getNotificationColumnDefs = () => {
    const columnDefs: ColDef[] = [
        { field: 'team_id', headerName: 'Team ID', hide: true },
        { field: 'team_name', headerName: 'Team', sortIndex: 0, sort: 'asc', flex: 1.5 },
        { field: 'org_name', headerName: 'Organization' },
        { field: 'updated', headerName: 'Updated', cellRenderer: statusCellRenderer },
        { field: 'primary_alias', headerName: 'Primary Contact' },
    ]
    columnDefs.forEach((colDef) => {
        colDef.wrapHeaderText = true
        colDef.tooltipField = colDef.field
        colDef.flex = colDef.flex ?? 1
        addGeneralFilterParams(colDef)
    })
    return columnDefs
}
