import React, { useEffect, useState } from 'react'
import useStore from '../../Store'
import {
    Box,
    Button,
    FormField,
    Header,
    Icon,
    Modal,
    Popover,
    Select,
    SpaceBetween,
    TextContent,
} from '@amzn/awsui-components-react'
import { useAppContext } from '../../../context'
import {
    findTeamByTeamId,
    formatUserTeamsOptions,
    getDefaultActiveUserTeam,
} from '../reusable/AllocationUtils'
import { NO_TEAM_MESSAGE } from '../reusable/TextUtil'
import { BADGE_COLOR, BADGE_NAME, EMPTY_SELECTION } from '../../Constant'
import { addBadgesToList } from '../../common/Util'
import ExportTable from '../reusable/ExportTable'
import DeleteAllocationsSummaryTable from './DeleteAllocationsSummaryTable'
import { useNavigate } from 'react-router-dom'

const ManageHistoryHeader = (props) => {
    const {
        isLoading,
        onHistoryLoadingChange,
        exportData,
        exportDataColumnDefinitions,
        teamId,
        showAllWeeks,
        refreshAllocations,
    } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias

    const canAdmin = useStore((state) => state.canAdmin)
    const userTeams = useStore((state) => state.userTeams)
    const setUserTeams = useStore((state) => state.setUserTeams)
    const selectedTeam = useStore((state) => state.selectedTeam)
    const setSelectedTeam = useStore((state) => state.setSelectedTeam)
    const selectedAllocationReportsToDelete = useStore(
        (state) => state.selectedAllocationReportsToDelete,
    )
    const setSelectedAllocationReportsToDelete = useStore(
        (state) => state.setSelectedAllocationReportsToDelete,
    )
    const setManageHistoryPageAlert = useStore((state) => state.setManageHistoryPageAlert)
    const setHistoryAllocationReports = useStore((state) => state.setHistoryAllocationReports)
    const [allHistoryAllocationReports, setAllHistoryAllocationReports] = useState<any[]>([])
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false)

    const [summaryWeeks, setSummaryWeeks] = React.useState<any[]>([])
    const navigate = useNavigate()

    const deleteDisabled = () => {
        return userTeams.length === 0 || isLoading || !selectedAllocationReportsToDelete.length
    }

    const getMyTeam = () => {
        let myTeamURL: string
        canAdmin
            ? (myTeamURL = `/business-entity/${selectBusinessEntity.id}/teams`)
            : (myTeamURL = `/user/${userAlias}/business-entity/${selectBusinessEntity.id}/myteam`)

        onHistoryLoadingChange(true)
        apiClient
            .get(myTeamURL)
            .then((response) => {
                const myTeams = response.data
                setUserTeams(myTeams)
                onHistoryLoadingChange(false)
            })
            .catch((error) => {
                console.error(error)
                onHistoryLoadingChange(false)
            })
    }

    useEffect(() => {
        getAllReportsOnTeam(teamId)
    }, [refreshAllocations])

    useEffect(() => {
        if (!userTeams || !userTeams.length || selectedTeam?.value === teamId) {
            return
        }
        const teamFromUrl = findTeamByTeamId(userTeams, teamId)
        if (teamFromUrl !== undefined) {
            setSelectedTeam(formatUserTeamsOptions([teamFromUrl], canAdmin)[0] ?? EMPTY_SELECTION)
            getAllReportsOnTeam(teamId)
            return
        }
        if (document.referrer) {
            const previousURL = document.referrer.split('/')
            const pageName = previousURL[3]
            const teamIdUrl = previousURL[4]
            // get team if user has select non-default team in allocation page with valid url
            if (pageName === 'allocation' && previousURL.length >= 6) {
                const allocationTeam = userTeams.filter((team) => team.team_id === teamIdUrl) ?? {}
                setSelectedTeam(
                    formatUserTeamsOptions([allocationTeam], canAdmin)[0] ?? EMPTY_SELECTION,
                )
                getAllReportsOnTeam(teamIdUrl)
                return
            }
        }
        const firstActiveUserTeam = getDefaultActiveUserTeam(userTeams, canAdmin)
        if (firstActiveUserTeam?.team_id) {
            setSelectedTeam(
                formatUserTeamsOptions([firstActiveUserTeam], canAdmin)[0] ?? EMPTY_SELECTION,
            )
            getAllReportsOnTeam(firstActiveUserTeam.team_id)
        } else {
            setHistoryAllocationReports([])
            setAllHistoryAllocationReports([])
            onHistoryLoadingChange(false)
        }
    }, [userTeams, teamId])

    useEffect(() => {
        if (showAllWeeks) {
            setHistoryAllocationReports(allHistoryAllocationReports)
            return
        }
        setHistoryAllocationReports(
            allHistoryAllocationReports.filter((report) => report.week_closed === showAllWeeks),
        )
    }, [showAllWeeks, allHistoryAllocationReports])

    const getAllReportsOnTeam = (teamId: string) => {
        if (!teamId) {
            setHistoryAllocationReports([])
            setAllHistoryAllocationReports([])
            onHistoryLoadingChange(false)
            return
        }
        const URL = `/allocations/team/${teamId}/browse`
        onHistoryLoadingChange(true)
        apiClient
            .get(URL)
            .then((response) => {
                const newReports = response.data
                const newReportsWithBadge = addBadgesToList(
                    'allocation_week',
                    newReports,
                    summaryWeeks,
                    BADGE_COLOR.GREEN,
                    BADGE_NAME.NEW,
                )
                if (showAllWeeks) {
                    setHistoryAllocationReports(newReportsWithBadge)
                } else {
                    setHistoryAllocationReports(
                        allHistoryAllocationReports.filter(
                            (report) => report.week_closed === showAllWeeks,
                        ),
                    )
                }
                setAllHistoryAllocationReports(newReportsWithBadge)
                onHistoryLoadingChange(false)
            })
            .catch((error) => {
                console.error(error)
                onHistoryLoadingChange(false)
            })
    }
    const handleChangeTeamSelect = (team) => {
        const teamIdSelected = team.value
        if (teamIdSelected !== selectedTeam.value) {
            navigate(`/history/team/${teamIdSelected}/manage`)
        }
        setSelectedAllocationReportsToDelete([])
    }
    const handleDeleteAllocations = () => {
        if (!selectedTeam?.value) {
            onHistoryLoadingChange(false)
            console.error('Cannot delete allocation: Invalid team.')
            setManageHistoryPageAlert({
                type: 'error',
                content: 'Cannot delete allocation(s): Invalid team.',
            })
        }
        const allocationWeeks = selectedAllocationReportsToDelete
            .filter((report) => report.allocation_week?.length)
            .map((report) => (report.allocation_week || '').trim())
        if (!allocationWeeks.length) {
            onHistoryLoadingChange(false)
            console.error('Cannot delete allocation: No weeks passed.')
            setManageHistoryPageAlert({
                type: 'error',
                content: 'Cannot delete allocation(s): Empty or invalid weeks selected.',
            })
        }

        apiClient
            .delete(
                `/allocations/team/${
                    selectedTeam.value
                }/bulk-delete?allocation_weeks=${allocationWeeks.join(',')}`,
            )
            .then((res) => {
                onHistoryLoadingChange(false)
                setManageHistoryPageAlert({
                    type: 'success',
                    content: `Successfully deleted allocations for ${
                        selectedTeam.label
                    } for the following weeks: ${allocationWeeks.join(', ')}.`,
                })
                getAllReportsOnTeam(selectedTeam.value)
                setSelectedAllocationReportsToDelete([])
            })
            .catch((err) => {
                onHistoryLoadingChange(false)
                console.error(err)
                setManageHistoryPageAlert({
                    type: 'error',
                    content: `Failed to delete allocation reports: ${err.response.data}`,
                })
            })
    }

    useEffect(() => {
        setSelectedTeam({})
        if (selectBusinessEntity.id) {
            getMyTeam()
        }
    }, [selectBusinessEntity])

    return (
        <Box margin={{ bottom: 'm' }}>
            <Modal
                visible={confirmDeleteModalVisible}
                size={'large'}
                onDismiss={() => setConfirmDeleteModalVisible(false)}
                footer={
                    <Header
                        actions={
                            <SpaceBetween size={'s'} direction={'horizontal'}>
                                <Button onClick={() => setConfirmDeleteModalVisible(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    variant={'primary'}
                                    onClick={() => {
                                        onHistoryLoadingChange(true)
                                        handleDeleteAllocations()
                                        setConfirmDeleteModalVisible(false)
                                    }}
                                >
                                    Delete
                                </Button>
                            </SpaceBetween>
                        }
                    ></Header>
                }
            >
                <DeleteAllocationsSummaryTable />
            </Modal>
            <Header
                variant='h3'
                actions={
                    <SpaceBetween size='s' direction='horizontal'>
                        <Button
                            variant='primary'
                            disabled={deleteDisabled()}
                            onClick={() => {
                                setConfirmDeleteModalVisible(true)
                            }}
                        >
                            Delete
                        </Button>
                        <ExportTable
                            tableData={exportData}
                            tableColumnDef={exportDataColumnDefinitions}
                            fileName={'FalconHistoryAllocationReport'}
                        />
                    </SpaceBetween>
                }
            >
                <SpaceBetween direction='vertical' size='s'>
                    <FormField
                        label={
                            <SpaceBetween direction='horizontal' size='xxs'>
                                <TextContent>
                                    <strong>Team</strong>
                                </TextContent>
                                <Popover
                                    position='top'
                                    size='small'
                                    triggerType='custom'
                                    content='Select a team for the allocation report.'
                                >
                                    <Icon name='status-info' />
                                </Popover>
                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                            <Select
                                onChange={({ detail }) =>
                                    handleChangeTeamSelect(detail.selectedOption)
                                }
                                selectedOption={selectedTeam}
                                options={formatUserTeamsOptions(userTeams, canAdmin)}
                                expandToViewport
                                empty={<NO_TEAM_MESSAGE />}
                                filteringType='auto'
                                disabled={isLoading}
                            ></Select>
                            <Button
                                variant={'icon'}
                                iconName={'refresh'}
                                onClick={() => {
                                    getMyTeam()
                                }}
                            ></Button>
                        </SpaceBetween>
                    </FormField>
                </SpaceBetween>
            </Header>
        </Box>
    )
}

export default ManageHistoryHeader
