import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    FormField,
    Grid,
    Link,
    Icon,
    Modal,
    Input,
    SpaceBetween,
    Textarea,
    TokenGroup,
    Toggle,
    SelectProps,
    TokenGroupProps,
} from '@amzn/awsui-components-react/polaris'
import {
    convertAlertContentWithLink,
    getDateFormat,
    isDuplicateByPropertyValue,
    isValidChanged,
} from '../../common/Util'
import {
    Selections,
    AlertTypes,
    validPermissionGroup,
    EMPTY_ACCESS_CONTROL_VALIDATION,
    ACCESS_CONTROL_VALIDATION,
    TEAM_NAME_VALIDATION,
    TEAM_NAME_DUPLICATE,
} from '../../Constant'
import { useAppContext } from '../../../context'
import useStore from '../../Store'
import { formatRegisteredUsersTokenGroup } from './OrgUtil'
import ActiveEffectiveDateSelector, {
    updateEffectiveDateOnActiveChange,
} from '../reusable/ActiveEffectiveDateSelector'

const EditTeam = ({
    orgId,
    orgName,
    groups,
    teams,
    selectedTeams,
    onSelectedTeamsChange,
    teamEditModalVisible,
    onTeamEditModalDismiss,
    onOrgHeaderAlertChange,
    onTeamLoadingChange,
    refreshList,
}) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [teamName, setTeamName] = useState('')
    const [permissionGroup, setPermissionGroup] = useState('')
    const [permissionGroupName, setPermissionGroupName] = useState('')
    const [hrDataPermissionGroup, setHrDataPermissionGroup] = useState('')
    const [hrDataPermissionGroupName, setHrDataPermissionGroupName] = useState('')
    const [teamNameError, setTeamNameError] = useState('')
    const [permissionGroupError, setPermissionGroupError] = useState('')
    const [hrPermissionGroupError, setHrPermissionGroupError] = useState('')
    const [isActive, setIsActive] = useState(true)
    const [originalIsActive, setOriginalIsActive] = useState(true)
    const [description, setDescription] = useState('')
    const [isSaveDisabled, setIsSaveDisabled] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState<SelectProps.Option>({
        label: Selections.GROUP,
        value: '',
    })
    const [registeredUsers, setRegisteredUsers] = useState<TokenGroupProps.Item[]>([])
    const [registerUserInput, setRegisterUserInput] = useState('')
    const [registerUserInputError, setRegisterUserInputError] = useState('')
    const [primaryContact, setPrimaryContact] = useState<TokenGroupProps.Item[]>([])
    const [primaryContactInput, setPrimaryContactInput] = useState('')
    const [primaryContactInputError, setPrimaryContactInputError] = useState('')
    const DEFAULT_DATE = getDateFormat(new Date())
    const [activeStatusEffectiveDate, setActiveStatusEffectiveDate] = useState(DEFAULT_DATE)
    const [originalActivityEffectiveDate, setOriginalActivityEffectiveDate] = useState(DEFAULT_DATE)
    const [teamCreationDate, setTeamCreationDate] = useState(DEFAULT_DATE)
    useEffect(() => {
        if (selectedTeams === undefined || selectedTeams.length === 0) {
            return
        }
        setIsSaveDisabled(true)
        fillAllInputWithSelectedItem(selectedTeams[0])
    }, [groups, selectedTeams])

    const fillAllInputWithSelectedItem = (team) => {
        const primaryAlias = team['primary_alias']
        setTeamName(team['team_name'])
        setPermissionGroup(team['permission_group'])
        setPermissionGroupName(team['permission_group_name'])
        setHrDataPermissionGroup(team['hr_permission_group'] || '')
        setHrDataPermissionGroupName(team['hr_permission_group_name'] || '')
        setSelectedGroup({ label: team['group_name'], value: team['group_id'] })
        setIsActive(team['is_active'])
        setOriginalIsActive(team['is_active'])
        const createAtDate = team['created_at'].split('T')[0]
        const defaultDate = team['is_active'] ? createAtDate : DEFAULT_DATE
        const activeEffectiveDate = team['active_status_effective_date'] || defaultDate
        setActiveStatusEffectiveDate(activeEffectiveDate)
        setTeamCreationDate(createAtDate)
        setOriginalActivityEffectiveDate(activeEffectiveDate)
        setDescription(team['description'])
        setRegisteredUsers(formatRegisteredUsersTokenGroup(team['registered_users']))
        setPrimaryContact(
            primaryAlias
                ? [
                      {
                          label: primaryAlias,
                          dismissLabel: 'Remove ' + primaryAlias,
                      },
                  ]
                : [],
        )
    }

    const clearAllInput = () => {
        setTeamName('')
        setPermissionGroup('')
        setPermissionGroupName('')
        setHrDataPermissionGroup('')
        setHrDataPermissionGroupName('')
        setSelectedGroup({
            label: Selections.GROUP,
            value: '',
        })
        setIsActive(true)
        setOriginalIsActive(true)
        setActiveStatusEffectiveDate(originalActivityEffectiveDate)
        setDescription('')
        setRegisteredUsers([])
        setRegisterUserInput('')
        setRegisterUserInputError('')
        setPrimaryContact([])
        setPrimaryContactInput('')
        setPrimaryContactInputError('')
    }

    const handleDismiss = () => {
        onTeamEditModalDismiss()
        setIsSaveDisabled(true)
        fillAllInputWithSelectedItem(selectedTeams[0])
        setTeamNameError('')
        setPermissionGroupError('')
        setHrPermissionGroupError('')
        setRegisterUserInputError('')
        setPrimaryContactInputError('')
    }

    const handleAddRegisterUser = () => {
        const userInputNormalized = registerUserInput.toLowerCase()
        const duplicateUser = registeredUsers.find(
            (aliasToken) => aliasToken?.label?.toLowerCase() === userInputNormalized,
        )
        if (registeredUsers.length && duplicateUser) {
            setRegisterUserInputError(
                `User ${userInputNormalized} is already registered for this team.`,
            )
            return
        }

        const newRegisteredUsers = [...registeredUsers]
        newRegisteredUsers.push({
            label: userInputNormalized,
            dismissLabel: 'Remove ' + userInputNormalized,
        })
        setRegisterUserInput('')
        setRegisterUserInputError('')
        setRegisteredUsers(newRegisteredUsers)
        setIsSaveDisabled(
            JSON.stringify(newRegisteredUsers.map((item) => item.label)) ===
                JSON.stringify(selectedTeams[0]['registered_users']),
        )
    }

    const handleAddPrimaryContact = () => {
        if (primaryContact.length > 0) {
            setPrimaryContactInputError(
                `Only one primary contact allowed. Remove the current one first.`,
            )
            return
        }
        const primaryContactInputNormalized = primaryContactInput.toLowerCase()

        setPrimaryContactInput('')
        setPrimaryContactInputError('')
        setPrimaryContact([
            {
                label: primaryContactInputNormalized,
                dismissLabel: 'Remove ' + primaryContactInputNormalized,
            },
        ])
        setIsSaveDisabled(
            primaryContactInputNormalized === selectedTeams[0]['primary_alias'].toLowerCase(),
        )
    }

    const isPayloadValidated = (payload) => {
        if (payload.team_name.trim().length === 0) {
            setTeamNameError(TEAM_NAME_VALIDATION)
            return false
        }

        if (
            isDuplicateByPropertyValue(
                'team_id',
                payload.team_id,
                teams,
                'team_name',
                payload.team_name.trim(),
            )
        ) {
            setTeamNameError(TEAM_NAME_DUPLICATE)
            return false
        }

        if (payload.permission_group.trim().length === 0) {
            setPermissionGroupError(EMPTY_ACCESS_CONTROL_VALIDATION)
            return false
        }

        if (!validPermissionGroup.test(payload.permission_group)) {
            setPermissionGroupError(ACCESS_CONTROL_VALIDATION)
            return false
        }

        // make sure that the hr permission group is valid if defined
        // hr permission group is optional for now
        const isHrInvalid =
            payload.hr_permission_group &&
            payload.hr_permission_group_name.length > 0 &&
            !validPermissionGroup.test(payload.hr_permission_group)
        if (isHrInvalid) {
            setHrPermissionGroupError(ACCESS_CONTROL_VALIDATION)
            return false
        }

        return true
    }

    const generateTeamPayload = () => {
        let group_id: string
        let group_name: string
        if (selectedGroup.label === Selections.GROUP) {
            group_id = ''
            group_name = ''
        } else {
            group_id = selectedGroup.value || ''
            group_name = selectedGroup.label || ''
        }
        // only save a name if the hc data permission group is set
        const hrDataSourceName =
            !hrDataPermissionGroup || hrDataPermissionGroup.length === 0
                ? ''
                : hrDataPermissionGroupName

        return {
            business_entity_id: selectBusinessEntity.id,
            business_entity_name: selectBusinessEntity.name,
            org_id: orgId,
            org_name: orgName,
            team_id: selectedTeams[0].team_id,
            team_name: teamName.trim(),
            permission_group: permissionGroup.trim(),
            permission_group_name: permissionGroupName ? permissionGroupName : 'Open',
            hr_permission_group: hrDataPermissionGroup.trim(),
            hr_permission_group_name: hrDataSourceName,
            group_id: group_id,
            group_name: group_name,
            is_active: isActive,
            active_status_effective_date: activeStatusEffectiveDate,
            description: description,
            requester: userAlias,
            primary_alias: (
                (primaryContact.length > 0 ? primaryContact[0].label : '') ?? ''
            ).toLowerCase(),
            registered_users: registeredUsers.map((item) => (item?.label ?? '').toLowerCase()),
        }
    }

    const handleClickUpdate = () => {
        const payload = generateTeamPayload()

        if (!isPayloadValidated(payload)) {
            return
        }

        onTeamLoadingChange(true)
        updateTeam(payload)
            .then((res) => {
                refreshList()
                onOrgHeaderAlertChange([
                    {
                        type: AlertTypes.SUCCESS,
                        content: `Successfully updated team ${selectedTeams[0].team_name}.`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => onOrgHeaderAlertChange([]),
                    },
                ])
                clearAllInput()
                onSelectedTeamsChange([])
                onTeamLoadingChange(false)
            })
            .catch((error) => {
                // todo - simple parse urls and build them into links
                const errMsg = error.response.data
                //convertAlertContentWithLink(`Failed to update team ${selectedTeams[0].team_name}: ${error.response.data}.`, 'this team')
                onOrgHeaderAlertChange([
                    {
                        type: AlertTypes.ERROR,
                        content: convertAlertContentWithLink(
                            `Failed to update team ${selectedTeams[0].team_name}: ${error.response.data}.`,
                            'this team',
                        ),
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => onOrgHeaderAlertChange([]),
                    },
                ])
                onSelectedTeamsChange([])
                onTeamLoadingChange(false)
                console.error(error)
            })
        onTeamEditModalDismiss()
    }

    const updateTeam = (payload) => {
        return apiClient.put(`/team/${selectedTeams[0].team_id}`, JSON.stringify(payload))
    }

    const checkValidActiveEffectiveDate = (newDate) => {
        if (selectedTeams[0]?.is_active) {
            return true
        }
        setIsSaveDisabled(
            !isValidChanged(newDate, selectedTeams[0], 'active_status_effective_date'),
        )
    }

    return (
        <Modal
            onDismiss={handleDismiss}
            header={'Edit Team'}
            visible={teamEditModalVisible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleDismiss}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleClickUpdate}
                            disabled={
                                isSaveDisabled ||
                                teamNameError !== '' ||
                                permissionGroupError !== '' ||
                                hrPermissionGroupError !== ''
                            }
                        >
                            Update
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='xs'>
                <FormField
                    label='Business Entity'
                    description='Business entity that the team belongs to'
                >
                    <Input readOnly={true} value={selectBusinessEntity.name}></Input>
                </FormField>
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <FormField
                        label='Organization'
                        description='Organization that the team belongs to'
                    >
                        <Input readOnly={true} value={orgName}></Input>
                    </FormField>
                    <FormField label='Group' description='Group that the team belongs to'>
                        <Input readOnly={true} value={selectedGroup.label || ''}></Input>
                    </FormField>
                </Grid>
                <FormField label='Team' description='Name of the team' errorText={teamNameError}>
                    <Input
                        value={teamName}
                        onChange={({ detail }) => {
                            const value = detail.value
                            let errorMessage = ''
                            if (value.trim().length === 0) {
                                errorMessage = TEAM_NAME_VALIDATION
                            } else if (
                                isDuplicateByPropertyValue(
                                    'team_id',
                                    selectedTeams[0].team_id,
                                    teams,
                                    'team_name',
                                    value.trim(),
                                )
                            ) {
                                errorMessage = TEAM_NAME_DUPLICATE
                            }
                            setTeamNameError(errorMessage)
                            setTeamName(value)
                            setIsSaveDisabled(!isValidChanged(value, selectedTeams[0], 'team_name'))
                        }}
                    ></Input>
                </FormField>
                <FormField label='Active' description='Mark true if it is an active team'>
                    <Toggle
                        onChange={({ detail }) => {
                            setIsActive(detail.checked)
                            setActiveStatusEffectiveDate(
                                updateEffectiveDateOnActiveChange(
                                    detail.checked,
                                    originalIsActive,
                                    originalActivityEffectiveDate,
                                    teamCreationDate,
                                ),
                            )
                            setIsSaveDisabled(
                                !isValidChanged(detail.checked, selectedTeams[0], 'is_active'),
                            )
                        }}
                        checked={isActive}
                    >
                        Is team active?
                    </Toggle>
                </FormField>
                <ActiveEffectiveDateSelector
                    activeEffectiveDate={activeStatusEffectiveDate}
                    setActiveEffectiveDate={setActiveStatusEffectiveDate}
                    originalActiveEffectiveDate={originalActivityEffectiveDate}
                    isActive={isActive}
                    originalIsActive={originalIsActive}
                    checkValidChange={checkValidActiveEffectiveDate}
                    itemType={'team'}
                    itemId={selectedTeams && selectedTeams.length ? selectedTeams[0].team_id : ''}
                    itemName={
                        selectedTeams && selectedTeams.length ? selectedTeams[0].team_name : 'Team'
                    }
                    itemCreationDate={teamCreationDate}
                />
                <FormField
                    label='Access Control'
                    description='Amazon TEAMS ID. For example, amzn1.abacus.team.hdobhgnhqu7ctplusltq'
                    errorText={permissionGroupError}
                >
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Link href='https://permissions.amazon.com/a/user' target={'_blank'}>
                            Find the access control you are on <Icon name={'external'} />{' '}
                        </Link>
                        <Link href='https://permissions.amazon.com/a/team/new' target={'_blank'}>
                            Create a new access control <Icon name={'external'} />{' '}
                        </Link>
                    </SpaceBetween>
                    <Input
                        value={permissionGroup}
                        onChange={({ detail }) => {
                            const value = detail.value.trim()
                            let errorMessage = ''

                            if (value.length === 0) {
                                errorMessage = EMPTY_ACCESS_CONTROL_VALIDATION
                            } else if (!validPermissionGroup.test(value)) {
                                errorMessage = ACCESS_CONTROL_VALIDATION
                            }
                            setPermissionGroupError(errorMessage)
                            setPermissionGroup(detail.value)
                            setIsSaveDisabled(
                                !isValidChanged(value, selectedTeams[0], 'permission_group'),
                            )
                        }}
                    ></Input>
                </FormField>
                <FormField
                    label='Access Control Name'
                    description='Amazon TEAM Name. For example, Falcon-core SW'
                >
                    <Input
                        value={permissionGroupName}
                        onChange={({ detail }) => {
                            setPermissionGroupName(detail.value)
                            setIsSaveDisabled(
                                !isValidChanged(
                                    detail.value,
                                    selectedTeams[0],
                                    'permission_group_name',
                                ),
                            )
                        }}
                    ></Input>
                </FormField>
                <FormField
                    label='Headcount Source'
                    description='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks team headcount'
                    errorText={hrPermissionGroupError}
                >
                    <SpaceBetween direction='vertical' size='xs'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Link href='https://permissions.amazon.com/a/user' target={'_blank'}>
                                Find your permission group <Icon name={'external'} />{' '}
                            </Link>
                            <Link
                                href='https://permissions.amazon.com/a/team/new'
                                target={'_blank'}
                            >
                                Create a new permission group <Icon name={'external'} />{' '}
                            </Link>
                        </SpaceBetween>
                        <Input
                            value={hrDataPermissionGroup}
                            onChange={({ detail }) => {
                                const value = detail.value.trim()
                                let errorMessage = ''

                                if (value.length > 0 && !validPermissionGroup.test(value)) {
                                    errorMessage = ACCESS_CONTROL_VALIDATION
                                }
                                setHrPermissionGroupError(errorMessage)
                                setHrDataPermissionGroup(detail.value)
                                setIsSaveDisabled(
                                    !isValidChanged(value, selectedTeams[0], 'hr_permission_group'),
                                )
                            }}
                        ></Input>
                    </SpaceBetween>
                </FormField>
                <FormField
                    label='Headcount Resource Name'
                    description='Amazon TEAM Name for the headcount data source. For example, Falcon-core-SW-HC'
                >
                    <Input
                        value={hrDataPermissionGroupName}
                        onChange={({ detail }) => {
                            setHrDataPermissionGroupName(detail.value)
                            setIsSaveDisabled(
                                !isValidChanged(
                                    detail.value,
                                    selectedTeams[0],
                                    'hr_permission_group_name',
                                ),
                            )
                        }}
                    ></Input>
                </FormField>
                <FormField
                    label='Primary Contact'
                    description='Primary contact of the team (will be mentioned when send notification)'
                    errorText={primaryContactInputError}
                >
                    <SpaceBetween size='xs' direction='vertical'>
                        <TokenGroup
                            onDismiss={({ detail: { itemIndex } }) => {
                                setPrimaryContact([])
                                setIsSaveDisabled(selectedTeams[0]['primary_alias'] === '')
                            }}
                            items={primaryContact}
                        />
                        <SpaceBetween size='xs' direction='horizontal'>
                            <Input
                                value={primaryContactInput}
                                onChange={({ detail }) => setPrimaryContactInput(detail.value)}
                                placeholder='Add primary contact'
                            ></Input>
                            <Button
                                onClick={() => handleAddPrimaryContact()}
                                disabled={!primaryContactInput}
                            >
                                Add Primary Contact
                            </Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </FormField>
                <FormField
                    label='Registered Users'
                    description='Registered users of the team'
                    errorText={registerUserInputError}
                >
                    <SpaceBetween size='xs' direction='vertical'>
                        <TokenGroup
                            i18nStrings={{
                                limitShowFewer: 'Show fewer registered users',
                                limitShowMore: 'Show more registered users',
                            }}
                            onDismiss={({ detail: { itemIndex } }) => {
                                const temp = [
                                    ...registeredUsers.slice(0, itemIndex),
                                    ...registeredUsers.slice(itemIndex + 1),
                                ]
                                setRegisteredUsers(temp)
                                setIsSaveDisabled(
                                    JSON.stringify(temp.map((item) => item.label)) ===
                                        JSON.stringify(selectedTeams[0]['registered_users']),
                                )
                            }}
                            items={registeredUsers}
                            limit={5}
                        />
                        <SpaceBetween size='xs' direction='horizontal'>
                            <Input
                                value={registerUserInput}
                                onChange={({ detail }) => setRegisterUserInput(detail.value)}
                                placeholder='Add user myTeam'
                            ></Input>
                            <Button
                                onClick={() => handleAddRegisterUser()}
                                disabled={!registerUserInput}
                            >
                                Add Registered User
                            </Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </FormField>
                <FormField label='Description' description='Description of the team'>
                    <Textarea
                        value={description}
                        onChange={({ detail }) => {
                            setDescription(detail.value)
                            setIsSaveDisabled(
                                !isValidChanged(detail.value, selectedTeams[0], 'description'),
                            )
                        }}
                    ></Textarea>
                </FormField>
            </SpaceBetween>
        </Modal>
    )
}

export default EditTeam
