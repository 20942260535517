import React, { useEffect, useState } from 'react'
import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    Route,
    RouterProvider,
} from 'react-router-dom'
import './apps/grid/AgGridSetup'
import HomePage from './apps/home/HomePage'
import HistoryPage from './apps/history/HistoryPage'
import MyTeam from './apps/myteam/MyTeam'
import NavBar from './layout/NavBar'
import SetupProgram from './apps/program/SetupProgram'
import SetupOrg from './apps/org/SetupOrg'
import OrgDetail from './apps/org/OrgDetail'
import SetupProject from './apps/project/SetupProject'
import AllocationPage from './apps/allocation/AllocationPage'
import LocationTracker from './LocationTracker'
import { applyDensity, Density } from '@amzn/awsui-global-styles'
import StatusPage from './apps/dashboard/status/StatusPage'
import { ROLES } from '../role'
import RouteWithPermission from './RouteWithPermission'
import NoRoutePage from './apps/home/NoRoutePage'
import { useAppContext } from '../context'
import useStore from './Store'
import { userHasRole } from '../hooks/userHasRole'
import { Alert, Box, Link, SpaceBetween } from '@amzn/awsui-components-react'
import {
    AlertTypes,
    EMPTY_ALERT_STATE,
    EMPTY_YEAR_SELECTION,
    SELECT_BUSINESS_ENTITY,
} from './Constant'
import './App.scss'
import { FALCON_SLACK_CHANNEL } from './common/LinkUtil'
import ModalTemplate from './apps/reusable/ModalTemplate'
import SetupHealthPage from './apps/dashboard/setupHealth/SetupHealthPage'
import ManagerPage from './apps/dashboard/manager/ManagerPage'
import DirectoryPage from './apps/directory/DirectoryPage'
import { formatYearSelection, getStageLabel } from './common/Util'
import SetupBanner from './apps/admin/SetupBanner'
import { BannerAlertType, convertMilliToSecEpochTime } from './apps/admin/BannerUtil'

export default function App(props) {
    document.body.classList.add('awsui-visual-refresh')
    applyDensity(Density.Compact)

    const { userBusinessEntity } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const canAdmin = userHasRole([ROLES.ADMIN])
    const canSTL = userHasRole([ROLES.STL])

    const setCanAdmin = useStore((state) => state.setCanAdmin)
    const setCanSTL = useStore((state) => state.setCanSTL)
    const setBusinessEntityMap = useStore((state) => state.setBusinessEntityMap)
    const setBusinessEntities = useStore((state) => state.setBusinessEntities)
    const setSelectBusinessEntity = useStore((state) => state.setSelectBusinessEntity)
    const setActualsYear = useStore((state) => state.setActualsYear)

    const [nonBEModalVisible, setNonBEModalVisible] = React.useState(false)
    const [switchPersonaAlert, setSwitchPersonaAlert] = useState(EMPTY_ALERT_STATE)
    const [businessEntityAlert, setBusinessEntityAlert] = useState(EMPTY_ALERT_STATE)
    const [bannerAlerts, setBannerAlerts] = useState<BannerAlertType[]>([])
    const [banners, setBanners] = useState<BannerAlertType[]>([])

    useEffect(() => {
        const stageLabel = getStageLabel()
        if (stageLabel && !document.title.startsWith(stageLabel)) {
            document.title = `${stageLabel} ${document.title}`
        }
    }, [])

    useEffect(() => {
        getBanners()
    }, [])

    useEffect(() => {
        if (bannerAlerts.length) {
            setBanners(
                bannerAlerts.map((alert) => {
                    return {
                        type: alert.type,
                        content: alert.content,
                        dismissible: alert.dismissible,
                        id: alert.id,
                        onDismiss: () => {
                            dismissAlerts(alert.id, bannerAlerts)
                        },
                    }
                }),
            )
        }
    }, [bannerAlerts])

    const getBanners = () => {
        apiClient
            .get('/banners')
            .then((response) => {
                formBannerAlerts(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const formBannerAlerts = (banners) => {
        const date = new Date()
        const today = convertMilliToSecEpochTime(date.getTime())
        const initialAlerts: BannerAlertType[] = []
        banners.forEach((banner: any) => {
            if (banner.ttl >= today) {
                const id = banner.value
                initialAlerts.push({
                    type: banner.banner_type,
                    content: banner.message,
                    dismissible: banner.removable,
                    id: id,
                    onDismiss: () => {
                        dismissAlerts(id, bannerAlerts)
                    },
                })
            }
        })
        setBannerAlerts(initialAlerts)
    }

    const dismissAlerts = (id, alerts) => {
        const updatedAlerts = alerts.filter((alert) => alert.id !== id)
        if (updatedAlerts.length) {
            setBannerAlerts(updatedAlerts)
        } else {
            setBannerAlerts([])
            setBanners([])
        }
    }
    const getAllBusinessEntities = () => {
        apiClient
            .get('/business-entities')
            .then((response) => {
                const businessEntities = response.data
                const businessEntityMap = {}
                businessEntities.forEach((businessEntity) => {
                    businessEntityMap[businessEntity.business_entity_id] =
                        businessEntity.business_entity_name
                })
                setBusinessEntities(businessEntities)
                setBusinessEntityMap(businessEntityMap)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const getCurrentActualYear = () => {
        apiClient
            .get(`/years/${userBusinessEntity.id}`)
            .then((res) => {
                setActualsYear(formatYearSelection(res.data)[0])
            })
            .catch((err) => {
                console.error(err)
                setActualsYear(EMPTY_YEAR_SELECTION)
            })
    }

    useEffect(() => {
        if (userBusinessEntity.name === SELECT_BUSINESS_ENTITY) {
            setNonBEModalVisible(true)
            setBusinessEntityAlert({
                type: AlertTypes.WARNING,
                header: 'Missing Business Entity',
                content: 'Please select your business entity in the top navigation bar.',
            })
        }
        setCanAdmin(canAdmin)
        setCanSTL(canSTL)
        getAllBusinessEntities()
        setSelectBusinessEntity(userBusinessEntity)
        getCurrentActualYear()
    }, [])

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                path='/'
                element={
                    <>
                        <LocationTracker
                            switchPersonaAlert={switchPersonaAlert}
                            onSwitchPersonaAlertChange={setSwitchPersonaAlert}
                            businessEntityAlert={businessEntityAlert}
                            onBusinessEntityAlertChange={setBusinessEntityAlert}
                        />
                        <NavBar
                            onBusinessEntityAlertChange={setBusinessEntityAlert}
                            onSwitchPersonaAlertChange={setSwitchPersonaAlert}
                            bannerAlerts={banners}
                        />
                        <ModalTemplate
                            modalVisible={nonBEModalVisible}
                            onModalVisibleChange={setNonBEModalVisible}
                            title='Missing Business Entity'
                            body={
                                <Alert type={AlertTypes.WARNING}>
                                    <SpaceBetween direction='horizontal' size='xxs'>
                                        {`Please select your business entity in the top navigation bar. Contact `}
                                        <Link
                                            external
                                            externalIconAriaLabel='Opens in a new tab'
                                            href={FALCON_SLACK_CHANNEL}
                                        >
                                            #falcon-resource-allocation-help-and-requests
                                        </Link>
                                        for more information or to request permission.
                                    </SpaceBetween>
                                </Alert>
                            }
                            cancelName='Confirm'
                        />
                        {businessEntityAlert.content && (
                            <Box
                                className='home-page-alert'
                                padding={{ top: 's', left: 's', right: 's' }}
                            >
                                <Alert
                                    dismissAriaLabel='Close alert'
                                    dismissible
                                    type={businessEntityAlert.type}
                                    header={businessEntityAlert.header}
                                    onDismiss={() => setBusinessEntityAlert(EMPTY_ALERT_STATE)}
                                >
                                    {businessEntityAlert.content}
                                </Alert>
                            </Box>
                        )}
                        {switchPersonaAlert.content && (
                            <Box
                                className='home-page-alert'
                                padding={{ top: 's', left: 's', right: 's' }}
                            >
                                <Alert
                                    dismissAriaLabel='Close alert'
                                    dismissible
                                    type={switchPersonaAlert.type}
                                    header={switchPersonaAlert.header}
                                    onDismiss={() => setSwitchPersonaAlert(EMPTY_ALERT_STATE)}
                                >
                                    {switchPersonaAlert.content}
                                </Alert>
                            </Box>
                        )}

                        <Outlet />
                    </>
                }
            >
                <Route index element={<HomePage />} />
                <Route path='/allocation' element={<AllocationPage />} />
                <Route path='/allocation/:team_id/:allocation_week' element={<AllocationPage />} />
                <Route path='/history' element={<HistoryPage />} />
                <Route path='/history/manage' element={<HistoryPage />} />
                <Route path='/history/team/:team_id' element={<HistoryPage />} />
                <Route path='/history/team/:team_id/manage' element={<HistoryPage />} />
                <Route path='/myteam' element={<MyTeam />} />
                <Route path='/programs' element={<SetupProgram />} />
                <Route path='/projects' element={<SetupProject />} />
                <Route
                    path='/program/:program_id/:local_program_id/projects'
                    element={<SetupProject />}
                />
                <Route path='/orgs' element={<SetupOrg />} />
                <Route path='/org/:id' element={<OrgDetail />} />
                <Route path='/manager' element={<ManagerPage />} />
                <Route path='/directory' element={<DirectoryPage />} />
                <Route
                    path='/admin/banner'
                    element={
                        <SetupBanner
                            bannerAlerts={bannerAlerts}
                            setBannerAlerts={setBannerAlerts}
                            dismissAlerts={dismissAlerts}
                        />
                    }
                />
                <Route
                    path='/admin/status'
                    element={<RouteWithPermission component={StatusPage} />}
                />
                <Route
                    path='/admin/setup-health'
                    element={<RouteWithPermission component={SetupHealthPage} />}
                />
                <Route path='*' element={<NoRoutePage />} />
            </Route>,
        ),
    )

    return <RouterProvider router={router} />
}
