import {
    Box,
    Button,
    ColumnLayout,
    FormField,
    Modal,
    Select,
    SpaceBetween,
    Textarea,
} from '@amzn/awsui-components-react'
import StatusNotificationTeamsTable from './StatusNotificationTeamsTable'
import {
    NO_MESSAGE_TEMPLATES_OPTION,
    NO_SLACK_CHANNELS_OPTION,
    SLACK_FORMATTING_REFERENCE,
} from './NotificationUtils'

const StatusNotificationModal = (props) => {
    const {
        modalVisible,
        onModalVisibleChange,
        modalTextValue,
        onModalTextValueChange,
        selectEndDate,
        slackSelectedOption,
        onSlackSelectedOptionChange,
        slackOptions,
        templateSelectedOption,
        onTemplateSelectedOptionChange,
        templateOptions,
        onSendNotification,
        teams,
        displayAliases,
        isSendingNotification,
    } = props

    const disableSendNotification = () => {
        return (
            slackSelectedOption.value === NO_SLACK_CHANNELS_OPTION.value ||
            templateSelectedOption.value === '' ||
            templateSelectedOption.value === NO_MESSAGE_TEMPLATES_OPTION.value ||
            (templateSelectedOption.value === 'other' && !modalTextValue)
        )
    }

    return (
        <Modal
            onDismiss={() => onModalVisibleChange()}
            visible={modalVisible}
            closeAriaLabel='Close modal'
            size='large'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={() => onModalVisibleChange()}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={() => onSendNotification()}
                            disabled={disableSendNotification()}
                            loading={isSendingNotification}
                        >
                            Send Notification(s)
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Send Notification for ${selectEndDate}`}
        >
            <SpaceBetween direction='vertical' size='s'>
                <ColumnLayout columns={2}>
                    <FormField label='Slack Channel'>
                        <Select
                            selectedOption={slackSelectedOption}
                            onChange={({ detail }) => {
                                onSlackSelectedOptionChange(detail.selectedOption)
                            }}
                            options={slackOptions}
                            disabled={isSendingNotification}
                            selectedAriaLabel='Selected'
                        />
                    </FormField>
                    <FormField label='Message Template'>
                        <Select
                            selectedOption={templateSelectedOption}
                            onChange={({ detail }) => {
                                onTemplateSelectedOptionChange(detail.selectedOption)
                            }}
                            options={templateOptions}
                            disabled={isSendingNotification}
                            selectedAriaLabel='Selected'
                        />
                    </FormField>
                </ColumnLayout>
                {templateSelectedOption.value === 'other' ? (
                    <Textarea
                        onChange={({ detail }) => onModalTextValueChange(detail.value)}
                        value={modalTextValue}
                        placeholder={SLACK_FORMATTING_REFERENCE}
                        disabled={isSendingNotification}
                    />
                ) : (
                    <Textarea
                        value=''
                        placeholder={`${displayAliases}Please update your Falcon Allocation for week ending ${selectEndDate}.`}
                        readOnly
                    />
                )}
                <StatusNotificationTeamsTable teams={teams} isLoading={isSendingNotification} />
            </SpaceBetween>
        </Modal>
    )
}

export default StatusNotificationModal
