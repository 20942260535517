import { ColDef, GridOptions } from 'ag-grid-community'
import ParentNameCellRenderer from '../grid/ParentNameCellRenderer'
import SecondaryGroupCellRenderer from '../grid/SecondaryGroupCellRenderer'
import { GRAND_TOTAL_COLOR_CODE, NORMAL_CELL_COLOR_CODE } from '../grid/Constants'
import { convertToMoneyFormat } from '../../common/Util'
// it's unlikely we'll inactivate anything with an effective date more than 2 years ago
export const MAX_HISTORIC_YEARS = 2

const allocationColumnDefinition = (totalType, isSpend?) => {
    const totalTypeFormatted = totalType
        .split('_')
        .map((totalName) => totalName[0].toUpperCase() + totalName.slice(1))
        .join(' ')

    return {
        headerName: `${totalTypeFormatted} Total`,
        field: `${totalType}_total`,
        sortable: true,
        aggFunc: 'sum',
        valueFormatter: (params) =>
            !isSpend ? params.value?.toFixed(2) : convertToMoneyFormat(params.value),
    }
}

export const SUMMARY_GRID_HEIGHT = 300
export const HEADCOUNT_VALUE_KEY = 'headcount_value'
export const SPEND_VALUE_KEY = 'total_expenditure'

export const groupTableColDefs: ColDef[] = [
    {
        field: 'group_name',
        hide: true,
        rowGroup: true,
    },
    {
        headerName: 'Group',
        field: 'group',
        showRowGroup: 'group_name',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            innerRenderer: (params) =>
                ParentNameCellRenderer(
                    params,
                    (data) => data?.group_name ?? 'Group',
                    'allocation_total',
                ),
        },
        tooltipValueGetter: (params) => {
            if (!params?.node?.group) {
                return
            }
            const tooltipSuffix = params.node?.footer ? ' Total' : ''
            return `${params.value ?? ''}${tooltipSuffix}`
        },
    },
    {
        headerName: 'Team',
        field: 'team_name',
        sortable: true,
        tooltipField: 'team_name',
    },
    {
        field: 'team_id',
        hide: true,
    },
    allocationColumnDefinition('allocation'),
]

export const programTableColDefs: ColDef[] = [
    {
        field: 'local_program_id_and_year',
        rowGroup: true,
        hide: true,
        sortable: false,
    },
    {
        headerName: 'Year',
        field: 'program_year',
        showRowGroup: 'local_program_id_and_year',
        cellRenderer: (params) => SecondaryGroupCellRenderer(params, 'program_year'),
        valueGetter: (params) => {
            const children = params.node?.allLeafChildren ?? []
            if (
                !params.node?.group ||
                !children.length ||
                (params.node?.footer && params.node?.level === -1)
            ) {
                return
            }
            return children[0]?.data?.program_year
        },
        valueFormatter: (params) => {
            const children = params.node?.allLeafChildren ?? []
            if (
                !params.node?.group ||
                !children.length ||
                (params.node?.footer && params.node?.level === -1)
            ) {
                return ''
            }
            return children[0]?.data?.program_year ?? 'Year'
        },
    },
    {
        headerName: 'Program',
        field: 'program_name',
        showRowGroup: 'local_program_id_and_year',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            innerRenderer: (params) =>
                ParentNameCellRenderer(
                    params,
                    (data) => `${data?.program_name ?? 'Program'}`,
                    'allocation_total',
                ),
        },
        valueGetter: (params) => {
            const children = params.node?.allLeafChildren ?? []
            if (
                !params.node?.group ||
                !children.length ||
                (params.node?.footer && params.node?.level === -1)
            ) {
                return
            }
            return `${children[0]?.data?.program_name}`
        },
        valueFormatter: (params) => {
            const children = params.node?.allLeafChildren ?? []
            if (
                !params.node?.group ||
                !children.length ||
                (params.node?.footer && params.node?.level === -1)
            ) {
                return ''
            }
            return children[0]?.data?.program_name ?? 'Program'
        },

        tooltipValueGetter: (params) => {
            const children = params.node?.allLeafChildren ?? []
            if (!params.node?.group || !children.length) {
                return
            }
            const programName =
                params.node?.footer && params.node?.level === -1
                    ? ''
                    : `${children[0]?.data?.program_name}`
            const tooltipSuffix = params.node?.footer ? ' Total' : ''
            return `${programName ?? ''}${tooltipSuffix}`
        },
    },
    {
        headerName: 'Project',
        field: 'project_name',
        tooltipField: 'project_name',
    },
    allocationColumnDefinition('allocation'),
]

export const generateSpendHeadcountTableColDefs = (isSpend): ColDef[] => {
    const estimateKey = isSpend ? SPEND_VALUE_KEY : HEADCOUNT_VALUE_KEY
    return [
        {
            field: 'plan_name',
            hide: true,
            rowGroup: true,
        },
        {
            headerName: 'Plan',
            field: 'plan',
            showRowGroup: 'plan_name',
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                innerRenderer: (params) =>
                    ParentNameCellRenderer(
                        params,
                        (data) => data?.plan_name ?? 'Plan',
                        `${estimateKey}_total`,
                    ),
            },
            tooltipValueGetter: (params) => {
                if (!params?.node?.group) {
                    return
                }
                const tooltipSuffix = params.node?.footer ? ' Total' : ''
                return `${params.value ?? ''}${tooltipSuffix}`
            },
        },
        {
            headerName: 'Revision',
            field: 'revision_name',
            sortable: true,
            tooltipField: 'revision_name',
        },
        {
            field: 'revision_id',
            hide: true,
        },
        allocationColumnDefinition(estimateKey, isSpend),
    ]
}

export const defaultGridOptions = (): GridOptions => {
    return {
        groupDisplayType: 'custom',
        groupTotalRow: 'bottom',
        grandTotalRow: 'bottom',
        autoSizeStrategy: {
            type: 'fitGridWidth',
        },
        getRowStyle: (params) => {
            return {
                fontWeight:
                    params.node.rowPinned || params.node.footer /*|| params.node*/
                        ? 'bold'
                        : 'normal',
                background: params.node.footer ? GRAND_TOTAL_COLOR_CODE : NORMAL_CELL_COLOR_CODE,
            }
        },
        pagination: false,
        groupLockGroupColumns: 2,
        sortingOrder: ['asc', 'desc'],
        suppressAggFuncInHeader: true,
    }
}

export const DEFAULT_COLUMN_OPTIONS: ColDef = {
    suppressMovable: true,
}
