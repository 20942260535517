import {
    Alert,
    Box,
    Button,
    DatePicker,
    ExpandableSection,
    FormField,
    SpaceBetween,
} from '@amzn/awsui-components-react'
import { getDateFormat, getDateNYearsAgo } from '../../common/Util'
import AllocationSummary from './AllocationSummary'
import React from 'react'
import { ModalModes } from '../../Constant'
import { MAX_HISTORIC_YEARS } from './AllocationSummaryConstants'

const DEFAULT_DATE = getDateFormat(new Date())

export const updateEffectiveDateOnActiveChange = (
    newActiveValue,
    originalActiveValue,
    originalActiveEffectiveDate,
    itemCreationDate,
) => {
    let effectiveDate = DEFAULT_DATE
    if (newActiveValue === originalActiveValue) {
        effectiveDate = originalActiveEffectiveDate
    } else if (newActiveValue) {
        effectiveDate = itemCreationDate
    }
    return effectiveDate
}

export const updateActiveStatusForYear = (
    currentIsActive: boolean,
    effectiveDate: string,
    itemYear: string,
) => {
    return currentIsActive || itemYear < effectiveDate.split('-')[0]
}

const ActiveEffectiveDateSelector = (props) => {
    const {
        activeEffectiveDate,
        setActiveEffectiveDate,
        originalActiveEffectiveDate,
        isActive,
        originalIsActive,
        checkValidChange,
        itemType,
        itemId,
        itemName,
        itemCreationDate,
        modalMode,
        blockDatesAfterDate,
    } = props
    const capitalizedItemType = itemType.length
        ? itemType[0].toUpperCase() + itemType.slice(1)
        : 'Item'

    const isDateEnabled = (date: Date) => {
        const earliestEffectiveDate = getDateNYearsAgo(MAX_HISTORIC_YEARS)
        const isDateTooEarly = date < earliestEffectiveDate
        if (!blockDatesAfterDate) {
            return !isDateTooEarly
        }
        return getDateFormat(date) < blockDatesAfterDate && !isDateTooEarly
    }

    return (
        <Box>
            <Alert>
                {`${isActive ? 'All historic ' : ''}${itemName ? itemName : capitalizedItemType} allocations ${
                    !isActive ? `after ${activeEffectiveDate}` : ''
                } ${
                    isActive === originalIsActive &&
                    originalActiveEffectiveDate === activeEffectiveDate
                        ? 'are'
                        : 'will be'
                } ${!isActive ? 'ignored' : 'included'} in reporting. `}
            </Alert>
            {!isActive && modalMode !== ModalModes.VIEW && (
                <ExpandableSection
                    defaultExpanded
                    headerText={'Effective Date'}
                    variant={'default'}
                >
                    <SpaceBetween size={'s'} direction={'vertical'}>
                        <FormField
                            description={`Summary displays allocations that are currently active and that will be ignored after ${activeEffectiveDate}.`}
                        >
                            <SpaceBetween size={'s'} direction={'horizontal'}>
                                <DatePicker
                                    onChange={({ detail }) => {
                                        setActiveEffectiveDate(detail.value)
                                        checkValidChange(detail.value)
                                    }}
                                    value={activeEffectiveDate}
                                    placeholder={getDateFormat(new Date())}
                                    disabled={modalMode === ModalModes.VIEW}
                                    isDateEnabled={isDateEnabled}
                                />
                                <Button
                                    onClick={() => {
                                        let newDate = DEFAULT_DATE
                                        if (isActive === originalIsActive) {
                                            newDate = originalActiveEffectiveDate
                                        } else if (isActive) {
                                            newDate = itemCreationDate
                                        }
                                        setActiveEffectiveDate(newDate)
                                    }}
                                    disabled={modalMode === ModalModes.VIEW}
                                >
                                    Reset
                                </Button>
                            </SpaceBetween>
                        </FormField>
                        <AllocationSummary
                            effectiveDate={activeEffectiveDate}
                            isActive={isActive}
                            itemType={itemType}
                            itemId={itemId}
                            originalIsActive={originalIsActive}
                            originalEffectiveDate={originalActiveEffectiveDate}
                            modalMode={modalMode}
                        />
                    </SpaceBetween>
                </ExpandableSection>
            )}
        </Box>
    )
}
export default ActiveEffectiveDateSelector
