import { FormField, Toggle } from '@amzn/awsui-components-react'
import { isValidChanged } from '../../common/Util'
import React from 'react'

interface FalconEgretUsageToggleProps {
    onIsFalconChange: (inp: boolean) => void
    isFalcon: boolean
    onIsEgretChange: (inp: boolean) => void
    isEgret: boolean
    referenceItem: any
    onSaveDisabledChange: (inp: boolean) => void
}

export default (props: FalconEgretUsageToggleProps) => {
    const {
        onIsFalconChange,
        isFalcon,
        onIsEgretChange,
        isEgret,
        referenceItem,
        onSaveDisabledChange,
    } = props
    return (
        <>
            <FormField
                label='Falcon Usage'
                description='Mark true if this group is used in Falcon.'
            >
                <Toggle
                    onChange={({ detail }) => {
                        onIsFalconChange(detail.checked)
                        onSaveDisabledChange(
                            !isValidChanged(detail.checked, referenceItem, 'is_falcon'),
                        )
                    }}
                    checked={isFalcon}
                >
                    Is group used in Falcon?
                </Toggle>
            </FormField>
            <FormField label='Egret usage' description='Mark true if this group is used in Egret.'>
                <Toggle
                    onChange={({ detail }) => {
                        onIsEgretChange(detail.checked)
                        onSaveDisabledChange(
                            !isValidChanged(detail.checked, referenceItem, 'is_egret'),
                        )
                    }}
                    checked={isEgret}
                >
                    Is group used in Egret?
                </Toggle>
            </FormField>
        </>
    )
}
