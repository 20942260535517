import { useCallback, useEffect, useMemo, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { GridApi, ColDef } from 'ag-grid-community'
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Modal,
    Select,
    SpaceBetween,
    SelectProps,
    Textarea,
} from '@amzn/awsui-components-react'
import useStore from '../../Store'
import { useAppContext } from '../../../context'
import { AlertTypes } from '../../Constant'
import {
    formatSlackMessageTemplates,
    getAliasMentionSlackFormat,
    SLACK_FORMATTING_REFERENCE,
} from '../dashboard/status/NotificationUtils'

interface NotificationProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    setAlertType: (alertType: AlertTypes) => void
    setAlertContent: (alertContent: string) => void
    teams: any[]
    templates: string[][]
    columnDefs: ColDef[]
}

const SendNotification = ({
    visible,
    setVisible,
    setAlertType,
    setAlertContent,
    teams,
    templates,
    columnDefs,
}: NotificationProps) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias
    const selectedBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [isSendingNotification, setIsSendingNotification] = useState<boolean>(false)
    const [slackOptions, setSlackOptions] = useState<SelectProps.Option[]>([])
    const [selectedSlack, setSelectedSlack] = useState<SelectProps.Option | null>(null)
    const [templateOptions, setTemplateOptions] = useState<SelectProps.Option[]>([])
    const [selectedTemplate, setSelectedTemplate] = useState<SelectProps.Option | null>(null)
    const [otherMessage, setOtherMessage] = useState<string>('')
    const isOtherTemplateSelected = useMemo(
        () => selectedTemplate?.value === 'other',
        [selectedTemplate],
    )

    const [gridApi, setGridApi] = useState<GridApi>()

    const onGridReady = useCallback((params) => {
        setGridApi(params.api)
        params.api.setGridOption('domLayout', 'autoHeight')
    }, [])

    const onDismiss = () => {
        setVisible(false)
        setOtherMessage('')
        setSelectedSlack(slackOptions?.[0] ?? null)
        setSelectedTemplate(templateOptions?.[0] ?? null)
    }

    const getMessage = (isDisplay: boolean) => {
        if (isOtherTemplateSelected) return otherMessage
        return `${getAliasMentionSlackFormat(teams, isDisplay)}${templates[templateOptions.findIndex((t) => t === selectedTemplate)]?.[isDisplay ? 2 : 1] || ''}`
    }

    const sendNotification = () => {
        if (!selectedSlack) return

        const payload = {
            user_alias: userAlias,
            slack_channel_id: selectedSlack.value,
            teams: teams,
            message: getMessage(false),
        }

        setIsSendingNotification(true)
        apiClient
            .post(`/chirp/slack`, JSON.stringify(payload))
            .then(() => {
                setAlertType(AlertTypes.SUCCESS)
                setAlertContent(`Successfully notified Slack channel #${selectedSlack.label}.`)
            })
            .catch((error) => {
                console.error(error)
                setAlertType(AlertTypes.ERROR)
                setAlertContent(`Failed to notify Slack channel #${selectedSlack.label}.`)
            })
            .finally(() => {
                setIsSendingNotification(false)
                onDismiss()
            })
    }

    useEffect(() => {
        gridApi?.setGridOption('rowData', teams)
    }, [gridApi, teams])

    useEffect(() => {
        if (!selectedBusinessEntity) return

        const slackChannels = selectedBusinessEntity.slack_channels ?? []
        const slackOptions = slackChannels.map((channel) => {
            return {
                label: channel.slack_channel_name,
                value: channel.slack_channel_id,
            }
        })

        setSlackOptions(slackOptions)
        setSelectedSlack(slackOptions?.[0] ?? null)

        const slackTemplates = formatSlackMessageTemplates(templates ?? [])
        setTemplateOptions(slackTemplates)
        setSelectedTemplate(slackTemplates?.[0] ?? null)
    }, [selectedBusinessEntity])

    return (
        <Modal
            size='large'
            visible={visible}
            onDismiss={onDismiss}
            header='Send Notification'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={onDismiss}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={sendNotification}
                            disabled={
                                !selectedSlack || (isOtherTemplateSelected && !otherMessage.trim())
                            }
                            loading={isSendingNotification}
                        >
                            Send Notification(s)
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='s'>
                <ColumnLayout columns={2}>
                    <FormField label='Slack Channel'>
                        <Select
                            selectedOption={selectedSlack}
                            onChange={({ detail }) => {
                                setSelectedSlack(detail.selectedOption)
                            }}
                            options={slackOptions}
                            disabled={isSendingNotification}
                            placeholder='No slack channels'
                        />
                    </FormField>
                    <FormField label='Message Template'>
                        <Select
                            selectedOption={selectedTemplate}
                            onChange={({ detail }) => {
                                setSelectedTemplate(detail.selectedOption)
                            }}
                            options={templateOptions}
                            disabled={isSendingNotification}
                            placeholder='No message templates'
                        />
                    </FormField>
                </ColumnLayout>
                {isOtherTemplateSelected ? (
                    <Textarea
                        value={otherMessage}
                        placeholder={SLACK_FORMATTING_REFERENCE}
                        disabled={isSendingNotification}
                        onChange={({ detail }) => setOtherMessage(detail.value)}
                    />
                ) : (
                    <Textarea value='' placeholder={getMessage(true)} readOnly />
                )}
                <Container header={<Header variant='h3'>Team(s)</Header>}>
                    <div className='ag-theme-quartz' style={{ height: 'fit-content' }}>
                        <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            pagination={true}
                            paginationPageSize={10}
                            paginationPageSizeSelector={false}
                            tooltipShowDelay={500}
                        />
                    </div>
                </Container>
            </SpaceBetween>
        </Modal>
    )
}

export default SendNotification
