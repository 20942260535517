import {
    Button,
    FormField,
    Input,
    SpaceBetween,
    TokenGroup,
    TokenGroupProps,
} from '@amzn/awsui-components-react'
import React, { useState } from 'react'

interface PrimaryAliasRegisteredUsersProps {
    onSaveDisabledChange: (inp: boolean) => void
    registeredUsers: TokenGroupProps.Item[]
    onRegisteredUsersChange: (inp: TokenGroupProps.Item[]) => void
    primaryContact: TokenGroupProps.Item[]
    onPrimaryContactChange: (inp: TokenGroupProps.Item[]) => void
    selectedItem: any
    itemType: string
    registerUserInputError: string
    onRegisteredUserInputErrorChange: (inp: string) => void
    primaryContactInputError: string
    onPrimaryContactInputErrorChange: (inp: string) => void
}

export default (props: PrimaryAliasRegisteredUsersProps) => {
    const {
        onSaveDisabledChange,
        registeredUsers,
        onRegisteredUsersChange,
        primaryContact,
        onPrimaryContactChange,
        selectedItem,
        itemType,
        onRegisteredUserInputErrorChange,
        onPrimaryContactInputErrorChange,
        primaryContactInputError,
        registerUserInputError,
    } = props
    const [registerUserInput, setRegisterUserInput] = useState('')
    const [primaryContactInput, setPrimaryContactInput] = useState('')

    const handleAddPrimaryContact = () => {
        if (primaryContact.length > 0) {
            onPrimaryContactInputErrorChange(
                `Only one primary contact allowed. Remove the current one first.`,
            )
            return
        }

        setPrimaryContactInput('')
        onPrimaryContactInputErrorChange('')
        onPrimaryContactChange([
            {
                label: primaryContactInput,
                dismissLabel: 'Remove ' + primaryContactInput,
            },
        ])
        onSaveDisabledChange(primaryContactInput === selectedItem?.primary_alias)
    }

    const handleAddRegisterUser = () => {
        if (registeredUsers.length > 0) {
            for (let i = 0; i < registeredUsers.length; i++) {
                if (registeredUsers[i].label === registerUserInput) {
                    onRegisteredUserInputErrorChange(
                        `User ${registerUserInput} is already registered for this ${itemType}.`,
                    )
                    return
                }
            }
        }

        const newRegisteredUsers = [...registeredUsers]
        newRegisteredUsers.push({
            label: registerUserInput,
            dismissLabel: 'Remove ' + registerUserInput,
        })
        setRegisterUserInput('')
        onRegisteredUserInputErrorChange('')
        onRegisteredUsersChange(newRegisteredUsers)
        onSaveDisabledChange(
            JSON.stringify(newRegisteredUsers.map((item) => item.label)) ===
                JSON.stringify(selectedItem?.registered_users ?? []),
        )
    }

    return (
        <>
            <FormField
                stretch
                label='Primary Contact'
                description={`Primary contact of the ${itemType} (will be mentioned when send notification)`}
                errorText={primaryContactInputError}
            >
                <SpaceBetween size='xs' direction='vertical'>
                    <TokenGroup
                        onDismiss={({ detail: { itemIndex } }) => {
                            onPrimaryContactChange([])
                            onSaveDisabledChange(selectedItem?.primary_alias ?? '' === '')
                        }}
                        items={primaryContact}
                    />
                    <SpaceBetween size='xs' direction='horizontal'>
                        <Input
                            value={primaryContactInput}
                            onChange={({ detail }) => setPrimaryContactInput(detail.value)}
                            placeholder='Add primary contact'
                        ></Input>
                        <Button
                            onClick={() => handleAddPrimaryContact()}
                            disabled={!primaryContactInput}
                        >
                            Add Primary Contact
                        </Button>
                    </SpaceBetween>
                </SpaceBetween>
            </FormField>

            <FormField
                stretch
                label='Registered Users'
                description={`Registered users of the ${itemType}.`}
                errorText={registerUserInputError}
            >
                <SpaceBetween size='xs' direction='vertical'>
                    <TokenGroup
                        i18nStrings={{
                            limitShowFewer: 'Show fewer registered users',
                            limitShowMore: 'Show more registered users',
                        }}
                        onDismiss={({ detail: { itemIndex } }) => {
                            const temp = [
                                ...registeredUsers.slice(0, itemIndex),
                                ...registeredUsers.slice(itemIndex + 1),
                            ]
                            onRegisteredUsersChange(temp)
                            onSaveDisabledChange(
                                JSON.stringify(temp.map((item) => item.label)) ===
                                    JSON.stringify(selectedItem?.registered_users ?? []),
                            )
                        }}
                        items={registeredUsers}
                        limit={5}
                    />

                    <SpaceBetween size='xs' direction='horizontal'>
                        <Input
                            value={registerUserInput}
                            onChange={({ detail }) => setRegisterUserInput(detail.value)}
                            placeholder={`Add ${itemType} manager(s)`}
                        ></Input>
                        <Button
                            onClick={() => handleAddRegisterUser()}
                            disabled={!registerUserInput}
                        >
                            Add Registered User
                        </Button>
                    </SpaceBetween>
                </SpaceBetween>
            </FormField>
        </>
    )
}
