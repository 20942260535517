import React from 'react'
import {
    ALLOCATION_STATUS,
    DAY_OF_WEEK,
    EMPLOYEE_TYPE_GENERIC,
    EMPTY_SELECTION,
    FALCON_START_YEAR,
    getDefaultAllocation,
    HISTORICAL_HR_TEAM_DATA_KEY,
    HR_MEMBER_DATA_KEY,
    LIVE_HR_TEAM_DATA_KEY,
    NOT_APPLICABLE,
    STATUS_INDICATOR_TYPES,
} from '../../Constant'
import { convertToLocalTime, getDateFormat } from '../../common/Util'
import { StatusIndicator } from '@amzn/awsui-components-react'
import _ from 'lodash'

const convertMetadataToDict = (metadata, keyValueMap) => {
    const result = {}
    metadata.map((value, index) => {
        result[keyValueMap[index]] = value
    })
    return result
}

export const allocationInputStep = (value: number) => (value % 1 == 0 ? 1 : 0.1)
const MS_PER_DAY = 1000 * 60 * 60 * 24

export const displayExpectedHeadcount = (headcount) => {
    if (headcount === NOT_APPLICABLE) {
        return headcount
    }

    return +headcount
}

export const getHeadcountDelta = (actualHeadcount, expectedHeadcount) => {
    if (expectedHeadcount === NOT_APPLICABLE) {
        return expectedHeadcount
    }

    return +Number(parseFloat(actualHeadcount) - parseFloat(expectedHeadcount)).toFixed(2)
}

export const getHeadcountValueSumGeneric = (allocationValuesList) => {
    // expects list of allocation counts and returns their sum
    // in case of empty list, returns 0
    if (allocationValuesList.length === 0) {
        return 0
    }
    const valuesParsed = allocationValuesList.map((employeeTypeAlloc: string) =>
        parseFloat(employeeTypeAlloc),
    )
    return Number(valuesParsed.reduce((sumTmp, allocVal) => sumTmp + allocVal)).toFixed(2)
}

export const getAllocationValueSum = (allocationValue, newValue, oldValue) => {
    if (newValue === undefined || newValue === '') {
        newValue = 0
    }

    if (oldValue === undefined || oldValue === '') {
        oldValue = 0
    }

    return Number(
        parseFloat(allocationValue) + parseFloat(newValue) - parseFloat(oldValue),
    ).toFixed(2)
}

export const getTotalByKeyList = (list, key) => {
    // list of project
    let counts = 0
    list.forEach(function (prj: any) {
        if (_.get(prj, key)) {
            counts += parseFloat(_.get(prj, key) === 'NaN' ? 0 : _.get(prj, key))
        }
    })

    return Number(counts).toFixed(2)
}

export const getMatchesCountText = (count: number | undefined) => {
    return count === 1 ? `1 match` : `${count} matches`
}

export const scrollToTopLeft = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

export const isDateEnabled = (date: Date) => {
    return (
        date.getDay() === DAY_OF_WEEK.FRIDAY &&
        date.getFullYear() <= new Date().getFullYear() + 1 &&
        date.getFullYear() >= FALCON_START_YEAR
    )
}

export const getCopyAheadStartDate = (dateStr: string) => {
    const strDates = dateStr.split('-')
    const date = new Date(parseInt(strDates[0]), parseInt(strDates[1]) - 1, parseInt(strDates[2]))
    date.setDate(date.getDate() + 7)
    return date
}

export const getFridayOfWeek = (date: Date) => {
    const first = date.getDate() - date.getDay() + 1
    const fifth = first + 4

    return new Date(date.setDate(fifth))
}

export const getLastFriday = (date: Date) => {
    while (date.getDay() !== DAY_OF_WEEK.FRIDAY) {
        date.setDate(date.getDate() - 1)
    }
    return new Date(date)
}

export const getDateDiff = (date1: Date, date2: Date) => {
    const getUTC = (date: Date) => {
        return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    }

    return Math.floor((getUTC(date2) - getUTC(date1)) / MS_PER_DAY)
}

export const getLastFridayOfYear = (year: number) => {
    const date = new Date(Date.UTC(year, 12, 0))
    while (date.getDay() !== DAY_OF_WEEK.FRIDAY) {
        date.setDate(date.getDate() - 1)
    }

    return date
}

export const getEndDate = (startDate: string, year: number, relativeOptions: any) => {
    let weeks = 1
    const friday = getFridayOfWeek(new Date(startDate))
    switch (relativeOptions.unit) {
        case 'day':
            weeks = Math.floor(relativeOptions.amount / 7)
            break
        case 'week':
            weeks = relativeOptions.amount
            break
        case 'month':
            weeks = 4 * relativeOptions.amount
            break
        default:
            return getLastFridayOfYear(year)
    }

    friday.setDate(friday.getDate() + 7 * weeks)
    return friday
}

export const getStartEndDateFromDateRange = (date, dateRange) => {
    const year: number = new Date(date).getFullYear()
    const friday: Date = getEndDate(date, year, dateRange)
    let startDate: string
    let endDate: string
    if (dateRange.type === 'absolute') {
        startDate = dateRange.startDate
        endDate = dateRange.endDate
    } else {
        startDate = getDateFormat(getCopyAheadStartDate(date))
        if (dateRange.unit === 'year') {
            endDate = getDateFormat(getLastFridayOfYear(year))
        } else {
            endDate = getDateFormat(friday)
        }
    }

    return [startDate, endDate]
}

export const getTeamById = (teams, id) => {
    return teams.find((team) => team.team_id === id)
}

export const findTeamByTeamId = (teams, id) => {
    return teams.find((team) => team.team_id === id)
}

export const getExistingReports = (allocationReports, startDate, endDate) => {
    return allocationReports.filter(function (rep) {
        return !(rep.allocation_week < startDate || rep.allocation_week > endDate)
    })
}

export const getWeeksDiff = (startDate, endDate) => {
    const MS_PER_WEEK = MS_PER_DAY * 7

    return Math.round(Math.abs(endDate - startDate) / MS_PER_WEEK)
}

export const getNotificationCountStatus = (count: number) => {
    let type: STATUS_INDICATOR_TYPES
    count === 0
        ? (type = STATUS_INDICATOR_TYPES.IN_PROGRESS)
        : (type = STATUS_INDICATOR_TYPES.SUCCESS)

    return <StatusIndicator type={type}>{count}</StatusIndicator>
}

export const getAllocationStatus = (status: ALLOCATION_STATUS) => {
    if (status === ALLOCATION_STATUS.NOT_ALLOCATED) {
        return (
            <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>
                {ALLOCATION_STATUS.NOT_ALLOCATED}
            </StatusIndicator>
        )
    }
    if (status === ALLOCATION_STATUS.COMPLETED) {
        return (
            <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>
                {ALLOCATION_STATUS.COMPLETED}
            </StatusIndicator>
        )
    }
    if (status === ALLOCATION_STATUS.OVER_ALLOCATED) {
        return (
            <StatusIndicator type={STATUS_INDICATOR_TYPES.INFO}>
                {ALLOCATION_STATUS.OVER_ALLOCATED}
            </StatusIndicator>
        )
    }
    return (
        <StatusIndicator type={STATUS_INDICATOR_TYPES.INFO}>
            {ALLOCATION_STATUS.UNDER_ALLOCATED}
        </StatusIndicator>
    )
}

export const getFridaysArray = (start: string, end: string) => {
    const arr: any[] = []
    for (
        let dt = new Date(end + 'T00:00');
        dt >= new Date(start + 'T00:00');
        dt.setDate(dt.getDate() - 7)
    ) {
        arr.push({ allocation_week: getDateFormat(dt) })
    }
    return arr
}

export const addOverrideWeeks = (weeks, overrideWeeks) => {
    for (const week of overrideWeeks) {
        weeks.push({ allocation_week: week.allocation_week })
    }
    return weeks
}

export const removeExistingWeeks = (weeks, existingWeeks) => {
    return weeks.filter(
        (week) => !existingWeeks.find((prj) => prj.allocation_week === week.allocation_week),
    )
}

export const isValidDateString = (date: string) => {
    return Date.parse(date) > 0
}

export const getYearFromDate = (date: string) => {
    return isValidDateString(date) ? new Date(date).getFullYear().toString() : ''
}

export const calculateStatus = (team) => {
    return team.team_entry_status.allocation_completed_teams / team.team_entry_status.total_teams
}

export const formatDashboardTeams = (teams) => {
    const getStatusString = (allocation, headcount) => {
        if (headcount === 0) {
            return ALLOCATION_STATUS.NOT_ALLOCATED
        }
        if (allocation === headcount) {
            return ALLOCATION_STATUS.COMPLETED
        }
        if (allocation > headcount) {
            return ALLOCATION_STATUS.OVER_ALLOCATED
        }
        return ALLOCATION_STATUS.UNDER_ALLOCATED
    }

    teams.forEach((t) => {
        t.total_headcount = +t.total_headcount
        t.total_allocation = +t.total_allocation
        t.status = getStatusString(t.total_allocation, t.total_headcount)
    })
}

export const formatHrTeamData = (hrTeam, isHistorical) => {
    return isHistorical
        ? convertMetadataToDict(hrTeam, HISTORICAL_HR_TEAM_DATA_KEY)
        : convertMetadataToDict(hrTeam, LIVE_HR_TEAM_DATA_KEY)
}

export const isHRObjectInvalid = (hr_obj) => {
    if (!hr_obj) {
        return true
    }

    return !hr_obj['resource_name'] || !hr_obj['alias']
}

export const formatHrMemberData = (hrMembers) => {
    const result = Object.fromEntries(
        EMPLOYEE_TYPE_GENERIC.map((employeeType) => [employeeType, [] as any[]]),
    )
    hrMembers.forEach((hrMember) => {
        switch (hrMember[9]) {
            case 'Employee':
                const hr_employee_obj = convertMetadataToDict(hrMember, HR_MEMBER_DATA_KEY)
                if (isHRObjectInvalid(hr_employee_obj)) {
                    return
                }

                result[EMPLOYEE_TYPE_GENERIC[0]].push(hr_employee_obj)
                break
            default:
                const hr_intern_obj = convertMetadataToDict(hrMember, HR_MEMBER_DATA_KEY)
                if (isHRObjectInvalid(hr_intern_obj)) {
                    return
                }
                result[EMPLOYEE_TYPE_GENERIC[1]].push(hr_intern_obj)
        }
    })
    return result
}

export const getWordCount = (text: string) => {
    if (!text) {
        return 0
    }
    return text.trim().split(/\s+/).length
}

export const generateAllocationReport = (
    isCopyAhead,
    selectedTeam,
    allocationDate,
    alias,
    allocationNote,
    hrDetailNote,
    weeklyNote,
    totalHeadcount,
    headcountByEmployeeType,
    expectedHeadcount,
    expectedHeadcountByEmployeeType,
    allocationProjects,
    cadence?,
    startEndDate?,
    CopyAheadOverrideWeeks?,
) => {
    const getWeekList = (reports) => {
        return reports.map((prj) => prj.allocation_week)
    }

    const resultReports = {
        team_id: selectedTeam.value,
        allocator_alias: alias,
        allocation_note: allocationNote,
        weekly_note: weeklyNote,
        hr_detail_note: hrDetailNote,
        total_headcount: totalHeadcount,
        headcount_by_employee_type: headcountByEmployeeType,
        total_hr_headcount: expectedHeadcount === NOT_APPLICABLE ? '0' : expectedHeadcount,
        hr_headcount_by_employee_type:
            expectedHeadcount === NOT_APPLICABLE
                ? getDefaultAllocation()
                : expectedHeadcountByEmployeeType,
        allocation_report_projects: [] as any[],
    }
    if (isCopyAhead) {
        resultReports['base_week'] = allocationDate
        resultReports['allocation_start_date'] = startEndDate[0]
        resultReports['allocation_end_date'] = startEndDate[1]
        resultReports['allocation_override_weeks'] = getWeekList(CopyAheadOverrideWeeks)
    } else {
        resultReports['allocation_week'] = allocationDate
        resultReports['allocation_cadence'] = cadence
    }

    allocationProjects.forEach(function (prj) {
        resultReports.allocation_report_projects.push({
            program_id: prj.program_id,
            local_program_id: prj.local_program_id,
            project_id: prj.project_id,
            allocation_value: prj.allocation_value === 'NaN' ? '0' : prj.allocation_value,
            allocation_by_employee_type: prj.allocation_by_employee_type,
        })
    })

    return resultReports
}

export const getHeadcountEmployeeValues = (teamData) => {
    return Object.fromEntries(
        EMPLOYEE_TYPE_GENERIC.map((employeeType) => [
            employeeType,
            teamData[employeeType].toString(),
        ]),
    )
}

export const getDateStringYearMonth = (date: Date) => {
    return date.toISOString().slice(0, 7)
}

export const isMonthEnabled = (date: Date, closedMonths: string[]) => {
    return closedMonths.length ? closedMonths.includes(getDateStringYearMonth(date)) : false
}

export const getYearMonthByClosedMonths = (
    month: string,
    monthLst: string[],
    isGettingNextClosedMonth: boolean,
) => {
    if (monthLst.length < 2) {
        return month
    }

    const idx = monthLst.indexOf(month)
    if (
        idx === -1 ||
        (isGettingNextClosedMonth && idx === monthLst.length - 1) ||
        (!isGettingNextClosedMonth && idx === 0)
    ) {
        return month
    }
    return isGettingNextClosedMonth ? monthLst[idx + 1] : monthLst[idx - 1]
}

export const getNextUpdatedStartAtTimeString = (timeString: string) => {
    if (!timeString) {
        return ''
    }

    const headcountLastUpdatedAt = new Date(timeString)
    headcountLastUpdatedAt.setDate(headcountLastUpdatedAt.getDate() + 1)
    return `${convertToLocalTime(headcountLastUpdatedAt.toISOString()).slice(0, -5)}00:00`
}

export const getTeamNameForSelection = (team, canAdmin) => {
    if (!canAdmin) {
        return team?.team_name
    }
    const suffix = team?.is_active ? '' : ' - deactivated'
    return `${team?.team_name}${suffix}`
}

export const getTeamIconForSelection = (team, canAdmin) => {
    if (!canAdmin) {
        return undefined
    }
    return team?.is_active ? undefined : 'status-stopped'
}

export const formatUserTeamsOptions = (userTeams, canAdmin) => {
    const formattedUserTeams = userTeams
        .filter((team) => team?.is_active || canAdmin)
        .map((team) => ({
            label: getTeamNameForSelection(team, canAdmin),
            value: team.team_id,
            iconName: getTeamIconForSelection(team, canAdmin),
            filteringTags: canAdmin && team.is_active ? ['Active', team.team_name] : [],
        }))
    return formattedUserTeams ?? []
}

export const getDefaultActiveUserTeam = (userTeams, canAdmin) => {
    return (
        userTeams.find((team) => team?.is_active || canAdmin) ?? {
            team_id: '',
            team_name: 'No Team Found',
            is_active: true,
        }
    )
}
