import React, { useState, useEffect } from 'react'
import useStore from '../../Store'
import {
    Box,
    Button,
    FormField,
    Header,
    Icon,
    Link,
    Modal,
    Popover,
    Select,
    SpaceBetween,
    TextContent,
} from '@amzn/awsui-components-react'
import { useAppContext } from '../../../context'
import {
    getTotalByKeyList,
    scrollToTopLeft,
    getStartEndDateFromDateRange,
    getExistingReports,
    getWeeksDiff,
    findTeamByTeamId,
    generateAllocationReport,
    formatUserTeamsOptions,
    getDefaultActiveUserTeam,
} from '../reusable/AllocationUtils'
import { NO_TEAM_MESSAGE } from '../reusable/TextUtil'
import { FALCON_SLACK_CHANNEL, REPORT_BUG_LINK } from '../../common/LinkUtil'
import {
    BADGE_COLOR,
    BADGE_NAME,
    EMPTY_SELECTION,
    getDefaultAllocation,
    NOTIFICATION_RESOURCE_LIMIT,
} from '../../Constant'
import { addBadgesToList } from '../../common/Util'
import ExportTable from '../reusable/ExportTable'
import { useNavigate } from 'react-router-dom'
import HistoryCopyAheadWizard from './HistoryCopyAheadWizard'

const HistoryHeader = (props) => {
    const {
        isLoading,
        onHistoryLoadingChange,
        isTeamActive,
        onIsTeamActiveChange,
        exportData,
        exportDataColumnDefinitions,
        teamId,
    } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias

    const canAdmin = useStore((state) => state.canAdmin)
    const userTeams = useStore((state) => state.userTeams)
    const setUserTeams = useStore((state) => state.setUserTeams)
    const selectedTeam = useStore((state) => state.selectedTeam)
    const setSelectedTeam = useStore((state) => state.setSelectedTeam)
    const selectedAllocationReportToCopy = useStore((state) => state.selectedAllocationReportToCopy)
    const setSelectedAllocationReportToCopy = useStore(
        (state) => state.setSelectedAllocationReportToCopy,
    )
    const setHistoryPageAlert = useStore((state) => state.setHistoryPageAlert)
    const historyCopyAheadProjects = useStore((state) => state.historyCopyAheadProjects)
    const setHistoryCopyAheadProjects = useStore((state) => state.setHistoryCopyAheadProjects)
    const allocationByEmployeeTypeTotalBIS = useStore(
        (state) => state.allocationByEmployeeTypeTotalBIS,
    )
    const setAllocationByEmployeeTypeTotalBIS = useStore(
        (state) => state.setAllocationByEmployeeTypeTotalBIS,
    )
    const setAllocationTotalBIS = useStore((state) => state.setAllocationTotalBIS)
    const setTotalAllocationByEmployeeType = useStore(
        (state) => state.setTotalAllocationByEmployeeType,
    )
    const setHistoryCopyAheadWizardAlert = useStore((state) => state.setHistoryCopyAheadWizardAlert)
    const setTotalAllocation = useStore((state) => state.setTotalAllocation)
    const historyDateRange = useStore((state) => state.historyDateRange)
    const setHistoryDateRange = useStore((state) => state.setHistoryDateRange)
    const historyAllocationReports = useStore((state) => state.historyAllocationReports)
    const setHistoryAllocationReports = useStore((state) => state.setHistoryAllocationReports)
    const historyCopyAheadOverrideWeeks = useStore((state) => state.historyCopyAheadOverrideWeeks)
    const setHistoryCopyAheadOverrideWeeks = useStore(
        (state) => state.setHistoryCopyAheadOverrideWeeks,
    )
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const expectedAllocationByEmployeeType = useStore(
        (state) => state.expectedAllocationByEmployeeType,
    )
    const expectedAllocation = useStore((state) => state.expectedAllocation)
    const navigate = useNavigate()

    const [copyAheadWizardVisible, setCopyAheadWizardVisible] = useState(false)
    const [summaryWeeks, setSummaryWeeks] = useState<any[]>([])
    const [adminDefaultTeam, setAdminDefaultTeam] = useState<any>(undefined)

    const copyAheadDisabled = () => {
        return userTeams.length === 0 || isLoading || !isTeamActive
    }

    const getMyTeam = () => {
        onHistoryLoadingChange(true)
        apiClient
            .get(`/user/${userAlias}/business-entity/${selectBusinessEntity.id}/myteam`)
            .then((response) => {
                const myTeams = response.data
                if (canAdmin) {
                    if (myTeams.length) {
                        const defaultTeam = myTeams.find(
                            (team) => team.is_default && team.is_active,
                        )
                        setAdminDefaultTeam(defaultTeam)
                    }

                    apiClient
                        .get(`/business-entity/${selectBusinessEntity.id}/teams`)
                        .then((response) => {
                            const allTeams = response.data
                            setUserTeams(allTeams)
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                } else {
                    setUserTeams(myTeams)
                }
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => onHistoryLoadingChange(false))
    }

    useEffect(() => {
        if (!userTeams || !userTeams.length || teamId === selectedTeam?.value) {
            return
        }
        const teamFromUrl = findTeamByTeamId(userTeams, teamId)
        if (teamFromUrl !== undefined) {
            onIsTeamActiveChange(teamFromUrl.is_active)
            setSelectedTeam(formatUserTeamsOptions([teamFromUrl], canAdmin)[0] ?? EMPTY_SELECTION)
            getAllReportsOnTeam(teamId)
            return
        } else if (document.referrer) {
            const previousURL = document.referrer.split('/')
            const pageName = previousURL[3]
            const teamIdUrl = previousURL[4]
            // get team if user has select non-default team in allocation page with valid url
            if (pageName === 'allocation' && previousURL.length >= 6) {
                const newTeam = findTeamByTeamId(userTeams, teamIdUrl)
                onIsTeamActiveChange(newTeam.is_active)
                const allocationTeam = userTeams.filter((team) => team.team_id === teamIdUrl) ?? {}
                setSelectedTeam(
                    formatUserTeamsOptions([allocationTeam], canAdmin)[0] ?? EMPTY_SELECTION,
                )
                getAllReportsOnTeam(teamIdUrl)
                return
            }
        }
        if (userTeams.length) {
            const defaultTeam =
                adminDefaultTeam ?? userTeams.find((team) => team.is_default && team.is_active)
            const firstActiveUserTeam = getDefaultActiveUserTeam(userTeams, canAdmin)
            const teamToUse = defaultTeam ? defaultTeam : firstActiveUserTeam
            onIsTeamActiveChange(teamToUse?.is_active)
            setSelectedTeam(formatUserTeamsOptions([teamToUse], canAdmin)[0] ?? EMPTY_SELECTION)
            if (teamToUse?.team_id) {
                getAllReportsOnTeam(teamToUse.team_id)
            } else {
                setHistoryAllocationReports([])
                onHistoryLoadingChange(false)
            }
        } else {
            setHistoryAllocationReports([])
        }
        onHistoryLoadingChange(false)
    }, [teamId, userTeams])

    const getAllReportsOnTeam = (inputTeamId: string) => {
        const URL = `/allocations/team/${inputTeamId}/browse`
        onHistoryLoadingChange(true)
        apiClient
            .get(URL)
            .then((response) => {
                const newReports = response.data
                const newReportsWithBadge = addBadgesToList(
                    'allocation_week',
                    newReports,
                    summaryWeeks,
                    BADGE_COLOR.GREEN,
                    BADGE_NAME.NEW,
                )
                setHistoryAllocationReports(newReportsWithBadge)
                onHistoryLoadingChange(false)
            })
            .catch((error) => {
                console.error(error)
                onHistoryLoadingChange(false)
            })
    }

    const getReportOnTeamAndDate = (date) => {
        const URL = `/allocation/team/${selectedTeam.value}/week/${date}`
        apiClient
            .get(URL)
            .then((response) => {
                const res = response.data
                if (res.length > 0) {
                    const headcountData = res[0].headcount_by_employee_type
                    setAllocationByEmployeeTypeTotalBIS(headcountData)
                    setHistoryCopyAheadProjects(res)
                    setAllocationTotalBIS(res[0].total_headcount)
                    setTotalAllocationByEmployeeType(
                        Object.fromEntries(
                            Object.keys(headcountData).map((employeeType) => [
                                employeeType,
                                getTotalByKeyList(
                                    res,
                                    `allocation_by_employee_type.${employeeType}`,
                                ),
                            ]),
                        ),
                    )
                    setTotalAllocation(getTotalByKeyList(res, 'allocation_value'))
                }
            })
            .catch((error) => {
                setAllocationByEmployeeTypeTotalBIS(getDefaultAllocation())
                setAllocationTotalBIS('0')
                setTotalAllocationByEmployeeType(getDefaultAllocation())
                setTotalAllocation('0')
                console.error(error)
            })
    }

    const handleChangeTeamSelect = (team) => {
        const teamIdSelected = team.value
        if (teamIdSelected !== selectedTeam.value) {
            navigate(`/history/team/${teamIdSelected}`)
        }
        setSelectedAllocationReportToCopy([])
        setHistoryCopyAheadOverrideWeeks([])
        setHistoryDateRange('')
    }

    const handleCopyAhead = () => {
        if (selectedAllocationReportToCopy.length === 0) {
            setHistoryPageAlert({
                type: 'error',
                content:
                    'Select an existing allocation report. When you copy ahead, allocation information will be copied from the selected report.',
            })
            return
        }

        const reportDate = selectedAllocationReportToCopy[0].allocation_week
        const startEndDate = getStartEndDateFromDateRange(reportDate, historyDateRange || '')
        if (new Date(reportDate).getFullYear() !== new Date(startEndDate[0]).getFullYear()) {
            setHistoryPageAlert({
                type: 'error',
                content: 'Cannot copy ahead of the last Friday of the year. Try select another.',
            })
        } else {
            getReportOnTeamAndDate(selectedAllocationReportToCopy[0].allocation_week)
            setHistoryPageAlert({ content: '' })
            setCopyAheadWizardVisible(true)
        }
    }

    const handleCopyAheadSave = () => {
        const startEndDate = getStartEndDateFromDateRange(
            selectedAllocationReportToCopy[0].allocation_week,
            historyDateRange,
        )
        const existingReportsCount = getExistingReports(
            historyAllocationReports,
            startEndDate[0],
            startEndDate[1],
        ).length
        const maxReportsInDateRange =
            getWeeksDiff(new Date(startEndDate[0]), new Date(startEndDate[1])) + 1

        // no updates if user skip(not override) all existing reports and
        // the date range already fully reports(all weeks has been reported)
        if (
            historyCopyAheadOverrideWeeks.length === 0 &&
            existingReportsCount === maxReportsInDateRange
        ) {
            setHistoryPageAlert({
                type: 'info',
                content:
                    'No allocation reports were updated because skipped all existing report(s).',
            })
            setCopyAheadWizardVisible(false)
            setSelectedAllocationReportToCopy([])
            setHistoryDateRange('')
            return
        }

        onHistoryLoadingChange(true)
        apiClient
            .post(
                '/allocations/copy-ahead',
                generateAllocationReport(
                    true,
                    selectedTeam,
                    selectedAllocationReportToCopy[0].allocation_week,
                    userAlias,
                    historyCopyAheadProjects[0].note,
                    historyCopyAheadProjects[0].hr_detail_note,
                    historyCopyAheadProjects[0].weekly_note,
                    historyCopyAheadProjects[0].total_headcount,
                    allocationByEmployeeTypeTotalBIS,
                    expectedAllocation,
                    expectedAllocationByEmployeeType,
                    historyCopyAheadProjects,
                    1,
                    startEndDate,
                    historyCopyAheadOverrideWeeks,
                ),
            )
            .then(() => {
                const weekCount = summaryWeeks.length
                if (weekCount > NOTIFICATION_RESOURCE_LIMIT) {
                    setHistoryPageAlert({
                        type: 'success',
                        content: `Successfully copied ahead ${weekCount} report(s) for date range ${startEndDate[0]} to ${startEndDate[1]}.`,
                    })
                } else {
                    setHistoryPageAlert({
                        type: 'success',
                        content: `Successfully copied ahead reports for ${summaryWeeks.map(
                            (week) => week.allocation_week,
                        )}.`,
                    })
                }
                getAllReportsOnTeam(selectedTeam.value)
                setCopyAheadWizardVisible(false)
                setSelectedAllocationReportToCopy([])
                setHistoryCopyAheadOverrideWeeks([])
                setHistoryDateRange('')
                scrollToTopLeft()
            })
            .catch((error) => {
                const errMessage = {
                    type: 'error',
                    content: (
                        <TextContent>
                            {`${error.response.data}`}. Please create a new allocation report from
                            the{' '}
                            <Link external href={'/allocation'}>
                                Allocation page
                            </Link>
                            . If this is unexpected, contact our Slack channel{' '}
                            <Link
                                href={FALCON_SLACK_CHANNEL}
                                external
                                externalIconAriaLabel='Opens in a new tab'
                            >
                                #ar-falcon-help-and-requests
                            </Link>{' '}
                            or report a bug -{' '}
                            <Link
                                href={REPORT_BUG_LINK}
                                external
                                externalIconAriaLabel='Opens in a new tab'
                            >
                                SIM
                            </Link>
                            {'.'}
                        </TextContent>
                    ),
                }
                setHistoryCopyAheadWizardAlert(errMessage)
                setHistoryPageAlert(errMessage)
                console.error(error)
                onHistoryLoadingChange(false)
            })
    }

    useEffect(() => {
        setSelectedTeam({})
        if (
            localStorage.getItem('allocation report save') === 'true' &&
            localStorage.getItem('business-entity-current') === selectBusinessEntity.id
        ) {
            setHistoryPageAlert({
                type: 'success',
                content:
                    'Successfully saved allocation report for the week ending in ' +
                    localStorage.getItem('allocation report week'),
            })
            localStorage.clear()
        }
        if (selectBusinessEntity.id) {
            getMyTeam()
        }
    }, [selectBusinessEntity])

    return (
        <Box margin={{ bottom: 'm' }}>
            <Modal
                onDismiss={() => setCopyAheadWizardVisible(false)}
                visible={copyAheadWizardVisible}
                closeAriaLabel='Close modal'
                size='max'
                header={
                    <Header variant='h1'>
                        Copying Ahead Based on{' '}
                        {selectedAllocationReportToCopy.length > 0
                            ? selectedAllocationReportToCopy[0].allocation_week
                            : ''}{' '}
                        Report
                    </Header>
                }
            >
                <HistoryCopyAheadWizard
                    key={
                        selectedAllocationReportToCopy.length > 0
                            ? selectedAllocationReportToCopy[0].allocation_week
                            : 1
                    }
                    summaryWeeks={summaryWeeks}
                    onSummaryWeeksChange={setSummaryWeeks}
                    onCopyAheadSave={handleCopyAheadSave}
                    onCopyAheadCancel={setCopyAheadWizardVisible}
                />
            </Modal>
            <Header
                variant='h3'
                actions={
                    <SpaceBetween size='s' direction='horizontal'>
                        <Button
                            variant='primary'
                            disabled={copyAheadDisabled()}
                            onClick={() => handleCopyAhead()}
                        >
                            Copy Ahead
                        </Button>
                        <ExportTable
                            tableData={exportData}
                            tableColumnDef={exportDataColumnDefinitions}
                            fileName={'FalconHistoryAllocationReport'}
                        />
                    </SpaceBetween>
                }
            >
                <SpaceBetween direction='vertical' size='s'>
                    <FormField
                        label={
                            <SpaceBetween direction='horizontal' size='xxs'>
                                <TextContent>
                                    <strong>Team</strong>
                                </TextContent>
                                <Popover
                                    position='top'
                                    size='small'
                                    triggerType='custom'
                                    content='Select a team for the allocation report.'
                                >
                                    <Icon name='status-info' />
                                </Popover>
                            </SpaceBetween>
                        }
                    >
                        <Select
                            onChange={({ detail }) => handleChangeTeamSelect(detail.selectedOption)}
                            selectedOption={selectedTeam}
                            options={formatUserTeamsOptions(userTeams, canAdmin)}
                            expandToViewport
                            empty={<NO_TEAM_MESSAGE />}
                            filteringType='auto'
                            disabled={isLoading}
                        ></Select>
                    </FormField>
                </SpaceBetween>
            </Header>
        </Box>
    )
}

export default HistoryHeader
